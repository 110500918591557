import React from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Select,
  Paper,
  ListItemText,
  MenuItem,
  Button,
} from "@mui/material";
import dataStorage from "src/dataStorage";
import { ACCOUNT_TYPE } from "src/constants";
import Individual from "src/components/Onboarding/Individual";
import Joint from "src/components/Onboarding/Joint";
import Company from "src/components/Onboarding/Company";
import Trust from "src/components/Onboarding/Trust";
import Fund from "src/components/Onboarding/Fund";
import Logo from "src/components/Logo";
import { makeStyles } from "@mui/styles";
import {
  saveDraft,
  getDraft,
  getDraftOfRetailByOperator,
  scrollToTop,
} from "src/utils/functionUtils";
import { postData, verifyOTP, verifyOTPApplicantOther } from "src/api/api";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      margin: theme.spacing(2, 2, 0, 2),
      padding: theme.spacing(1, 2, 2, 2),
      maxWidth: "calc(100vw - 32px)",
    },
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& ul": {
      margin: theme.spacing(0, 0, 0, 5),
    },
    "& li": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiListItemText-multiline": {
      marginBottom: 22,
    },
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      "& img": {
        maxWidth: "30%",
      },
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  itemSelect: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme?.palette?.mode === "dark" ? "#6e6e6e" : "#c4c4c4",
  },
}));

const options = [
  {
    value: ACCOUNT_TYPE.INDIVIDUAL,
    title: "Individual - Australian resident",
    description: "You trade under your name",
  },
  {
    value: ACCOUNT_TYPE.JOINT,
    title: "Joint (2 or 3 persons)",
    description: "You trade in joint names (more than 1 person)",
  },
  {
    value: ACCOUNT_TYPE.COMPANY,
    title: "Company",
    description: "You trade on behalf of a company or corporation",
  },
  {
    value: ACCOUNT_TYPE.TRUST,
    title: "Trust",
    description:
      "You trade as an individual trustee or on behalf of a corporate trust",
  },
  {
    value: ACCOUNT_TYPE.FUND,
    title: "Superannuation fund",
    description: "You trade on behalf of a superannuation fund",
  },
  {
    value: ACCOUNT_TYPE.INTERNATIONAL_INVESTOR,
    title: "International investor (Soon)",
    description: "You want to trade and are not an Australian resident",
    disabled: true,
  },
];

export default function NewAccount({ ...props }) {
  const classes = useStyle();
  const navigate = useNavigate();
  const accountTypeRef = React.useRef(
    dataStorage.accountType || ACCOUNT_TYPE.INDIVIDUAL
  );
  const [accountType, setAccountType] = React.useState(dataStorage.accountType);
  const [open, setOpen] = React.useState(false);
  const UrlParams = new URLSearchParams(window.location.search);
  const draftId = UrlParams.get("draft_id");
  const email = UrlParams.get("email");
  const otp = UrlParams.get("otp");
  const accessToken = UrlParams.get("accessToken");
  email && (dataStorage.registerEmail = email);
  const equixId = UrlParams.get("equix_id");
  equixId && (dataStorage.equixId = equixId);
  const applicantId = UrlParams.get("applicantId");
  dataStorage.applicantId = applicantId;
  accessToken && (dataStorage.accessToken = accessToken);
  const [loading, setLoading] = React.useState(!!(otp || accessToken));

  React.useEffect(() => {
    preprocess();
  }, []);

  const preprocess = async () => {
    if (dataStorage.accessToken && draftId) {
      dataStorage.isOperatorSupport = true; // broker, operator
      const [, err] = await getDraftOfRetailByOperator(
        draftId,
        dataStorage.registerEmail
      );
      if (err) {
        setLoading(false);
        window.history.replaceState(
          "",
          "",
          `${window.location.origin}/${dataStorage.env}`
        );
      } else {
        setAccountType(dataStorage.accountType);
        setLoading(false);
        window.history.replaceState(
          "",
          "",
          `${window.location.origin}/${dataStorage.env}`
        );
      }
    } else {
      if (equixId) {
        dataStorage.isSubApplicant = true;
        const bodyData = {
          user_login_id: email,
          otp,
        };
        const getUrlOTP = applicantId
          ? verifyOTPApplicantOther(applicantId)
          : verifyOTP();
        const [res, error] = await postData(getUrlOTP, bodyData);
        if (error) {
          setLoading(false);
          dataStorage.showAlert?.({ message: error, type: "error" });
        } else {
          const { application_token: token } = res;
          dataStorage.accessToken = token;
          await getDraft(
            `?draft_id=${draftId}&page_size=${20}&page_id=${1}`,
            draftId
          );
          setAccountType(dataStorage.accountType);
          setLoading(false);
          window.history.replaceState(
            "",
            "",
            `${window.location.origin}/${dataStorage.env}`
          );
        }
      } else {
        if (email && otp) {
          const bodyData = {
            user_login_id: email,
            otp,
          };
          const [res, error] = await postData(verifyOTP(), bodyData);
          if (error) {
            setLoading(false);
            dataStorage.showAlert?.({ message: error, type: "error" });
          } else {
            const { application_token: token } = res;
            dataStorage.accessToken = token;
            await getDraft(
              `?draft_id=${draftId}&page_size=${20}&page_id=${1}`,
              draftId
            );
            setAccountType(dataStorage.accountType);
            setLoading(false);
            window.history.replaceState(
              "",
              "",
              `${window.location.origin}/${dataStorage.env}`
            );
          }
        }
      }
    }
  };

  const onStart = () => {
    scrollToTop();
    dataStorage.accountType = accountTypeRef.current;
    saveDraft(
      {
        formData: {
          account_type: accountTypeRef.current,
        },
      },
      () => {
        setAccountType(accountTypeRef.current);
      }
    );
  };

  function renderChooseAccountType() {
    return (
      <Paper className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ maxWidth: "50vw" }}>
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Select the account type you would like to apply for:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              value={accountTypeRef.current}
              onChange={(e) => {
                accountTypeRef.current = e.target.value;
                dataStorage.accountType = accountTypeRef.current;
              }}
              variant="filled"
              fullWidth
            >
              {options.map((e, i) => {
                return (
                  <MenuItem
                    key={`account_type_MenuItem_${i}`}
                    value={e.value}
                    disabled={e.disabled}
                    className={classes.itemSelect}
                  >
                    <ListItemText primary={e.title} secondary={e.description} />
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">To register you will need:</Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  To be 18 years or older and an Australian resident.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Identification documents: Drivers License, Passport.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Australian Tax File Number /ABN for businesses (optional).
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  To open a new CMA account during this application.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.rowContainer}>
              <Typography variant="body1">
                As part of this process you will have an account with our
                banking partner
              </Typography>
              <img
                className="logoDark logoDarkLight"
                src={"/images/Macquarie_logo_dark.svg"}
                alt="Logo"
                width="auto"
                height="40px"
                style={{ marginLeft: 32 }}
              />
              <img
                className="logoLight logoDarkLight"
                src={"/images/Macquarie_logo_light.svg"}
                alt="Logo"
                width="auto"
                height="40px"
                style={{ marginLeft: 32 }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="primary" onClick={onStart}>
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const backChooseAccountType = () => {
    dataStorage.accountType = null;
    dataStorage.equixId = null;
    dataStorage.indexApplicant = 0;
    dataStorage.isSubApplicant = false;
    dataStorage.accountStatus = "";
    setAccountType(null);
  };
  const backChooseDraft = () => {
    navigate &&
      navigate(`/${dataStorage.env}/onboarding/drafts`, { replace: true });
    props.closeModal && props.open && props.closeModal();
  };
  if (accountType) {
    switch (accountType) {
      case ACCOUNT_TYPE.INDIVIDUAL:
        return (
          <Individual
            backChooseAccountType={backChooseAccountType}
            backChooseDraft={backChooseDraft}
          />
        );
      case ACCOUNT_TYPE.JOINT:
        return (
          <Joint
            backChooseAccountType={backChooseAccountType}
            backChooseDraft={backChooseDraft}
          />
        );
      case ACCOUNT_TYPE.COMPANY:
        return (
          <Company
            backChooseAccountType={backChooseAccountType}
            backChooseDraft={backChooseDraft}
          />
        );
      case ACCOUNT_TYPE.TRUST:
        return (
          <Trust
            backChooseAccountType={backChooseAccountType}
            backChooseDraft={backChooseDraft}
          />
        );
      case ACCOUNT_TYPE.FUND:
        return (
          <Fund
            backChooseAccountType={backChooseAccountType}
            backChooseDraft={backChooseDraft}
          />
        );
      default:
        return <React.Fragment />;
    }
  }
  return (
    <React.Fragment>
      {loading ? <CircularProgress /> : renderChooseAccountType()}
    </React.Fragment>
  );
}
