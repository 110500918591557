import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CreateSegmentModal from "src/components/Segments/CreateSegmentModal";
import EditSegmentModal from "src/components/Segments/EditSegmentModal";
import {
  fetchSegments,
  deleteSegment,
  setPeddingRequest,
} from "src/redux/segment/segment";
import { Paper, Fab, Backdrop, CircularProgress } from "@mui/material";
import Kebab from "src/components/Kebab";
import {
  getData,
  deleteData,
  getSegmentDetailUrl,
  getDeleteSegmentUrl,
} from "src/api/api";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "src/components/ConfirmModal";
import CustomTable, { TABLE_ACTION } from "src/components/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import dataStorage from "src/dataStorage";
import { getFilterObj } from "src/utils/functionUtils";
import DateTimeFilter from "src/components/DateTimeFilter";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    minHeight: 0,
    overflow: "hidden",
    position: "relative",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
  floatingBtn: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const FIELD = {
  SEGMENT_ID: "segment_id",
  SEGMENT_NAME: "name",
  NUMBER_OF_SUBSCRIBER: "total_subscribers",
  LAST_UPDATE: "last_updated",
  ACTOR: "actor",
  ACTION: "action",
};

const LIST_FILTER = [
  FIELD.ACTOR,
  FIELD.SEGMENT_ID,
  FIELD.SEGMENT_NAME,
  FIELD.NUMBER_OF_SUBSCRIBER,
  FIELD.LAST_UPDATE,
];

const LIST_TIME_FILTER = [{ value: FIELD.LAST_UPDATE, label: "Last Updated" }];

const DEFAULT_VALUE = "--";

const Segments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const segments = useSelector((state) => state.segment.segments);
  const paginate = useSelector((state) => state.segment.paginate);
  const loading = useSelector((state) => state.segment.loading);
  const peddingRequest = useSelector((state) => state.segment.peddingRequest);
  const searchText = React.useRef("");
  const createModalRef = React.useRef();
  const editModalRef = React.useRef();
  const confirmModalRef = React.useRef();
  const timeout = React.useRef(0);
  const [checkModal, setcheckModal] = React.useState(0);
  const COLUMNS = [
    {
      name: FIELD.ACTOR,
      label: "Actor",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#F3F4F6",
            zIndex: 101,
          },
        }),
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.SEGMENT_ID,
      label: "Segment ID",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.SEGMENT_NAME,
      label: "Segment Name",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.NUMBER_OF_SUBSCRIBER,
      label: "Number of Subscriber",
    },
    {
      name: FIELD.LAST_UPDATE,
      label: "Last Update",
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          return value
            ? moment(value).format("DD/MM/YYYY HH:mm:ss")
            : DEFAULT_VALUE;
        },
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <DateTimeFilter
              data={filterList[0][0]}
              options={LIST_TIME_FILTER}
              onChange={(filterObj) => {
                filterList[index][0] = filterObj;
                onChange(filterList[index], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: FIELD.ACTION,
      label: " ",
      options: {
        filter: false,
        sort: false,
        draggable: false,
        searchable: false,
        setCellProps: () => ({
          style: { width: 30, position: "sticky", right: 0, padding: 0 },
        }),
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return <Kebab items={defaultActions(rowIndex)} />;
        },
      },
    },
  ];
  React.useEffect(() => {
    if (peddingRequest) {
      setTimeout(() => {
        dispatch(setPeddingRequest());
      }, 1000);
    }
  }, [peddingRequest]);
  React.useEffect(() => {
    dataStorage.closeSideBar && dataStorage?.closeSideBar(false);
  }, []);
  const defaultActions = React.useCallback(
    (rowIndex) => [
      {
        label: "View",
        onClick: async () => {
          setcheckModal(1);
          const [response, error] = await getData(
            getSegmentDetailUrl(segments[rowIndex][FIELD.SEGMENT_ID])
          );
          if (error) dataStorage.showAlert?.({ type: "error", message: error });
          else editModalRef?.current?.showModal?.(response, false);
        },
      },
      {
        label: "Edit",
        onClick: async () => {
          setcheckModal(2);
          const [response, error] = await getData(
            getSegmentDetailUrl(segments[rowIndex][FIELD.SEGMENT_ID])
          );
          if (error) dataStorage.showAlert?.({ type: "error", message: error });
          else editModalRef?.current?.showModal?.(response, true);
        },
      },
      {
        label: "Delete",
        onClick: () => {
          const segmentId = segments[rowIndex][FIELD.SEGMENT_ID];
          confirmModalRef?.current?.showModal?.(() => onDelete(segmentId));
        },
      },
    ],
    [dispatch, segments]
  );

  const onDelete = async (segmentId) => {
    const [, error] = await deleteData(getDeleteSegmentUrl(segmentId));
    if (error) dataStorage.showAlert?.({ type: "error", message: error });
    else {
      dataStorage.showAlert?.({
        type: "success",
        message: `Delete segment ${segmentId} successfully`,
      });
      dispatch(deleteSegment(segmentId));
    }
  };

  React.useEffect(() => {
    dispatch(
      fetchSegments({
        pageId: paginate.current_page,
        pageSize: paginate.page_size,
        search: searchText.current,
      })
    );
  }, []);

  const onTableChange = (action, tableState) => {
    if (
      ["propsUpdate", "onFilterDialogOpen", "onFilterDialogClose"].includes(
        action
      )
    )
      return;
    timeout.current && clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      const filterObj = getFilterObj(tableState, LIST_FILTER);
      switch (action) {
        case TABLE_ACTION.SORT:
          dispatch(
            fetchSegments({
              pageId: 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.FILTER_CHANGE:
          dispatch(
            fetchSegments({
              pageId: 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.PAGE_CHANGE:
          dispatch(
            fetchSegments({
              pageId: tableState.page + 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.PAGE_SIZE_CHANGE:
          dispatch(
            fetchSegments({
              pageId: paginate.current_page,
              pageSize: tableState.rowsPerPage,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.SEARCH:
          searchText.current = tableState.searchText;
          dispatch(
            fetchSegments({
              pageId: 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.SEARCH_CLOSE:
          if (!searchText.current) return;
          dispatch(
            fetchSegments({
              pageId: paginate.current_page,
              pageSize: paginate.page_size,
              search: "",
              ...filterObj,
            })
          );
          break;
        default:
          break;
      }
    }, 500);
  };

  const renderTable = () => {
    return (
      <CustomTable
        title="Segments"
        loading={loading}
        rows={segments}
        columns={COLUMNS}
        rowsPerPageOptions={[10, 25, 100]}
        rowCount={paginate.total_count}
        pageSize={paginate.page_size}
        pageId={paginate.current_page}
        onTableChange={onTableChange}
      />
    );
  };

  return (
    <>
      <Paper className={classes.paperContainer}>
        {renderTable()}
        <CreateSegmentModal formId="createSegmentForm" ref={createModalRef} />
        <EditSegmentModal
          formId="editSegmentForm"
          ref={editModalRef}
          checkModal={checkModal}
          setcheckModal={setcheckModal}
        />
        <ConfirmModal ref={confirmModalRef} />
        <Fab
          onClick={() => createModalRef?.current?.showModal?.()}
          color="primary"
          aria-label="add"
          className={classes.floatingBtn}
          size="large"
        >
          <AddIcon />
        </Fab>
      </Paper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={peddingRequest}
        // onClick={() => { conosle.log('xin hcao') }}
        style={{ zIndex: 1000 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default Segments;
