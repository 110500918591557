import React from "react";
import { COLUMN_TYPE } from "src/constants";
import moment from "moment";
import {
  Paper,
  Fab,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Kebab from "src/components/Kebab";
import CreateNotificationModal from "src/components/Notifications/CreateNotificationModal";
import PreviewNotification from "src/components/Notifications/PreviewNotification";
import EditNotificationModal from "../components/NewNotification/EditNotificationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  deleteNotification,
  setPeddingRequest,
} from "src/redux/notification/notification";
import { deleteData, getDeleteNotificationUrl } from "src/api/api";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "src/components/ConfirmModal";
import { NOTIFICATION_TYPE } from "src/constants";
import ChipSegment from "src/components/Subscribers/ChipSegment";
import CustomTable, { TABLE_ACTION } from "src/components/CustomTable";
import AddIcon from "@mui/icons-material/Add";
import dataStorage from "src/dataStorage";
import DoneIcon from "@mui/icons-material/Done";
import { getFilterObj } from "src/utils/functionUtils";
import DateTimeFilter from "src/components/DateTimeFilter";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    minHeight: 0,
    overflow: "hidden",
    position: "relative",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
  floatingBtn: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const FIELD = {
  NOTIFICATION_ID: "notification_id",
  NOTIFICATION_TYPE: "notification_type",
  DELIVERY_TYPE: "delivery_type",
  AUDIENCE: "audience_id",
  TITLE: "title",
  MESSAGE: "message",
  URL: "url",
  ACTION1: "action1",
  ACTION2: "action2",
  CREATED_TIME: "create_time",
  EXPIRATION_TIME: "expire_time_timestamp",
  SCHEDULE: "schedule_time",
  TRIGGER_TIME: "trigger_time",
  CHANNEL_MOBILE: "mobile_push",
  CHANNEL_DESKTOP: "desktop_push",
  CHANNEL_EMAIL: "email_push",
  CHANNEL_SMS: "sms_push",
  CHANNEL: "channel",
  STATUS: "status",
  ATTEMPTED: "attempted",
  DELIVERED: "delivered",
  CLICKED: "clicked",
  CTR: "ctr",
  ACTOR: "actor",
  LAST_UPDATED: "last_updated",
  ACTION: "action",
};

const DEFAULT_VALUE = "--";

const LIST_FILTER = [
  FIELD.AUDIENCE,
  FIELD.NOTIFICATION_ID,
  FIELD.NOTIFICATION_TYPE,
  FIELD.DELIVERY_TYPE,
  FIELD.TITLE,
  FIELD.MESSAGE,
  FIELD.URL,
  FIELD.ACTION1,
  FIELD.ACTION2,
  FIELD.CREATED_TIME,
  FIELD.EXPIRATION_TIME,
  FIELD.SCHEDULE,
  FIELD.TRIGGER_TIME,
  FIELD.CHANNEL_MOBILE,
  FIELD.CHANNEL_DESKTOP,
  FIELD.CHANNEL_EMAIL,
  FIELD.CHANNEL_SMS,
  FIELD.STATUS,
  FIELD.ATTEMPTED,
  FIELD.DELIVERED,
  FIELD.CLICKED,
  FIELD.CTR,
  FIELD.ACTOR,
  FIELD.LAST_UPDATED,
  FIELD.ACTION,
];

const LIST_TIME_FILTER = [
  { value: FIELD.CREATED_TIME, label: "Created Time" },
  { value: FIELD.EXPIRATION_TIME, label: "Expiration Time" },
  { value: FIELD.SCHEDULE, label: "Schedule Time" },
  { value: FIELD.TRIGGER_TIME, label: "Triggered Time" },
  { value: FIELD.LAST_UPDATED, label: "Last Updated" },
];

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const paginate = useSelector((state) => state.notification.paginate);
  const loading = useSelector((state) => state.notification.loading);
  const peddingRequest = useSelector(
    (state) => state.notification.peddingRequest
  );
  const searchText = React.useRef("");
  const reviewModalRef = React.useRef();
  const editModalRef = React.useRef();
  const createModalRef = React.useRef();
  const confirmModalRef = React.useRef();
  const timeout = React.useRef(0);
  const [infoNoti, setInfoNoti] = React.useState({});
  const [infoNotiEdit, setInfoNotiEdit] = React.useState({});
  React.useEffect(() => {
    dataStorage.closeSideBar && dataStorage?.closeSideBar(false);
  }, []);
  React.useEffect(() => {
    if (peddingRequest) {
      setTimeout(() => {
        dispatch(setPeddingRequest());
      }, 1000);
    }
  }, [peddingRequest]);
  const renderCellByType = (value, tableMeta) => {
    const { columnData, rowIndex } = tableMeta;
    switch (columnData.type) {
      case COLUMN_TYPE.NOTIFICATION_ACTION:
        return (
          <a href={value?.[0]?.url} target="_blank" rel="noreferrer">
            {value?.[0]?.title}
          </a>
        );
      case COLUMN_TYPE.CHECKBOX:
        return value ? (
          <IconButton>
            <DoneIcon />
          </IconButton>
        ) : (
          <></>
        );
      case COLUMN_TYPE.LINK:
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      case COLUMN_TYPE.CHIP:
        return checkTypeNoti(value, notifications[rowIndex]);
      case COLUMN_TYPE.DATE:
        return value
          ? moment(value).format("DD/MM/YYYY HH:mm:ss")
          : DEFAULT_VALUE;
      default:
        return DEFAULT_VALUE;
    }
  };

  const COLUMNS = [
    {
      name: FIELD.AUDIENCE,
      label: "Audience",
      options: {
        filter: true,
        sort: false,
        type: COLUMN_TYPE.CHIP,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#F3F4F6",
            zIndex: 101,
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <DateTimeFilter
              data={filterList[0][0]}
              options={LIST_TIME_FILTER}
              onChange={(filterObj) => {
                filterList[index][0] = filterObj;
                onChange(filterList[index], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: FIELD.NOTIFICATION_ID,
      label: "Notification ID",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.NOTIFICATION_TYPE,
      label: "Notification Type",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Direct", "Segment"],
        },
      },
    },
    {
      name: FIELD.DELIVERY_TYPE,
      label: "Delivery Type",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Instant", "Schedule"],
        },
      },
    },
    {
      name: FIELD.TITLE,
      label: "Title",
      options: {
        setCellProps: () => ({ style: { minWidth: 180, maxWidth: 250 } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: FIELD.MESSAGE,
      label: "Message",
      options: {
        setCellProps: () => ({ style: { minWidth: 240, maxWidth: 500 } }),
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.URL,
      label: "URL",
      options: {
        setCellProps: () => ({
          style: {
            minWidth: 180,
            maxWidth: 350,
            whiteSpace: "normal",
            wordBreak: "break-word",
          },
        }),
        filter: false,
        sort: false,
        type: COLUMN_TYPE.LINK,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.ACTION1,
      label: "Action 1",
      options: {
        filter: false,
        sort: false,
        type: COLUMN_TYPE.NOTIFICATION_ACTION,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.ACTION2,
      label: "Action 2",
      options: {
        filter: false,
        sort: false,
        type: COLUMN_TYPE.NOTIFICATION_ACTION,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.CREATED_TIME,
      label: "Created Time",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        type: COLUMN_TYPE.DATE,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.EXPIRATION_TIME,
      label: "Expiration Time",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        type: COLUMN_TYPE.DATE,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.SCHEDULE,
      label: "Schedule",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        type: COLUMN_TYPE.DATE,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.TRIGGER_TIME,
      label: "Trigger Time",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        type: COLUMN_TYPE.DATE,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.CHANNEL_MOBILE,
      label: "Channel Mobile",
      options: {
        filter: false,
        sort: false,
        type: COLUMN_TYPE.CHECKBOX,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.CHANNEL_DESKTOP,
      label: "Channel Desktop",
      options: {
        filter: false,
        sort: false,
        type: COLUMN_TYPE.CHECKBOX,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.CHANNEL_EMAIL,
      label: "Channel Email",
      options: {
        filter: false,
        sort: false,
        type: COLUMN_TYPE.CHECKBOX,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.CHANNEL_SMS,
      label: "Channel SMS",
      options: {
        filter: false,
        sort: false,
        type: COLUMN_TYPE.CHECKBOX,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.STATUS,
      label: "Status",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Triggered", "Pending"],
        },
      },
    },
    {
      name: FIELD.ATTEMPTED,
      label: "Attempted",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: FIELD.DELIVERED,
      label: "Delivered",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: FIELD.CLICKED,
      label: "Clicked",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: FIELD.CTR,
      label: "CTR",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: FIELD.ACTOR,
      label: "Actor",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.LAST_UPDATED,
      label: "Last Updated",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        type: COLUMN_TYPE.DATE,
        customBodyRender: (value, tableMeta) => {
          return renderCellByType(value, tableMeta);
        },
      },
    },
    {
      name: FIELD.ACTION,
      label: " ",
      options: {
        filter: false,
        sort: false,
        draggable: false,
        searchable: false,
        setCellProps: () => ({
          style: { width: 30, position: "sticky", right: 0, padding: 0 },
        }),
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          const rowData = notifications[rowIndex];
          // console.log(rowData.status)
          const actions =
            rowData.status == "Triggered"
              ? triggeredActions(rowIndex, rowData)
              : defaultActions(rowIndex);
          // const actions = defaultActions(rowIndex)//when done gonna revert this place
          return <Kebab items={actions} />;
        },
      },
    },
  ];

  const defaultActions = React.useCallback(
    (rowIndex) => [
      {
        label: "View",
        onClick: () => {
          const getUser = notifications[rowIndex];
          setInfoNoti(getUser);
          return reviewModalRef.current.showModal(rowIndex);
        },
      },
      {
        label: "Edit",
        onClick: () => {
          const getUser = notifications[rowIndex];
          setInfoNotiEdit(getUser);
          return editModalRef.current.showModal(rowIndex);
          // return console.log('edit notification.')
        },
      },
      {
        label: "Delete",
        onClick: () => {
          confirmModalRef?.current?.showModal?.(() => onDelete(rowIndex));
        },
      },
    ],
    [notifications]
  );
  const onDelete = async (rowIndex) => {
    const notificationId = notifications[rowIndex][FIELD.NOTIFICATION_ID];
    const [, error] = await deleteData(
      getDeleteNotificationUrl(notificationId)
    );
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
    } else {
      dataStorage.showAlert?.({
        type: "success",
        message: `Delete notification successfully`,
      });
      dispatch(deleteNotification(notificationId));
    }
  };

  const triggeredActions = React.useCallback(
    (rowIndex, row) => [
      {
        label: "View",
        onClick: () => {
          setInfoNoti(row);
          return reviewModalRef.current.showModal(rowIndex);
        },
      },
      {
        label: "Delete",
        onClick: () => {
          confirmModalRef?.current?.showModal?.(() => onDelete(rowIndex));
        },
      },
    ],
    [notifications]
  );

  React.useEffect(() => {
    dispatch(
      fetchNotifications({
        pageId: paginate.current_page,
        pageSize: paginate.page_size,
        search: searchText.current,
      })
    );
  }, []);

  const checkTypeNoti = (value, row) => {
    if (row.notification_type === NOTIFICATION_TYPE.DIRECT) {
      const formatEmail = row.subscribers.map((e) => e.user_login_id);
      return <ChipSegment value={formatEmail} />;
    }
    return row.segment_name;
  };

  const onTableChange = (action, tableState) => {
    if (
      ["propsUpdate", "onFilterDialogOpen", "onFilterDialogClose"].includes(
        action
      )
    )
      return;
    timeout.current && clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      const filterObj = getFilterObj(tableState, LIST_FILTER);
      switch (action) {
        case TABLE_ACTION.SORT:
          dispatch(
            fetchNotifications({
              pageId: 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.FILTER_CHANGE:
          dispatch(
            fetchNotifications({
              pageId: 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.PAGE_CHANGE:
          dispatch(
            fetchNotifications({
              pageId: tableState.page + 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.PAGE_SIZE_CHANGE:
          dispatch(
            fetchNotifications({
              pageId: paginate.current_page,
              pageSize: tableState.rowsPerPage,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.SEARCH:
          searchText.current = tableState.searchText;
          dispatch(
            fetchNotifications({
              pageId: 1,
              pageSize: paginate.page_size,
              search: searchText.current,
              ...filterObj,
            })
          );
          break;
        case TABLE_ACTION.SEARCH_CLOSE:
          if (!searchText.current) return;
          dispatch(
            fetchNotifications({
              pageId: paginate.current_page,
              pageSize: paginate.page_size,
              search: "",
              ...filterObj,
            })
          );
          break;
        default:
          break;
      }
    }, 500);
  };

  const renderTable = () => {
    return (
      <CustomTable
        title="Notifications"
        loading={loading}
        rows={notifications}
        columns={COLUMNS}
        rowsPerPageOptions={[10, 25, 100]}
        rowCount={paginate.total_count}
        pageSize={paginate.page_size}
        pageId={paginate.current_page}
        onTableChange={onTableChange}
      />
    );
  };

  return (
    <>
      <Paper className={classes.paperContainer}>
        {renderTable()}
        <EditNotificationModal ref={editModalRef} infoNoti={infoNotiEdit} />
        <PreviewNotification ref={reviewModalRef} infoNoti={infoNoti} />
        <CreateNotificationModal ref={createModalRef} />
        <ConfirmModal ref={confirmModalRef} />
        <Fab
          onClick={() => createModalRef?.current?.showModal?.()}
          color="primary"
          aria-label="add"
          className={classes.floatingBtn}
          size="large"
        >
          <AddIcon />
        </Fab>
      </Paper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={peddingRequest}
        style={{ zIndex: 1000 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    </>
  );
};

export default Notifications;
