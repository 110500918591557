import React from "react";
import {
  TextField,
  CircularProgress,
  Autocomplete,
  ClickAwayListener,
} from "@mui/material";
import { at } from "lodash";
import { useField } from "formik";
import { getData, getSearchAddressUrl } from "src/api/api";
import { removeVietnamese } from "src/utils/functionUtils";
import logger from "src/utils/logger";

export default function AutocompleteFieldAsync(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const { setValue: setValueForm } = helper;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const timeoutId = React.useRef();

  React.useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    };
  }, []);

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const getListSuggestAddress = async (text = "") => {
    if (text.length < 2) return;
    setLoading(true);
    const url = getSearchAddressUrl(text);
    const [res, err] = await getData(url);
    if (err) {
      setLoading(false);
      logger.error(err, "getListSuggestAddress error: ");
    } else {
      const filterFields = res.filter((item, index, array) => {
        const checkDuplicate = array.filter(
          (e) => e.full_address == item.full_address
        );
        return !(checkDuplicate.length > 1);
      });
      setOptions(filterFields || []);
      setLoading(false);
    }
  };

  function onChangeText(e) {
    if (!open) setOpen(true);
    const text = removeVietnamese(e.target.value || "");
    timeoutId.current && clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => getListSuggestAddress(text), 500);
  }

  function _onChange(event, option, reason) {
    if (reason === "selectOption") {
      setValueForm && setValueForm(option);
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Autocomplete
          style={{ flex: 1 }}
          {...field}
          {...rest}
          onChange={_onChange}
          disableClearable
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={(x) => x}
          getOptionLabel={(option) => option?.full_address || ""}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              {...rest}
              variant="outlined"
              error={touched && error && true}
              helperText={_renderHelperText()}
              onChange={onChangeText}
              label={label}
              type="search"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
    </ClickAwayListener>
  );
}
