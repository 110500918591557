import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Paper } from "@mui/material";
import Kebab from "src/components/Kebab";
import { getData, getOpeningAccountUrl } from "src/api/api";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "src/components/ConfirmModal";
import ViewApplicationModal from "src/components/Onboarding/ViewApplicationModal";
import CustomTable, { TABLE_ACTION } from "src/components/CustomTable";
import dataStorage from "src/dataStorage";
import {
  getAccountStatus,
  approveAplication,
  moveAplicationToDraft,
  deleteApplication,
  kycApplication,
  rejectApplication,
} from "src/utils/functionUtils";
import {
  ACCOUNT_TYPE,
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_LABEL,
  ACCOUNT_TYPE_TEXT,
  USER_TYPE_ACCOUNT,
} from "src/constants";
import ChipAccountStatus from "../components/Notifications/ChipAccountStatus";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    minHeight: 0,
    overflow: "hidden",
    position: "relative",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
}));

const FIELD = {
  APPLICATION_ID: "application_id",
  APPLICATION_NAME: "name",
  ACCOUNT_TYPE: "account_type",
  LAST_UPDATE: "updated",
  ACTION: "action",
  ACCOUNT_STATUS: "account_status",
  ACCOUNT_STATUS_LABEL: "account_status_label",
};

const defaultPaginate = {
  pageSize: 10,
  currentPage: 1,
  totalCount: 0,
  totalPages: 0,
};

const DEFAULT_VALUE = "--";

const Drafts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const paginate = React.useRef(defaultPaginate);
  const searchText = React.useRef("");
  const confirmModalRef = React.useRef();
  const modalViewApplicationRef = React.useRef();
  const timeout = React.useRef(0);
  const COLUMNS = [
    {
      name: FIELD.APPLICATION_ID,
      label: "Application ID",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.APPLICATION_NAME,
      label: "Application Name",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.ACCOUNT_TYPE,
      label: "Account Type",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        filterType: "dropdown",
        filterOptions: {
          names: [
            ACCOUNT_TYPE.INDIVIDUAL,
            ACCOUNT_TYPE.JOINT,
            ACCOUNT_TYPE.COMPANY,
            ACCOUNT_TYPE.TRUST,
          ],
        },
      },
    },
    {
      name: FIELD.ACCOUNT_STATUS_LABEL,
      label: "Account Status",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        filterType: "dropdown",
        filterOptions: {
          names: Object.values(ACCOUNT_STATUS),
        },
        customBodyRender: (value) => {
          return <ChipAccountStatus value={value} />;
        },
      },
    },
    {
      name: FIELD.LAST_UPDATE,
      label: "Last Update",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value
            ? moment(value).format("DD/MM/YYYY HH:mm:ss")
            : DEFAULT_VALUE;
        },
      },
    },
    {
      name: FIELD.ACTION,
      label: " ",
      options: {
        filter: false,
        sort: false,
        draggable: false,
        searchable: false,
        setCellProps: () => ({
          style: { width: 30, position: "sticky", right: 0, padding: 0 },
        }),
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return <Kebab items={defaultActions(rowIndex)} />;
        },
      },
    },
  ];

  const onKYC = (equixId, accountType, rowIndex) => {
    kycApplication(equixId, ACCOUNT_TYPE_TEXT[accountType], (status) => {
      rows[rowIndex][FIELD.ACCOUNT_STATUS] = status;
      setRows(rows.slice(0));
    });
  };

  const defaultActions = React.useCallback(
    (rowIndex) => {
      const accountStatus = rows[rowIndex][FIELD.ACCOUNT_STATUS];
      const accountType = rows[rowIndex][FIELD.ACCOUNT_TYPE];
      const renderAction = [
        {
          label: "View",
          onClick: () => {
            onRowClick(rowIndex);
          },
        },
      ];
      if (accountStatus === ACCOUNT_STATUS.IN_DEVELOPMENT) return renderAction;
      if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
        if (
          [
            ACCOUNT_TYPE.TRUST,
            ACCOUNT_TYPE.COMPANY,
            ACCOUNT_TYPE.FUND,
          ].includes(accountType) &&
          accountStatus === ACCOUNT_STATUS.IN_KYC
        ) {
          renderAction.push({
            label: "KYC",
            onClick: () => {
              const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
              confirmModalRef?.current?.showModal?.(
                () => onKYC(equixId, accountType, rowIndex),
                {
                  title: "Confirm kyc",
                  content: "Are you sure you want to kyc this application?",
                }
              );
            },
          });
        }
      }

      if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
        if (
          ![
            ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT,
            ACCOUNT_STATUS.IN_PENDING_DEVELOPMENT,
            ACCOUNT_STATUS.REJECTED,
          ].includes(accountStatus)
        ) {
          renderAction.push({
            label: "Move to draft",
            onClick: () => {
              const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
              confirmModalRef?.current?.showModal?.(
                () => onMoveToDraft(equixId, rows[rowIndex]),
                {
                  title: "Confirm move to draft",
                  content:
                    "Are you sure you want to move this application to draft?",
                }
              );
            },
          });
        }
      }
      if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
        if (
          accountStatus === ACCOUNT_STATUS.PENDING_KYC_APPROVAL ||
          accountStatus === ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT
        ) {
          renderAction.push({
            label: "Approve",
            onClick: () => {
              const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
              const accountStatus = rows[rowIndex][FIELD.ACCOUNT_STATUS];
              confirmModalRef?.current?.showModal?.(
                () => approveAplication(equixId, accountStatus),
                {
                  title: "Confirm approve application",
                  content: "Are you sure you want to approve this application?",
                }
              );
            },
          });
        }
      }
      if (dataStorage.userInfo?.user_type !== USER_TYPE_ACCOUNT.Advisor) {
        if (accountType === ACCOUNT_TYPE.JOINT) {
          renderAction.push({
            label: "Delete",
            onClick: () => {
              const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
              confirmModalRef?.current?.showModal?.(
                () => onDeleteApplication(equixId),
                {
                  title: "Confirm delete application",
                  content: "Are you sure you want to delete this application?",
                }
              );
            },
          });
        }
      }
      if (
        dataStorage.userInfo?.user_type !== USER_TYPE_ACCOUNT.Advisor &&
        rows[rowIndex].account_status ===
          ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT
      ) {
        renderAction.push({
          label: "Reject",
          onClick: () => {
            const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
            confirmModalRef?.current?.showModal?.(
              () => onRejectApplication(equixId),
              {
                title: "Confirm reject application",
                content: "Are you sure you want to reject this application?",
              }
            );
          },
        });
      }
      return renderAction;
    },
    [dispatch, rows]
  );

  const onDeleteApplication = async (equixId) => {
    deleteApplication(equixId, () => {
      setRows(rows.slice(0).filter((e) => e[FIELD.APPLICATION_ID] !== equixId));
    });
  };
  const onRejectApplication = async (equixId) => {
    rejectApplication(equixId, () => {
      setRows(rows.slice(0).filter((e) => e[FIELD.APPLICATION_ID] !== equixId));
    });
  };

  const onMoveToDraft = async (equixId, account) => {
    moveAplicationToDraft(
      equixId,
      () => {
        setRows(
          rows.slice(0).filter((e) => e[FIELD.APPLICATION_ID] !== equixId)
        );
      },
      account?.account_status
    );
  };

  const updatePaginate = (obj) => {
    paginate.current = {
      pageSize: obj.page_size,
      currentPage: obj.current_page,
      totalCount: obj.total_count,
      totalPages: obj.total_pages,
    };
  };

  const getDraft = async ({ pageId, pageSize }) => {
    setLoading(true);
    const [response, error] = await getData(
      getOpeningAccountUrl(
        `/draft?status=pending&page_size=${pageSize}&page_id=${pageId}`
      )
    );
    setLoading(false);
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
      return;
    }
    const { data, ...rest } = response;
    updatePaginate({ ...rest, page_size: pageSize });
    const listData = data
      ? Object?.keys(data)?.map((k) => ({
          [FIELD.DRAFT_ID]: k,
          [FIELD.APPLICATION_ID]: data[k]?.data?.formData?.equix_id,
          [FIELD.LAST_UPDATE]: data[k]?.data?.updated,
          [FIELD.ACCOUNT_TYPE]: data[k]?.data?.formData?.account_type,
          [FIELD.ACCOUNT_STATUS]: data[k]?.status,
          [FIELD.ACCOUNT_STATUS_LABEL]: ACCOUNT_STATUS_LABEL[data[k]?.status],
          ...data[k],
        }))
      : [];
    setRows(listData);
  };

  React.useEffect(() => {
    getDraft({
      pageId: paginate.current.currentPage,
      pageSize: paginate.current.pageSize,
      search: searchText.current,
    });
  }, []);

  const onTableChange = (action, tableState) => {
    timeout.current && clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      switch (action) {
        case TABLE_ACTION.SORT:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.FILTER_CHANGE:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.PAGE_CHANGE:
          getDraft({
            pageId: tableState.page + 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.PAGE_SIZE_CHANGE:
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: tableState.rowsPerPage,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.SEARCH:
          searchText.current = tableState.searchText;
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.SEARCH_CLOSE:
          if (!searchText.current) return;
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        default:
          break;
      }
    }, 500);
  };

  const onRowClick = async (index) => {
    const rowData = rows[index];
    dataStorage.dicDraft = rowData;
    dataStorage.accountType =
      dataStorage.dicDraft?.data?.formData?.account_type;
    if (dataStorage.dicDraft?.data?.index) {
      dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0;
      dataStorage.isSubApplicant = true;
    }
    dataStorage.registerEmail =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_email;
    dataStorage.applicantId =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_id;
    const equixId = dataStorage.dicDraft?.data?.formData?.equix_id;
    dataStorage.equixId = equixId;
    if (equixId && !dataStorage.isSubApplicant) await getAccountStatus(equixId);
    else dataStorage.accountStatus = null;
    if (dataStorage.accountType.value)
      dataStorage.accountType = dataStorage.accountType.value;
    // navigate(`/${dataStorage.env}/onboarding/new_application`)
    modalViewApplicationRef?.current?.showModal?.();
  };

  const renderTable = () => {
    return (
      <CustomTable
        title="Pending Applications"
        loading={loading}
        rows={rows}
        onRowClick={onRowClick}
        columns={COLUMNS}
        rowsPerPageOptions={[10, 25, 100]}
        rowCount={paginate.current.totalCount}
        pageSize={paginate.current.pageSize}
        pageId={paginate.current.currentPage}
        onTableChange={onTableChange}
      />
    );
  };

  return (
    <Paper className={classes.paperContainer}>
      {renderTable()}
      <ConfirmModal ref={confirmModalRef} />
      <ViewApplicationModal ref={modalViewApplicationRef} />
    </Paper>
  );
};

export default Drafts;
