import React from "react";
import { Grid, Typography, Button, Stack } from "@mui/material";
import {
  InputField,
  SelectField,
  PasswordGenarate,
  CheckboxField,
  SearchUserAccountField,
  SearchOrganization,
} from "src/components/CustomFields";
import { getUserGroupUrl, getEmailTempUrl } from "src/api/api";
import { initialState } from "../FormModels/Forminitial";
import FormModel from "../FormModels/FormModel";
import { useFormikContext } from "formik";
import useFetch from "src/components/Hooks/useFetch";
import {
  USER_STATUS_OPTIONS,
  ACCESS_METHOD_OPTIONS,
  API_ACCESS_OPTIONS,
  USER_GROUP_OPTIONS,
  USER_STATUS,
  API_ACCESS_ENUM,
} from "src/constants";
import CircularProgress from "@mui/material/CircularProgress";
import useStyles from "../../styles";

const New_user = () => {
  const classes = useStyles();
  const { isSubmitting, values, submitForm, resetForm } = useFormikContext();
  const { data: dataRoleGroup } = useFetch({ url: getUserGroupUrl() });
  const { data: dataEmail } = useFetch({ url: getEmailTempUrl() });
  let statusApiAccess = +(values.api_access?.value != null
    ? values.api_access?.value
    : values.api_access);
  const formatdataRoleGroup = React.useCallback(() => {
    return dataRoleGroup
      .map((e) => {
        try {
          const { group_id, group_name } = e;
          const transformLabel = group_name.split(/(\s|-)/gm);
          const textTransformed = transformLabel
            .map((e) => e[0].toUpperCase() + e.slice(1).toLowerCase())
            .filter((e) => e.length !== 1)
            .join(" ");
          return { value: group_id, label: textTransformed };
        } catch (error) {
          return e;
        }
      })
      .filter((e) => e.value != "DEFAULT");
  }, [dataRoleGroup?.length]);
  const formatDataEmail = React.useCallback(() => {
    return dataEmail
      .map((e) => {
        try {
          const { id, name } = e;
          return { value: id, label: name };
        } catch (error) {
          return e;
        }
      })
      .filter((e) => e.value != "DEFAULT");
  }, [dataEmail?.length]);
  const clearData = React.useCallback(() => resetForm({ ...initialState }), []);
  const checkboxChanel = () => {
    return (
      <Grid container spacing={0} style={{ borderRadius: 5, paddingBottom: 6 }}>
        <Grid item xs={12} md={12} className={classes.styleCheckbox}>
          <CheckboxField
            disabled={true}
            disableTextHelper={true}
            name={FormModel.formField.send_password.name}
            label={FormModel.formField.send_password.label}
          />
        </Grid>
        <Grid item xs={12} md={12} className={classes.styleCheckbox}>
          <CheckboxField
            disabled={true}
            disableTextHelper={true}
            name={FormModel.formField.change_password.name}
            label={FormModel.formField.change_password.label}
          />
        </Grid>
      </Grid>
    );
  };
  const userDetail = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>User Detail</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              required
              name={FormModel.formField.full_name.name}
              label={FormModel.formField.full_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              required
              autoComplete={"off"}
              name={FormModel.formField.user_login_id.name}
              label={FormModel.formField.user_login_id.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name={FormModel.formField.phone.name}
              label={FormModel.formField.phone.label}
              fullWidth
            />
          </Grid>
          {(values.status?.value || values.status) == USER_STATUS.ACTIVE ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <PasswordGenarate
                  name={FormModel.formField.password.name}
                  autoComplete={"off"}
                  label={FormModel.formField.password.label}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {checkboxChanel()}
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12} sm={6}></Grid>
          )}
          <Grid item xs={12} sm={4}>
            <SelectField
              required
              name={FormModel.formField.role_group.name}
              label={FormModel.formField.role_group.label}
              data={formatdataRoleGroup()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              name={FormModel.formField.email_template.name}
              label={FormModel.formField.email_template.label}
              data={formatDataEmail()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              name={FormModel.formField.status.name}
              label={FormModel.formField.status.label}
              data={USER_STATUS_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField
              name={FormModel.formField.note.name}
              label={FormModel.formField.note.label}
              rows={3}
              maxRows={3}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const accessPermission = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Access Permission
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              disabled={true}
              name={FormModel.formField.access_method.name}
              label={FormModel.formField.access_method.label}
              data={ACCESS_METHOD_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              required
              name={FormModel.formField.api_access.name}
              label={FormModel.formField.api_access.label}
              data={API_ACCESS_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectField
              required
              name={FormModel.formField.user_group.name}
              label={FormModel.formField.user_group.label}
              data={USER_GROUP_OPTIONS}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const accountManagement = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Account Management
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SearchUserAccountField
              name={FormModel.formField.list_mapping.name}
              label={FormModel.formField.list_mapping.label}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const organizationManagement = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Organization Management
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SearchOrganization
              name={FormModel.formField.organization_management.name}
              statusSearch={0}
              label={FormModel.formField.organization_management.label}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const branchManagement = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Branch Management
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SearchOrganization
              name={FormModel.formField.branch_management.name}
              statusSearch={1}
              label={FormModel.formField.branch_management.label}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const AdvisorManagement = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              Advisor Management
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SearchOrganization
              name={FormModel.formField.manage_advisor.name}
              statusSearch={2}
              label={FormModel.formField.manage_advisor.label}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const buttonActions = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.styleButtonClose}
            onClick={clearData}
          >
            Clear Data
          </Button>
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size="25px" />
              ) : (
                "Create New User"
              )}
            </Button>
          </Stack>
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Create User
        </Typography>
      </Grid>
      {userDetail()}
      {accessPermission()}
      {statusApiAccess == API_ACCESS_ENUM.Advisor ? (
        <React.Fragment>
          {organizationManagement()}
          {branchManagement()}
          {AdvisorManagement()}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      {[API_ACCESS_ENUM.Retail, API_ACCESS_ENUM.Advisor].includes(
        statusApiAccess
      ) ? (
        <React.Fragment>{accountManagement()}</React.Fragment>
      ) : (
        <React.Fragment />
      )}
      {buttonActions()}
    </React.Fragment>
  );
};

export default New_user;
