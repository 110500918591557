import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import fromModels from "../../NewUser/FormModels/FormModel";
import {
  USER_STATUS_OPTIONS,
  ACCESS_METHOD_OPTIONS,
  USER_GROUP_OPTIONS,
  API_ACCESS_EDIT_OPTIONS,
} from "src/constants";
import { useFormikContext } from "formik";
import {
  InputField,
  SelectField,
  SearchUserAccountField,
  SearchOrganization,
} from "src/components/CustomFields";

export const API_ACCESS = {
  retail: "retail",
  advisor: "advisor",
  operation: "operation",
};
const FormUserEdit = ({ formatDataEmail, formatdataRoleGroup }) => {
  const { values } = useFormikContext();
  const renderManagement = () => {
    return (
      <React.Fragment>
        {values?.user_type?.value == API_ACCESS.advisor ? (
          <>
            <Grid item xs={12} sm={12}>
              <SearchOrganization
                name={fromModels.formField.organization_management.name}
                statusSearch={0}
                label={fromModels.formField.organization_management.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SearchOrganization
                name={fromModels.formField.branch_management.name}
                statusSearch={1}
                label={fromModels.formField.branch_management.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SearchOrganization
                name={fromModels.formField.manage_advisor.name}
                statusSearch={2}
                label={fromModels.formField.manage_advisor.label}
                fullWidth
              />
            </Grid>
          </>
        ) : null}
        {[API_ACCESS.retail, API_ACCESS.advisor].includes(
          values?.user_type?.value
        ) ? (
          <>
            <Grid item xs={12} sm={12}>
              <SearchUserAccountField
                name={fromModels.formField.list_mapping.name}
                label={fromModels.formField.list_mapping.label}
                fullWidth
              />
            </Grid>
          </>
        ) : null}
        {values?.user_type?.value == API_ACCESS.operation ? (
          <>
            <Grid item xs={12} sm={12}>
              <TextField
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label={"Manage"}
                value={"Operators have access all Operator API by default"}
                fullWidth
              />
            </Grid>
          </>
        ) : null}
      </React.Fragment>
    );
  };
  // console.log(formatDataEmail)
  const userInfo = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              User Infomation
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              required
              name={fromModels.formField.full_name.name}
              label={fromModels.formField.full_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              disabled
              name={fromModels.formField.user_login_id.name}
              label={fromModels.formField.user_login_id.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name={fromModels.formField.phone.name}
              label={fromModels.formField.phone.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              name={fromModels.formField.email_template.name}
              label={fromModels.formField.email_template.label}
              data={formatDataEmail || []}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              required
              disabled={true}
              name={fromModels.formField.access_method.name}
              label={fromModels.formField.access_method.label}
              data={ACCESS_METHOD_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              name={"user_type"}
              label={fromModels.formField.api_access.label}
              data={API_ACCESS_EDIT_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              required
              name={fromModels.formField.user_group.name}
              label={fromModels.formField.user_group.label}
              data={USER_GROUP_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              name={fromModels.formField.status.name}
              label={fromModels.formField.status.label}
              data={USER_STATUS_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              required
              name={fromModels.formField.role.name}
              label={fromModels.formField.role.label}
              data={formatdataRoleGroup}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              disabled
              name={fromModels.formField.last_active.name}
              label={fromModels.formField.last_active.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              disabled
              name={fromModels.formField.whitelable.name}
              label={fromModels.formField.whitelable.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              disabled
              name={fromModels.formField.create_time.name}
              label={fromModels.formField.create_time.label}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const Management = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>Management</Typography>
          </Grid>
          {renderManagement()}
        </Grid>
      </React.Fragment>
    );
  };
  const Notes = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>Notes</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField
              name={fromModels.formField.note.name}
              label={fromModels.formField.note.label}
              rows={3}
              maxRows={3}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {userInfo()}
      {Management()}
      {Notes()}
    </React.Fragment>
  );
};

export default FormUserEdit;
