import React from "react";
import { makeStyles } from "@mui/styles";
import { useFormikContext, FieldArray } from "formik";
import formModels from "./formModels";
import { InputField } from "../CustomFields";
import { NOTIFICATION_TYPE, DELIVERY_TYPE } from "src/constants";
import ChooseTypeQueryUrlReadOnly from "./chooseTypeUrlReadOnly";
import {
  FormControlLabel,
  IconButton,
  Grid,
  Chip,
  FormHelperText,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    alignItems: "center",
    // background: 'red'
  },
  styleCheckBox: {
    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: 0,
      "& legend": {
        marginRight: 16,
        color: "black",
      },
    },
  },
  spaceBox: {
    marginBottom: 14,
  },
  styleCheckbox: {
    "& .MuiFormControl-root": {
      padding: 0,
      paddingTop: 8,
      "& .MuiButtonBase-root": {
        paddingBottom: 0,
      },
      "& .MuiTypography-root": {
        paddingTop: 3,
      },
    },
  },
  styleImage: {
    objectFit: "cover",
    borderRadius: 5,
    maxWidth: 150,
    maxHeight: 150,
  },
  styleMessage: {
    "& .MuiFormControl-root": {
      "& .MuiInputBase-root": {
        paddingBottom: 0,
        paddingTop: 5,
      },
    },
  },
  labelUploadFile: {
    color: "#65748B",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.4375em",
    fontFamily:
      '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
    transformOrigin: "top left",
    whiteSpace: "nowrap",
    "-webkit-transform": "translate(0, -1.5px) scale(0.75)",
  },
}));
function FormViewNotificationForm() {
  const classes = useStyles();
  const formik = useFormikContext();
  const checkboxChanel = () => {
    return (
      <Grid container spacing={0} style={{ borderRadius: 5, paddingTop: 8 }}>
        <Grid item xs={12} md={3} className={classes.styleCheckbox}>
          {formik.values?.mobile_push ? (
            <FormControlLabel
              label={formModels.formField.mobile_push.label}
              control={
                <IconButton>
                  <DoneIcon />
                </IconButton>
              }
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={3} className={classes.styleCheckbox}>
          {formik.values?.desktop_push ? (
            <FormControlLabel
              label={formModels.formField.desktop_push.label}
              control={
                <IconButton>
                  <DoneIcon />
                </IconButton>
              }
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={3} className={classes.styleCheckbox}>
          {formik.values?.email_push ? (
            <FormControlLabel
              label={formModels.formField.email_push.label}
              control={
                <IconButton>
                  <DoneIcon />
                </IconButton>
              }
            />
          ) : null}
        </Grid>

        <Grid item xs={12} md={3} className={classes.styleCheckbox}>
          {formik.values?.sms_push ? (
            <FormControlLabel
              label={formModels.formField.sms_push.label}
              control={
                <IconButton>
                  <DoneIcon />
                </IconButton>
              }
            />
          ) : null}
        </Grid>
      </Grid>
    );
  };
  // console.log(formik.values?.subsribers.length ? true : false)
  const searchEmail = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <p className={classes.labelUploadFile}>Subscribers</p>
          {formik.values.subsribers.length ? (
            <FieldArray name="subsribers">
              {(fieldArrayProps) => {
                const { form } = fieldArrayProps;
                // console.log(form.values.subsribers)
                return form.values.subsribers.map((current, index) => {
                  return (
                    <>
                      <span key={index}>
                        <Chip label={current?.user_login_id || ""} />
                        <span style={{ marginRight: 5 }}></span>
                      </span>
                    </>
                  );
                });
              }}
            </FieldArray>
          ) : null}
        </Grid>
      </>
    );
  };
  const errorHelperChanel = () => {
    const { mobile_push, desktop_push, email_push, sms_push } = formik?.errors;
    const {
      mobile_push: mobile_pushTouch,
      desktop_push: desktop_pushTouch,
      email_push: email_pushTouch,
      sms_push: sms_pushTouch,
    } = formik.touched;
    const helperError = mobile_push || desktop_push || email_push || sms_push;
    const touchedFields =
      mobile_pushTouch || desktop_pushTouch || email_pushTouch || sms_pushTouch;
    return helperError && touchedFields ? (
      <FormHelperText error={true} style={{ margin: "0 14px" }}>
        {helperError}
      </FormHelperText>
    ) : null;
  };
  const chooseTypeNoti = () => {
    return (
      <Grid item xs={12} md={12} style={{ margin: 0, padding: "0 5px 0 5px" }}>
        <Grid
          container
          spacing={2}
          style={{ margin: 0, paddingRight: 0, paddingTop: 0, width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12} className={classes.styleCheckBox}>
              {/* <RadioField name={formModels.formField.notification_type.name} disabled={true} label={formModels.formField.notification_type.label} fullWidth data={NOTIFICATION_TYPE_OPTIONS} /> */}
              <InputField
                name={formModels.formField.notification_type.name}
                label={formModels.formField.notification_type.label}
                fullWidth
                inputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>
          {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
            <>
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12} className={classes.styleCheckBox}>
                  <InputField
                    name={formModels.formField.delivery_type.name}
                    label={formModels.formField.delivery_type.label}
                    fullWidth
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                  {/* <RadioField name={formModels.formField.delivery_type.name} disabled={true} label={formModels.formField.delivery_type.label} fullWidth data={DELIVERY_TYPE_OPTIONS} /> */}
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    );
  };
  const searchField = () => {
    return (
      <Grid item xs={12} md={12} style={{ margin: 0, padding: "0 5px 0 5px" }}>
        <Grid
          container
          spacing={2}
          style={{ margin: 0, paddingRight: 0, paddingTop: 0, width: "100%" }}
        >
          {formik.values?.notification_type ? (
            <>
              {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
                searchEmail()
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.segment.name}
                      label={formModels.formField.segment.label}
                      fullWidth
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                    />
                  </Grid>
                </>
              )}
            </>
          ) : null}

          {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.expire_number.name}
                      label={formModels.formField.expire_number.label}
                      type="number"
                      fullWidth
                      style={{ marginRight: 7 }}
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.option_type.name}
                      label={formModels.formField.option_type.label}
                      fullWidth
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                    />
                  </Grid>
                  {/* <SelectField name={'optionType'} label="Type" data={EXPIRE_TIME_OPTIONS} checkReview={true} fullWidth variant="standard" /> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  {formik.values?.delivery_type !== DELIVERY_TYPE.INSTANT ? (
                    <InputField
                      name={formModels.formField.schedule_time.name}
                      label={formModels.formField.schedule_time.label}
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="standard"
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputField
                    name={formModels.formField.attempted.name}
                    label={formModels.formField.attempted.label}
                    fullWidth
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputField
                    name={formModels.formField.delivered.name}
                    label={formModels.formField.delivered.label}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
  const actions = () => {
    return (
      <Grid item xs={12} md={12} style={{ margin: 0, padding: "0 5px 0 5px" }}>
        <Grid
          container
          spacing={2}
          style={{ margin: 0, paddingRight: 0, width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            {checkboxChanel()}
            {errorHelperChanel()}
          </Grid>
          {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
            <Grid item xs={12} md={6}>
              <InputField
                name={formModels.formField.action1_title.name}
                label={formModels.formField.action1_title.label}
                InputProps={{ readOnly: true }}
                fullWidth
                variant="standard"
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputField
                    name={formModels.formField.clicked.name}
                    label={formModels.formField.clicked.label}
                    fullWidth
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputField
                    name={formModels.formField.ctr.name}
                    label={formModels.formField.ctr.label}
                    fullWidth
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
  const FieldDisplayNoti = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <InputField
                    required
                    name={formModels.formField.title.name}
                    label={formModels.formField.title.label}
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>

            {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
              <>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={12}>
                    <InputField
                      name={formModels.formField.action1_url.name}
                      label={formModels.formField.action1_url.label}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.actor.name}
                      label={formModels.formField.actor.label}
                      fullWidth
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.last_updated.name}
                      label={formModels.formField.last_updated.label}
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid item xs={12} md={12} className={classes.styleMessage}>
                <InputField
                  required
                  name={formModels.formField.message.name}
                  label={formModels.formField.message.label}
                  multiline
                  rows={4}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            </Grid>

            {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
              <>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={12} style={{ marginBottom: 17 }}>
                    <InputField
                      name={formModels.formField.action2_title.name}
                      label={formModels.formField.action2_title.label}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} spacing={2} container>
                    <Grid item xs={12} md={6}>
                      <InputField
                        name={formModels.formField.action2_url.name}
                        label={formModels.formField.action2_url.label}
                        InputProps={{ readOnly: true }}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputField
                        name={formModels.formField.delivered.name}
                        label={formModels.formField.delivered.label}
                        fullWidth
                        InputProps={{ readOnly: true }}
                        variant="standard"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12}>
                  <InputField
                    name={formModels.formField.status.name}
                    label={formModels.formField.status.label}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  };
  const chooseTypeURL = () => {
    return (
      <Grid item xs={12} md={12} style={{ margin: 0, padding: "0 5px 0 5px" }}>
        <Grid
          container
          spacing={2}
          style={{ margin: 0, paddingRight: 0, width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={12}>
              {/* <SelectField required name={'Type_Url'} label={'Type Url'} checkReview={true} fullWidth data={TYPE_URL} variant="standard" /> */}
              <InputField
                name={formModels.formField.type_url_label.name}
                label={formModels.formField.type_url_label.label}
                fullWidth
                InputProps={{ readOnly: true }}
                variant="standard"
              />
              {/* <InputField  required name={formModels.formField.url.name} label={formModels.formField.url.label} fullWidth /> */}
            </Grid>
          </Grid>
          {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputField
                    name={formModels.formField.attempted.name}
                    label={formModels.formField.attempted.label}
                    fullWidth
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputField
                    name={formModels.formField.status.name}
                    label={formModels.formField.status.label}
                    fullWidth
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };
  const uploadField = () => {
    return (
      <Grid item xs={12} md={12} style={{ margin: 0, padding: "0 5px 0 5px" }}>
        <Grid
          container
          spacing={2}
          style={{ margin: 0, paddingRight: 0, width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <div style={{ padding: 4 }}>
                  <p className={classes.labelUploadFile}>{"Icon"}</p>
                  {formik.values.icon ? (
                    <div>
                      <img
                        src={formik.values.icon}
                        className={classes.styleImage}
                        style={{ maxWidth: 75 }}
                        alt=""
                      />
                    </div>
                  ) : null}
                </div>
              </Grid>

              {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT ? (
                <Grid item xs={6} md={6}>
                  <div style={{ padding: 4 }}>
                    <p className={classes.labelUploadFile}>{"Image"}</p>
                    {formik.values.large_image ? (
                      <div>
                        <img
                          src={formik.values.large_image}
                          className={classes.styleImage}
                          alt=""
                        />
                      </div>
                    ) : null}
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container style={{ width: "100%" }}></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container spacing={3}>
      {chooseTypeNoti()}
      {searchField()}
      {actions()}
      {FieldDisplayNoti()}
      {chooseTypeURL()}
      <ChooseTypeQueryUrlReadOnly classes={classes} checkReview={true} />
      {uploadField()}
    </Grid>
  );
}

export default FormViewNotificationForm;
