
import Yup from 'src/utils/customYup'
import { } from 'src/constants'
import FormModel from './FormModel'

export default Yup.object().shape({
    [FormModel.formField.full_name.name]: Yup.string().checkRequired('Full Name is required'),
    [FormModel.formField.user_login_id.name]: Yup.string().checkRequired('User Login is required').checkApplicantEmail('User Login is invalid'),
    [FormModel.formField.role_group.name]: Yup.mixed().checkRequired('Role Group is required'),
    [FormModel.formField.password.name]: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z].{7,}$/, 'Your password must include 8-25 characters and contain at least one lowercase, one capital letter and one number'),
    [FormModel.formField.phone.name]: Yup.string().matches(/^([a-z]{2}\|)?[0-9+\s\-()]*$/, 'Phone Number is invalid'),
    [FormModel.formField.api_access.name]: Yup.mixed().checkRequired('Api Access is required'),
    [FormModel.formField.user_group.name]: Yup.mixed().checkRequired('User Group is required')
})