import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dataStorage from "src/dataStorage";

const ProtectedRoute = ({ children }) => {
  const isLogin = useSelector((state) => state.auth.isLogin);

  if (isLogin) return children;

  return <Navigate to={`/${dataStorage.env}/login`} />;
};

export default ProtectedRoute;
