import React from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { DashboardLayout } from './components/DashboardLayout';
import { MainLayout } from './components/MainLayout';
import ProtectedRoute from './components/ProtectedRoute';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import dataStorage from './dataStorage';
import NewApplication from './pages/new_application';
import Drafts from './pages/drafts';
import PendingApplication from './pages/pending_application';
import DoneApplication from './pages/completed_application';
import RejectApplication from './pages/reject_application';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import NotFound from './pages/404';
import Notifications from './pages/notifications';
import Subscribers from './pages/subscribers';
import Segments from './pages/segments';
import NewUser from './pages/new_user';
import UserManagement from './pages/user_management';
import { useSelector } from 'react-redux'
import Alert from 'src/components/Alert'
import {
    addEventListenerWindow
} from 'src/utils/functionUtils'

const App = () => {
    const isLogin = useSelector(state => state.auth.isLogin)
    const UrlParams = new URLSearchParams(window.location.search);
    const otp = UrlParams.get('otp');
    const accessToken = UrlParams.get('accessToken');

    React.useEffect(() => {
        addEventListenerWindow
    }, [])

    const renderRoutes = () => {
        if (otp || accessToken) {
            return (
                <Routes>
                    <Route path={`${dataStorage.env}`} element={<MainLayout />}>
                        <Route path='' element={<div style={{ padding: 56 }}>
                            <div id='topModal' />
                            <NewApplication /></div>} />
                        <Route path='*' element={<div style={{ padding: 56 }} >
                            <div id='topModal' />
                            <NewApplication /></div>} />
                    </Route>
                </Routes>
            )
        }
        return (
            <Routes>
                <Route path={`${dataStorage.env}`} element={isLogin ? <DashboardLayout /> : <MainLayout />}>
                    <Route path='' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path='login' element={<Login />} />
                    <Route path='notifications' element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
                    <Route path='subscribers' element={<ProtectedRoute><Subscribers /></ProtectedRoute>} />
                    <Route path='segments' element={<ProtectedRoute><Segments /></ProtectedRoute>} />
                    <Route path='userandAccount'>
                        <Route path='' element={<Navigate to={`${dataStorage.env}/userandAccount/new_user`} />} />
                        <Route path='new_user' element={<ProtectedRoute><NewUser /></ProtectedRoute>} />
                        <Route path='user_management' element={<ProtectedRoute><UserManagement /></ProtectedRoute>} />
                        {/* <Route path='drafts' element={<ProtectedRoute><Drafts /></ProtectedRoute>} />
                        <Route path='pending_application' element={<ProtectedRoute><PendingApplication /></ProtectedRoute>} />
                        <Route path='completed_application' element={<ProtectedRoute><DoneApplication /></ProtectedRoute>} />
                        <Route path='reject_application' element={<ProtectedRoute><RejectApplication /></ProtectedRoute>} /> */}
                    </Route>
                    <Route path='onboarding'>
                        <Route path='' element={<Navigate to={`${dataStorage.env}/onboarding/new_application`} />} />
                        <Route path='new_application' element={<ProtectedRoute><NewApplication /></ProtectedRoute>} />
                        <Route path='drafts' element={<ProtectedRoute><Drafts /></ProtectedRoute>} />
                        <Route path='pending_application' element={<ProtectedRoute><PendingApplication /></ProtectedRoute>} />
                        <Route path='completed_application' element={<ProtectedRoute><DoneApplication /></ProtectedRoute>} />
                        <Route path='reject_application' element={<ProtectedRoute><RejectApplication /></ProtectedRoute>} />
                    </Route>
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        )
    }

    return (
        <StyledEngineProvider injectFirst>
            <GlobalStyles />
            <ThemeProvider theme={theme}>
                <div id='topMark' />
                <Alert />
                {renderRoutes()}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;