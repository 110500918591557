import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Paper } from "@mui/material";
import Kebab from "src/components/Kebab";
import { getData, deleteData, getOpeningAccountUrl } from "src/api/api";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "src/components/ConfirmModal";
import CustomTable, { TABLE_ACTION } from "src/components/CustomTable";
import ViewApplicationModal from "src/components/Onboarding/ViewApplicationModal";
import dataStorage from "src/dataStorage";
import { getAccountStatus } from "src/utils/functionUtils";
import { ACCOUNT_TYPE } from "src/constants";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    minHeight: 0,
    overflow: "hidden",
    position: "relative",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
}));

const FIELD = {
  DRAFT_ID: "draft_id",
  DRAFT_NAME: "name",
  ACCOUNT_TYPE: "account_type",
  LAST_UPDATE: "updated",
  ACTION: "action",
  ACCOUNT_STATUS: "account_status",
};

const defaultPaginate = {
  pageSize: 10,
  currentPage: 1,
  totalCount: 0,
  totalPages: 0,
};

const DEFAULT_VALUE = "--";

const Drafts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const paginate = React.useRef(defaultPaginate);
  const searchText = React.useRef("");
  const confirmModalRef = React.useRef();
  const modalViewApplicationRef = React.useRef();
  const timeout = React.useRef(0);
  const COLUMNS = [
    {
      name: FIELD.DRAFT_ID,
      label: "Draft Id",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.DRAFT_NAME,
      label: "Draft Name",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: FIELD.ACCOUNT_TYPE,
      label: "Account Type",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        filterType: "dropdown",
        filterOptions: {
          names: [
            ACCOUNT_TYPE.INDIVIDUAL,
            ACCOUNT_TYPE.JOINT,
            ACCOUNT_TYPE.COMPANY,
            ACCOUNT_TYPE.TRUST,
          ],
        },
      },
    },
    {
      name: FIELD.LAST_UPDATE,
      label: "Last Update",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value
            ? moment(value).format("DD/MM/YYYY HH:mm:ss")
            : DEFAULT_VALUE;
        },
      },
    },
    {
      name: FIELD.ACTION,
      label: " ",
      options: {
        filter: false,
        sort: false,
        draggable: false,
        searchable: false,
        setCellProps: () => ({
          style: { width: 30, position: "sticky", right: 0, padding: 0 },
        }),
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return <Kebab items={defaultActions(rowIndex)} />;
        },
      },
    },
  ];

  const defaultActions = React.useCallback(
    (rowIndex) => [
      {
        label: "Continue",
        onClick: () => {
          onRowClick(rowIndex);
        },
      },
      {
        label: "Delete",
        onClick: () => {
          const draftId = rows[rowIndex][FIELD.DRAFT_ID];
          confirmModalRef?.current?.showModal?.(() => onDelete(draftId));
        },
      },
    ],
    [dispatch, rows]
  );

  const onDelete = async (draftId) => {
    const url = getOpeningAccountUrl(`/draft?draft_id=${draftId}`);
    const [, error] = await deleteData(url);
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
    } else {
      setRows(rows.slice(0).filter((e) => e[FIELD.DRAFT_ID] !== draftId));
      dataStorage.showAlert?.({
        type: "success",
        message: "Delete draft successfully",
      });
    }
  };

  const updatePaginate = (obj) => {
    paginate.current = {
      pageSize: obj.page_size,
      currentPage: obj.current_page,
      totalCount: obj.total_count,
      totalPages: obj.total_pages,
    };
  };

  const getDraft = async ({ pageId, pageSize }) => {
    setLoading(true);
    const [response, error] = await getData(
      getOpeningAccountUrl(
        `/draft?status=not_submitted&page_size=${pageSize}&page_id=${pageId}`
      )
    );
    setLoading(false);
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
      return;
    }
    const { data, ...rest } = response;
    updatePaginate({ ...rest, page_size: pageSize });
    const listData = data
      ? Object?.keys(data)?.map((k) => ({
          [FIELD.DRAFT_ID]: k,
          [FIELD.LAST_UPDATE]: data[k]?.data?.updated,
          [FIELD.ACCOUNT_TYPE]: data[k]?.data?.formData?.account_type,
          ...data[k],
        }))
      : [];
    setRows(listData);
  };

  React.useEffect(() => {
    getDraft({
      pageId: paginate.current.currentPage,
      pageSize: paginate.current.pageSize,
      search: searchText.current,
    });
  }, []);

  const onTableChange = (action, tableState) => {
    if (
      ["propsUpdate", "onFilterDialogOpen", "onFilterDialogClose"].includes(
        action
      )
    )
      return;
    timeout.current && clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      switch (action) {
        case TABLE_ACTION.SORT:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.FILTER_CHANGE:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.PAGE_CHANGE:
          getDraft({
            pageId: tableState.page + 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.PAGE_SIZE_CHANGE:
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: tableState.rowsPerPage,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.SEARCH:
          searchText.current = tableState.searchText;
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        case TABLE_ACTION.SEARCH_CLOSE:
          if (!searchText.current) return;
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
          });
          break;
        default:
          break;
      }
    }, 500);
  };

  const onRowClick = async (index) => {
    const rowData = rows[index];
    dataStorage.dicDraft = rowData;
    dataStorage.accountType =
      dataStorage.dicDraft?.data?.formData?.account_type;
    if (dataStorage.dicDraft?.data?.index) {
      dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0;
      dataStorage.isSubApplicant = true;
    }
    dataStorage.registerEmail =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_email;
    dataStorage.applicantId =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_id;
    const equixId = dataStorage.dicDraft?.data?.formData?.equix_id;
    dataStorage.equixId = equixId;
    if (equixId && !dataStorage.isSubApplicant) await getAccountStatus(equixId);
    else dataStorage.accountStatus = null;
    if (dataStorage.accountType.value)
      dataStorage.accountType = dataStorage.accountType.value;
    // navigate(`/${dataStorage.env}/onboarding/new_application`)
    modalViewApplicationRef?.current?.showModal?.();
  };

  const renderTable = () => {
    return (
      <CustomTable
        title="Drafts"
        loading={loading}
        rows={rows}
        onRowClick={onRowClick}
        columns={COLUMNS}
        rowsPerPageOptions={[10, 25, 100]}
        rowCount={paginate.current.totalCount}
        pageSize={paginate.current.pageSize}
        pageId={paginate.current.currentPage}
        onTableChange={onTableChange}
      />
    );
  };

  return (
    <Paper className={classes.paperContainer}>
      {renderTable()}
      <ConfirmModal ref={confirmModalRef} />
      <ViewApplicationModal ref={modalViewApplicationRef} />
    </Paper>
  );
};

export default Drafts;
