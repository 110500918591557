import React from "react";
import { styled } from "@mui/styles";
import dataStorage from "src/dataStorage";

const Logo = styled((props) => {
  const { style, src, ...other } = props;
  return (
    <img
      {...other}
      className="logoLight"
      style={style}
      src={src || dataStorage.config?.logoLight}
      alt="Logo"
    />
  );
})``;

export default Logo;
