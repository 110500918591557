/* eslint-disable no-debugger */
import React from "react";
import { IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import FormUserDetail from "../component/FormUserDetail";
import FormUserEdit from "../component/FormUserEdit";
import { Paper, Grid, Typography, Button, Stack } from "@mui/material";
import { Formik, Form } from "formik";
import {
  getResetPasswordUrl,
  postData,
  putData,
  getForceChangePassUrl,
} from "src/api/api";
import moment from "moment";
import { getUserGroupUrl, getEmailTempUrl, getData } from "src/api/api";
import useFetch from "src/components/Hooks/useFetch";
import ConfirmModal from "src/components/ConfirmModal";
import dataStorage from "src/dataStorage";
import {
  USER_STATUS_OPTIONS,
  ACCESS_METHOD_OPTIONS,
  USER_GROUP_OPTIONS,
  API_ACCESS_EDIT_OPTIONS,
} from "src/constants";
import FocusError from "src/components/FocusError";
import { diff } from "src/utils/functionUtils";
import { API_ACCESS } from "../component/FormUserEdit";
import formValidation from "../FormModel/formValidation";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: theme.spacing(3),
    borderRadius: 10,
    // minHeight: '400px',
    padding: theme.spacing(0, 2, 2, 2),
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 800,
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(52 41 41 / 50%)",
      borderRadius: 10,
      // outline: '1px solid slategrey'
    },
    "@media screen and (max-width: 1700px)": {
      height: "92vh",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  buttons: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 8,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 56,
    alignItems: "center",
    position: "fixed",
    width: "calc(86vw - 56px)",
    background: theme.palette.background.paper,
    zIndex: 9,
    borderRadius: 10,
  },
  styleButtonClose: {
    background: "#B75D69",
    "&:hover": {
      background: "#9D4358",
    },
  },
}));
const ResetPassApi = (userInfo) => async () => {
  const query = {
    data: { type: "forgot_password", user_login_id: userInfo.user_login_id },
  };
  const [, error] = await postData(getResetPasswordUrl(), query);
  if (error) {
    dataStorage.showAlert?.({ type: "error", message: error });
  } else {
    dataStorage.showAlert?.({
      type: "success",
      message: "Reset password successfully",
    });
  }
};
const Activities = (props) => {
  const classes = useStyles();
  const [step, setstep] = React.useState(0);
  const { data: dataUser } = useFetch({ url: getUserGroupUrl() });
  const { data: dataEmail } = useFetch({ url: getEmailTempUrl() });
  const listDataUsers = React.useCallback(
    () =>
      dataUser?.reduce(
        (preV, curV) => ({ ...preV, [curV?.group_id || ""]: curV?.group_name }),
        {}
      ),
    [dataUser?.length]
  );
  const listDataEmail = React.useCallback(
    () =>
      dataEmail?.reduce(
        (preV, curV) => ({ ...preV, [curV?.id || ""]: curV?.name }),
        {}
      ),
    [dataEmail?.length]
  );
  const [refetch, setRefetch] = React.useState(false);
  const formatDataEmail = React.useCallback(() => {
    return dataEmail
      .map((e) => {
        try {
          const { id, name } = e;
          return { value: id, label: name };
        } catch (error) {
          return e;
        }
      })
      .filter((e) => e.value != "DEFAULT");
  }, [dataEmail?.length]);
  const formatdataRoleGroup = React.useCallback(() => {
    return dataUser
      .map((e) => {
        try {
          const { group_id, group_name } = e;
          const transformLabel = group_name.split(/(\s|-)/gm);
          const textTransformed = transformLabel
            .map((e) => e[0].toUpperCase() + e.slice(1).toLowerCase())
            .filter((e) => e.length !== 1)
            .join(" ");
          return { value: group_id, label: textTransformed };
        } catch (error) {
          return e;
        }
      })
      .filter((e) => e.value != "DEFAULT");
  }, [dataUser?.length]);
  const [userInfo, setUserInfo] = React.useState({});
  const confirmModalRef = React.useRef();

  React.useEffect(() => {
    if (props?.infoAccount?.user_id) {
      getData(getForceChangePassUrl(props?.infoAccount?.user_id))
        .then((res) => {
          res?.[0] && setUserInfo(res?.[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [refetch]);

  const initialStateForm = () => {
    try {
      let initialValues = { ...userInfo };
      initialValues.updated = initialValues?.updated
        ? moment(initialValues?.updated || "").format("h:mm:ss DD/MM/YYYY")
        : "";
      initialValues.create_time = initialValues?.create_time
        ? moment(+initialValues?.create_time || "").format("h:mm:ss DD/MM/YYYY")
        : "";
      initialValues.access_method = initialValues.access_method + "";
      // initialValues.user_group = initialValues.user_group + '';
      initialValues.email_template = formatDataEmail().find(
        (e) => e.value == initialValues.email_template
      );
      initialValues.access_method = ACCESS_METHOD_OPTIONS.find(
        (e) => e.value == initialValues.access_method
      );
      initialValues.user_type = API_ACCESS_EDIT_OPTIONS.find(
        (e) => e.value == initialValues.user_type
      );
      initialValues.user_group = USER_GROUP_OPTIONS.find(
        (e) => e.value == initialValues.user_group
      );
      initialValues.status = USER_STATUS_OPTIONS.find(
        (e) => e.value == initialValues.status
      );
      !initialValues.list_mapping && (initialValues.list_mapping = []);
      !initialValues.phone && (initialValues.phone = "");
      if (!initialValues.organisation_code) {
        initialValues.organization_management = [];
      } else {
        initialValues.organization_management =
          initialValues.organisation_code.split(",");
      }
      if (!initialValues.branch_code) {
        initialValues.branch_management = [];
      } else {
        initialValues.branch_management = initialValues.branch_code.split(",");
      }
      if (!initialValues.advisor_code) {
        initialValues.manage_advisor = [];
      } else {
        initialValues.manage_advisor = initialValues.advisor_code?.split(",");
      }
      return { ...initialValues };
    } catch (err) {
      return { ...userInfo };
    }
  };
  const renderHeader = () => {
    return (
      <div className={classes.headerContainer}>
        <IconButton
          onClick={props.handleModalToggle}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="top" component="h1" variant="h6" align="center">
          USER DETAIL
        </Typography>
      </div>
    );
  };

  const onResetPass = () => {
    confirmModalRef?.current?.showModal?.(ResetPassApi(userInfo), {
      title: "Confirm",
      content: `Do you want to reset password for user login ${
        userInfo?.user_login_id || ""
      }?`,
    });
  };
  const omSubmitForm = (value, FormikHelpers) => {
    let ValuesDiff = diff(value, initialStateForm());
    FormikHelpers.setSubmitting(true);
    try {
      for (const key in ValuesDiff) {
        if (Array.isArray(ValuesDiff[key])) continue;
        if (typeof ValuesDiff[key] == "object") {
          //format value user type
          if (
            [API_ACCESS.retail, API_ACCESS.advisor].includes(
              value?.user_type?.value
            )
          ) {
            if (key == "list_mapping") {
              ValuesDiff[key] = Object.values(ValuesDiff[key])?.map((e) => {
                const {
                  account_id,
                  account_name,
                  advisor_code,
                  branch_code,
                  organisation_code,
                } = e;
                return {
                  account_id,
                  account_name,
                  advisor_code,
                  branch_code,
                  organisation_code,
                };
              });
            }
          }
          if (value.user_type.value == API_ACCESS.advisor) {
            if (
              ["branch_management", "organization_management"].includes(key)
            ) {
              ValuesDiff[key] = Object.values(ValuesDiff[key])
                .map((e) => e.organisation_code)
                .join(",");
            }
            if (key == "manage_advisor") {
              ValuesDiff[key] = Object.values(ValuesDiff[key])
                .map((e) => e.advisor_code)
                .join(",");
            }
          }
          ValuesDiff[key]?.value && (ValuesDiff[key] = ValuesDiff[key]?.value);
        }
      }
      // remove value redundant
      if (value.user_type.value == API_ACCESS.retail) {
        delete ValuesDiff.branch_management;
        delete ValuesDiff.manage_advisor;
        delete ValuesDiff.organization_management;
      }
      if (value.user_type.value == API_ACCESS.operation) {
        delete ValuesDiff.branch_management;
        delete ValuesDiff.manage_advisor;
        delete ValuesDiff.organization_management;
        delete ValuesDiff.list_mapping;
      }
      delete ValuesDiff.password;
      delete ValuesDiff.user_login_id;
      putData(getForceChangePassUrl(value.user_id), { data: ValuesDiff })
        .then((res) => {
          if (res?.[1]) {
            dataStorage.showAlert?.({ message: res[1], type: "error" });
          } else {
            dataStorage.showAlert?.({
              message: `Create user successfully`,
              type: "success",
            });
            // actions.resetForm({ ...initialState })
            setstep(0);
            setRefetch((e) => !e);
          }
          FormikHelpers.setSubmitting(false);
        })
        .catch((err) => {
          console.log(err);
          FormikHelpers.setSubmitting(false);
        });
    } catch (error) {
      console.log(error);
      FormikHelpers.setSubmitting(false);
    }
  };
  const renderPage = () => {
    switch (step) {
      case 0:
        return (
          <FormUserDetail
            infoAccount={userInfo}
            listDataUsers={listDataUsers()}
            listDataEmail={listDataEmail()}
          />
        );
      case 1:
        return (
          <FormUserEdit
            listDataUsers={listDataUsers()}
            listDataEmail={listDataEmail()}
            formatdataRoleGroup={formatdataRoleGroup()}
            formatDataEmail={formatDataEmail()}
          />
        );
      default:
        return "not Found...";
    }
  };
  const backDetail = (resetForm) => () => {
    setstep(0);
    resetForm({ ...initialStateForm() });
  };
  const buttonActions = (submitForm, values, isSubmitting, resetForm) => {
    // let valueForm = values;
    // !valueForm.note && (valueForm.note = null);
    // valueForm.role_group?.value && (valueForm.role_group = valueForm.role_group?.value)
    // const checkBlank = Object.keys(diff(values, initialStateForm()) || {});
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button variant="contained" color="secondary" onClick={onResetPass}>
            Reset Password
          </Button>
          <Stack direction={"row"} spacing={2}>
            {step ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={backDetail(resetForm)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => submitForm()}
                >
                  {isSubmitting ? (
                    <CircularProgress size="25px" />
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setstep(1)}
              >
                Edit
              </Button>
            )}
          </Stack>
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <>
      <Paper className={classes.paper} elevation={15}>
        {renderHeader()}
        <div style={{ padding: 42 }}></div>
        <Formik
          initialValues={initialStateForm()}
          validationSchema={formValidation}
          onSubmit={omSubmitForm}
          validateOnBlur={true}
          validateOnChange={false}
          enableReinitialize
        >
          {({ submitForm, values, isSubmitting, resetForm }) => (
            <FocusError>
              <Form style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  {renderPage()}
                  {buttonActions(submitForm, values, isSubmitting, resetForm)}
                  {/* <TableActivities infoAccount={props.infoAccount} /> */}
                </Grid>
              </Form>
            </FocusError>
          )}
        </Formik>
        <ConfirmModal ref={confirmModalRef} />
      </Paper>
    </>
  );
};

export default Activities;
