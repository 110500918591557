import { } from 'src/constants'

const fromModels = {
  formId: 'CreateNewForm',
  formField: {
    full_name: {
      name: 'full_name',
      label: 'Full Name'
    },
    user_login_id: {
      name: 'user_login_id',
      label: 'User Login'
    },
    phone: {
      name: 'phone',
      label: 'Phong Number'
    },
    password: {
      name: 'password',
      label: 'Password'
    },
    send_password: {
      name: 'send_password',
      label: 'Send password to email'
    },
    change_password: {
      name: 'change_password',
      label: 'Require password change at next sign in'
    },
    role_group: {
      name: 'role_group',
      label: 'Role Group'
    },
    role: {
      name: 'role_group',
      label: 'Role'
    },
    email_template: {
      name: 'email_template',
      label: 'Email Template'
    },
    status: {
      name: 'status',
      label: 'Status'
    },
    note: {
      name: 'note',
      label: 'Note'
    },
    access_permission: {
      name: 'access_permission',
      label: 'Access Permission'
    },
    access_method: {
      name: 'access_method',
      label: 'Access Method'
    },
    api_access: {
      name: 'api_access',
      label: 'Api Access'
    },
    user_group: {
      name: 'user_group',
      label: 'User Group'
    },
    organization: {
      name: 'organization',
      label: 'Organization'
    },
    organization_management: {
      name: 'organization_management',
      label: 'Search Organization'
    },
    list_mapping: {
      name: 'list_mapping',
      label: 'Search Account'
    },
    branch_management: {
      name: 'branch_management',
      label: 'Search Branch'
    },
    manage_advisor: {
      name: 'manage_advisor',
      label: 'Search Advisor'
    },
    last_active: {
      name: 'updated',
      label: 'Last Active'
    },
    whitelable: {
      name: 'bl_environment',
      label: 'Whitelable'
    },
    create_time: {
      name: 'create_time',
      label: 'Created Time'
    },
    account_id: {
      name: 'list_mapping',
      label: 'Account ID'
    },
    description: {
      name: 'note',
      label: 'Description'
    }
  }
}
export default fromModels