import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Kebab from "src/components/Kebab";
import Activities from "../components/UserandAccount/UserManagement/Form/ModalActivities";
import UserDetail from "../components/UserandAccount/UserManagement/Form/ModalUserDetail";
import {
  getResetPasswordUrl,
  putData,
  getForceChangePassUrl,
} from "src/api/api";
import {
  getSearchUserUrl,
  postData,
  getUserGroupUrl,
  getEmailTempUrl,
} from "src/api/api";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "src/components/ConfirmModal";
import { Paper, IconButton } from "@mui/material";
import { Done } from "@mui/icons-material";
import CustomGridTable, {
  TABLE_ACTION,
  TYPE,
} from "src/components/CustomGridTable";
import dataStorage from "src/dataStorage";
import {
  getFilterObj,
  getAccountStatus,
  formatData,
} from "src/utils/functionUtils";
import {
  USER_TYPE_ACCOUNT,
  USER_GROUP_EMUN,
  LIST_STATUS_CHECK,
  API_ACCESS_EDIT_OPTIONS,
  USER_GROUP_OPTIONS,
  ACCESS_METHOD_OPTIONS,
  USER_STATUS_OPTIONS,
} from "src/constants";
import useFetch from "src/components/Hooks/useFetch";
import { COLUMN_TYPE } from "src/constants";
import Button from "@mui/material/Button";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    minHeight: 0,
    overflow: "hidden",
    position: "relative",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
}));

export const FIELD = {
  USER_ID: "user_id",
  USER_LOGIN_ID: "user_login_id",
  FULL_NAME: "full_name",
  USER_TYPE: "user_type",
  ROLE_GROUP: "role_group",
  USER_GROUP: "user_group",
  ACCESS_METHOD: "access_method",
  STATUS: "status",
  EMAIL_TEMPLATE: "email_template",
  LIVE_NEWS: "live_news",
  MORNINGSTAR: "addon",
  TIPRANK: "tipRank",
  BROKERDATA: "brokerData",
  CONTINGENTORDER: "contingentOrder",
  EMAIL: "email",
  PHONE: "phone",
  LIST_MAPPING: "list_mapping",
  NOTE: "note",
  ACTOR: "actor",
  UPDATED: "updated",
  WHITE_LABEL: "bl_environment",
  LAST_TIME: "last_time",
  CREATE_TIME: "create_time",
  ACTION: "action",
};

const LIST_FILTER = [...Object.values(FIELD)];

const defaultPaginate = {
  pageSize: 10,
  currentPage: 1,
  totalCount: 0,
  totalPages: 0,
};

const DEFAULT_VALUE = "--";

const renderCellByType = (value, tableMeta) => {
  const { columnData } = tableMeta;
  switch (columnData.type) {
    case COLUMN_TYPE.NOTIFICATION_ACTION:
      return (
        <a href={value?.[0]?.url} target="_blank" rel="noreferrer">
          {value?.[0]?.title}
        </a>
      );
    case COLUMN_TYPE.CHECKBOX:
      return value ? (
        <IconButton>
          <Done />
        </IconButton>
      ) : (
        <></>
      );
    case COLUMN_TYPE.LINK:
      return (
        <a href={value} target="_blank" rel="noreferrer">
          {value}
        </a>
      );
    // case COLUMN_TYPE.CHIP:
    //   return checkTypeNoti(value, notifications[rowIndex])
    case COLUMN_TYPE.DATE:
      return value
        ? moment(value).format("DD/MM/YYYY HH:mm:ss")
        : DEFAULT_VALUE;
    default:
      return DEFAULT_VALUE;
  }
};
const UserManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const paginate = React.useRef(defaultPaginate);
  const searchText = React.useRef("");
  const confirmModalRef = React.useRef();
  const dataChanges = React.useRef();
  const timeout = React.useRef(0);
  const modelActivities = React.useRef(null);
  const modelUserDetail = React.useRef(null);
  const { data: dataUser } = useFetch({ url: getUserGroupUrl() });
  const { data: dataEmail } = useFetch({ url: getEmailTempUrl() });
  const listDataUsers = React.useCallback(
    () =>
      dataUser?.reduce(
        (preV, curV) => ({ ...preV, [curV?.group_id || ""]: curV?.group_name }),
        {}
      ),
    [dataUser?.length]
  );
  const listDataEmail = React.useCallback(
    () =>
      dataEmail?.reduce(
        (preV, curV) => ({ ...preV, [curV?.id || ""]: curV?.name }),
        {}
      ),
    [dataEmail?.length]
  );
  const [editModel, setEditModel] = React.useState(false);
  const formatdataRoleGroup = React.useCallback(() => {
    return dataUser
      .map((e) => {
        try {
          const { group_id, group_name } = e;
          const transformLabel = group_name.split(/(\s|-)/gm);
          const textTransformed = transformLabel
            .map((e) => e[0].toUpperCase() + e.slice(1).toLowerCase())
            .filter((e) => e.length !== 1)
            .join(" ");
          return { value: group_id, label: textTransformed };
        } catch (error) {
          return e;
        }
      })
      .filter((e) => e.value != "DEFAULT");
  }, [dataUser?.length]);
  const formatDataEmail = React.useCallback(() => {
    return dataEmail
      .map((e) => {
        try {
          const { id, name } = e;
          return { value: id, label: name };
        } catch (error) {
          return e;
        }
      })
      .filter((e) => e.value != "DEFAULT");
  }, [dataEmail?.length]);
  const renderColumn = () => {
    let COLUMNS = [
      {
        name: FIELD.USER_ID,
        label: "User Id",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.USER_LOGIN_ID,
        label: "User Login Id",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.FULL_NAME,
        label: "Full Name",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.USER_TYPE,
        label: "Api Access",
        dataOptions: API_ACCESS_EDIT_OPTIONS,
        type: TYPE.DROPDOWN,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            const styles = {
              bgColor: {
                operation: "#CCC4E0",
                advisor: "#6ccdff",
                retail: "rgba(197, 203, 206, 0.54)",
              },
            };
            return styles.bgColor?.[value] ? (
              <Chip
                style={{
                  backgroundColor: styles.bgColor?.[value],
                  textTransform: "uppercase",
                }}
                label={value || "--"}
              />
            ) : (
              "--"
            );
          },
        },
      },
      {
        name: FIELD.ROLE_GROUP,
        label: "Role",
        dataOptions: formatdataRoleGroup(),
        type: TYPE.DROPDOWN,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return listDataUsers?.()?.[value] || "--";
          },
        },
      },
      {
        name: FIELD.USER_GROUP,
        label: "User Group",
        dataOptions: USER_GROUP_OPTIONS,
        type: TYPE.DROPDOWN,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return USER_GROUP_EMUN[value] || "--";
          },
        },
      },
      {
        name: FIELD.ACCESS_METHOD,
        label: "Access Method",
        dataOptions: ACCESS_METHOD_OPTIONS,
        type: TYPE.DROPDOWN,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            const styles = {
              bgColor: {
                0: { color: "#FCEDBE", lable: "Internal Only" },
                1: { color: "#FCEDBE", lable: "" },
              },
            };
            return styles.bgColor?.[value] ? (
              <Chip
                style={{
                  backgroundColor: styles.bgColor?.[value].color,
                  textTransform: "uppercase",
                }}
                label={styles.bgColor?.[value].lable || "--"}
              />
            ) : (
              "--"
            );
          },
        },
      },
      {
        name: FIELD.STATUS,
        label: "Status",
        dataOptions: USER_STATUS_OPTIONS,
        type: TYPE.DROPDOWN,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return (
              (
                <Chip
                  style={{
                    backgroundColor: LIST_STATUS_CHECK[value]?.color,
                    textTransform: "uppercase",
                  }}
                  label={LIST_STATUS_CHECK[value]?.label || "--"}
                />
              ) || "--"
            );
          },
        },
      },
      {
        name: FIELD.EMAIL_TEMPLATE,
        label: "Email Template",
        dataOptions: formatDataEmail(),
        type: TYPE.DROPDOWN,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return (
              (
                <Chip
                  style={{
                    backgroundColor: "rgba(197, 203, 206, 0.54)",
                    textTransform: "capitalize",
                  }}
                  label={listDataEmail?.()?.[value] || "--" || "--"}
                />
              ) || "--"
            );
          },
        },
      },
      {
        name: FIELD.LIVE_NEWS,
        label: "Live News",
        type: TYPE.CHECKBOX,
        options: {
          filter: false,
          sort: true,
          type: COLUMN_TYPE.CHECKBOX,
          sortThirdClickReset: true,
          customBodyRender: (value, tableMeta) => {
            return renderCellByType(value, tableMeta);
          },
        },
      },
      {
        name: FIELD.MORNINGSTAR,
        label: "Morning Start",
        type: TYPE.CHECKBOX,
        options: {
          filter: false,
          sort: true,
          type: COLUMN_TYPE.CHECKBOX,
          sortThirdClickReset: true,
          customBodyRender: (value, tableMeta) => {
            const valueCheckBox = value == "A1" ? 1 : 0;
            return renderCellByType(valueCheckBox, tableMeta);
          },
        },
      },
      {
        name: FIELD.TIPRANK,
        label: "Tiprank",
        type: TYPE.CHECKBOX,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          type: COLUMN_TYPE.CHECKBOX,
          customBodyRender: (value, tableMeta) => {
            return renderCellByType(value, tableMeta);
          },
        },
      },
      {
        name: FIELD.BROKERDATA,
        label: "Broker Data",
        type: TYPE.CHECKBOX,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          type: COLUMN_TYPE.CHECKBOX,
          customBodyRender: (value, tableMeta) => {
            return renderCellByType(value, tableMeta);
          },
        },
      },
      dataStorage.env_config?.roles?.contingentOrder
        ? {
            name: FIELD.CONTINGENTORDER,
            label: "Contingentorder",
            options: {
              filter: false,
              sort: true,
              sortThirdClickReset: true,
            },
          }
        : null,
      {
        name: FIELD.EMAIL,
        label: "Email",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.PHONE,
        label: "Phone Number",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.LIST_MAPPING,
        label: "Management",
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            let branch = value?.bl_branch_code
              ? value?.bl_branch_code + ","
              : "";
            let org = value?.bl_organisation_code
              ? value?.bl_organisation_code + ","
              : "";
            let adv = value?.bl_advisor_code
              ? value?.bl_advisor_code + ","
              : "";
            let lst = value?.bl_list_mapping ? value?.bl_list_mapping : "";
            return branch + org + adv + lst;
          },
        },
      },
      {
        name: FIELD.NOTE,
        label: "Note",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.ACTOR,
        label: "Actor",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: FIELD.WHITE_LABEL,
        label: "White Label",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return value && value.toUpperCase();
          },
        },
      },
      {
        name: FIELD.LAST_TIME,
        label: "Last Time",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return value && moment(value).format("HH:mm:ss DD/MMM/YYYY");
          },
        },
      },
      {
        name: FIELD.CREATE_TIME,
        label: "Create Time",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return value && moment(value).format("HH:mm:ss DD/MMM/YYYY");
          },
        },
      },
      {
        name: FIELD.UPDATED,
        label: "Updated",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return value && moment(value).format("HH:mm:ss DD/MMM/YYYY");
          },
        },
      },
      {
        name: FIELD.ACTION,
        label: " ",
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => ({
            style: { width: 30, position: "sticky", right: 0, padding: 0 },
          }),
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            return <Kebab items={defaultActions(rowIndex)} />;
          },
        },
      },
    ];
    return COLUMNS.filter((e) => e != null);
  };

  const onResetPass = async (equixId, accountType, rowIndex) => {
    const query = {
      data: {
        type: "forgot_password",
        user_login_id: rows[rowIndex].user_login_id,
      },
    };
    const [, error] = await postData(getResetPasswordUrl(), query);
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
    } else {
      dataStorage.showAlert?.({
        type: "success",
        message: "Reset password successfully",
      });
    }
  };
  const onForceChangePass = async (equixId, accountType, rowIndex) => {
    const query = { data: { change_password: rows[rowIndex].change_password } };
    const [, error] = await putData(
      getForceChangePassUrl(rows[rowIndex]?.user_id),
      query
    );
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
    } else {
      dataStorage.showAlert?.({
        type: "success",
        message: "Force change password successfully",
      });
    }
  };

  const defaultActions = React.useCallback(
    (rowIndex) => {
      const accountType = rows[rowIndex][FIELD.ACCOUNT_TYPE];
      const renderAction = [];
      // if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
      //   renderAction.push({
      //     label: 'Open Activities',
      //     onClick: (event) => {
      //       const equixId = rows[rowIndex][FIELD.APPLICATION_ID]
      //       modelActivities?.current?.showModal?.(rows[rowIndex])
      //     }
      //   })
      // }
      if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
        renderAction.push({
          label: "User Detail",
          onClick: () => {
            modelUserDetail?.current?.showModal?.(rows[rowIndex]);
          },
        });
      }
      // if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
      //   renderAction.push({
      //     label: 'Activities',
      //     onClick: (event) => {
      //       modelActivities?.current?.showModal?.(rows[rowIndex])
      //     }
      //   })
      // }
      if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
        renderAction.push({
          label: "Reset Password",
          onClick: () => {
            const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
            confirmModalRef?.current?.showModal?.(
              () => onResetPass(equixId, accountType, rowIndex),
              {
                title: "Confirm",
                content: `Do you want to reset password for user login ${rows[rowIndex].user_login_id}?`,
              }
            );
          },
        });
      }
      if (dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation) {
        renderAction.push({
          label: "Force to Change Password",
          onClick: () => {
            const equixId = rows[rowIndex][FIELD.APPLICATION_ID];
            console.log(rows[rowIndex]);
            confirmModalRef?.current?.showModal?.(
              () => onForceChangePass(equixId, accountType, rowIndex),
              {
                title: "Confirm",
                content: `Do you want to cancel changing password request for user login ${rows[rowIndex].user_login_id}?`,
              }
            );
          },
        });
      }

      return renderAction;
    },
    [dispatch, rows]
  );

  const updatePaginate = (obj) => {
    paginate.current = {
      pageSize: obj.page_size,
      currentPage: obj.current_page,
      totalCount: obj.total_count,
      totalPages: obj.total_pages,
    };
  };

  const getDraft = async ({ pageId, pageSize, search, ...restDraft }) => {
    setLoading(true);
    let query = { query: { bool: { must: [] } }, sort: [] };
    if (search)
      query.query.bool.must.push({ query_string: { query: `*${search}*` } });
    if (restDraft?.filter) {
      const coverFilter = restDraft?.filter.map((e) => {
        return [{ wildcard: { [e.name]: { wildcard: `*${e.value}*` } } }];
      });
      query.query.bool.must.push(...coverFilter);
    }
    if (restDraft?.sort) {
      const { sortField, sortType } = restDraft?.sort || {};
      const querySort = { [sortField]: { order: sortType.toLowerCase() } };
      query.sort = [querySort];
    }
    const [response, error] = await postData(
      getSearchUserUrl(pageId, pageSize),
      query
    );
    setLoading(false);
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
      return;
    }
    const { data, ...rest } = response;
    updatePaginate({ ...rest, page_size: pageSize });
    const listData = data
      ? Object?.keys(data)?.map((k) => ({ ...data[k] }))
      : [];
    setRows(listData);
  };

  React.useEffect(() => {
    getDraft({
      pageId: paginate.current.currentPage,
      pageSize: paginate.current.pageSize,
      search: searchText.current,
    });
  }, []);

  const onTableChange = (action, tableState) => {
    if (
      ["propsUpdate", "onFilterDialogOpen", "onFilterDialogClose"].includes(
        action
      )
    )
      return;
    timeout.current && clearTimeout(timeout.current);
    const filterItemRedundant = LIST_FILTER.filter((e) =>
      tableState.columns.find((item) => item.name == e)
    );
    timeout.current = window.setTimeout(() => {
      const filterObj = getFilterObj(tableState, filterItemRedundant);
      switch (action) {
        case TABLE_ACTION.SORT:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.FILTER_CHANGE:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.PAGE_CHANGE:
          getDraft({
            pageId: tableState.page + 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.PAGE_SIZE_CHANGE:
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: tableState.rowsPerPage,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.SEARCH:
          searchText.current = tableState.searchText;
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.SEARCH_CLOSE:
          if (!searchText.current) return;
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        default:
          break;
      }
    }, 500);
  };

  const onRowClick = async (index) => {
    const rowData = rows[index];
    dataStorage.dicDraft = rowData;
    dataStorage.accountType =
      dataStorage.dicDraft?.data?.formData?.account_type;
    if (dataStorage.dicDraft?.data?.index) {
      dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0;
      dataStorage.isSubApplicant = true;
    }
    dataStorage.registerEmail =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_email;
    dataStorage.applicantId =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_id;
    const equixId = dataStorage.dicDraft?.data?.formData?.equix_id;
    dataStorage.equixId = equixId;
    if (equixId && !dataStorage.isSubApplicant) await getAccountStatus(equixId);
    else dataStorage.accountStatus = null;
    if (dataStorage.accountType?.value)
      dataStorage.accountType = dataStorage.accountType?.value;
    // navigate(`/${dataStorage.env}/onboarding/new_application`)
    // modalViewApplicationRef?.current?.showModal?.()
  };
  const editUser = (newObj, IdAccount = "") => {
    let data = { data: {} };
    data.data.user_type = newObj.user_type;
    data.data.status = newObj.status;
    data.data.role_group = newObj.role_group;
    data.data.access_method = newObj.access_method;
    data.data.email_template = newObj.email_template;
    data.data.email = newObj.email || null;
    data.data.user_group = newObj.user_group;
    data.data.live_news = newObj.live_news ? 1 : 0;
    const addOns = [];
    if (newObj.tipRank) {
      addOns.push("A0");
    }
    if (newObj.addon) {
      addOns.push("A1");
    }
    if (newObj.brokerData) {
      addOns.push("A2");
    }
    if (newObj.contingentOrder) {
      addOns.push("A3");
    }
    data.data.addon = addOns.join(",");
    const url = getForceChangePassUrl(`${IdAccount}`);
    return putData(url, data)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const submitForm = () => async () => {
    const listDataField = formatData(dataChanges.current.dataFields);
    for (const key in listDataField) {
      await editUser(listDataField[key], key);
    }
  };

  const renderTable = () => {
    return (
      <CustomGridTable
        title={{
          title: "User Management",
          actionTitle: (
            <>
              {!editModel ? (
                <Button
                  onClick={() => {
                    setEditModel((e) => !e);
                  }}
                  size="small"
                  variant="outlined"
                >
                  <EditIcon />
                </Button>
              ) : null}
              {editModel ? (
                <>
                  <div style={{ padding: 3 }}></div>
                  <Button
                    onClick={submitForm()}
                    variant="outlined"
                    size="small"
                  >
                    <SaveAsIcon />
                  </Button>
                  <div style={{ padding: 3 }}></div>
                  {editModel ? (
                    <Button
                      onClick={() => {
                        setEditModel((e) => !e);
                      }}
                      size="small"
                      variant="outlined"
                      color="error"
                    >
                      <CloseIcon />
                    </Button>
                  ) : null}
                </>
              ) : null}
            </>
          ),
        }}
        loading={loading}
        rows={rows}
        allField={FIELD}
        editModel={editModel}
        onRowClick={onRowClick}
        columns={renderColumn()}
        rowsPerPageOptions={[10, 25, 100]}
        rowCount={paginate.current.totalCount}
        pageSize={paginate.current.pageSize}
        pageId={paginate.current.currentPage}
        onTableChange={onTableChange}
        ref={dataChanges}
      />
    );
  };

  return (
    <Paper className={classes.paperContainer}>
      <Activities ref={modelActivities} />
      <UserDetail ref={modelUserDetail} />
      {renderTable()}
      <ConfirmModal ref={confirmModalRef} />
      {/* <UserManagementComponent ref={modalViewApplicationRef} /> */}
    </Paper>
  );
};

export default UserManagement;
