import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function SelectFieldDataGrid({ ...props }) {
  const [valueField, setvalueField] = React.useState(
    props?.value == 1 || props?.value == "A1"
  );
  // console.log(props?.value)
  const onChangeField = () => {
    const valueCheckbox = !valueField;
    setvalueField(valueCheckbox);
    props?.handleChange && props.handleChange(valueCheckbox);
  };
  return (
    <Checkbox
      defaultChecked={valueField}
      checked={valueField}
      onChange={onChangeField}
    />
  );
}
