import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import fromModels from "../../NewUser/FormModels/FormModel";
import { USER_GROUP_OPTIONS, LIST_STATUS_CHECK } from "src/constants";
import { Chip } from "@mui/material";
import moment from "moment";

const ACCESS_METHOD_ENUM = {
  0: "Internal Only",
  1: "",
};
export const API_ACCESS = {
  retail: "retail",
  advisor: "advisor",
  operation: "operation",
};
const FormUserDetail = ({ infoAccount, listDataEmail, listDataUsers }) => {
  const listMapping = () => {
    const data = { ...infoAccount };
    let manageAdvisor = [];
    if (infoAccount?.user_type === API_ACCESS.retail) {
      if (data.list_mapping) {
        const listACC = data.list_mapping;
        listACC &&
          Array.isArray(listACC) &&
          listACC.length > 0 &&
          listACC.map((e) => {
            if (e) {
              manageAdvisor.push({
                account_id: e.account_id,
                account_name: e.account_name,
              });
            }
          });
      }
    }
    if (infoAccount?.user_type === API_ACCESS.advisor) {
      if (data.organisation_code) {
        const listOC = data.organisation_code.split(",");
        listOC &&
          Array.isArray(listOC) &&
          listOC.length > 0 &&
          listOC.map((e) => {
            if (e) manageAdvisor.push({ OC: e });
          });
      }
      if (data.branch_code) {
        const listBC = data.branch_code.split(",");
        listBC &&
          Array.isArray(listBC) &&
          listBC.length > 0 &&
          listBC.map((e) => {
            if (e) {
              const temp = e.split(".");
              manageAdvisor.push({ OC: temp[0], BC: temp[1] });
            }
          });
      }
      if (data.advisor_code) {
        const listAC = data.advisor_code.split(",");
        listAC &&
          Array.isArray(listAC) &&
          listAC.length > 0 &&
          listAC.map((e) => {
            if (e) {
              const temp = e.split(".");
              manageAdvisor.push({ OC: temp[0], BC: temp[1], AC: temp[2] });
            }
          });
      }
      if (data.list_mapping) {
        const listACC = data.list_mapping;
        listACC &&
          Array.isArray(listACC) &&
          listACC.length > 0 &&
          listACC.map((e) => {
            if (e) {
              manageAdvisor.push({
                account_id: e.account_id,
                account_name: e.account_name,
              });
            }
          });
      }
      // if (data.organisation_code || data.branch_code || data.advisor_code || data.list_mapping) {
      //   data.manage_advisor = manageAdvisor;
      //   // this.manage_advisor = manageAdvisor;
      // }
    }
    // this.list_mapping = data.list_mapping || [];
    data.list_mapping = manageAdvisor;
    return data;
  };
  const renderManagement = () => {
    const renderListMapping = listMapping().list_mapping;
    if (infoAccount?.user_type == API_ACCESS.retail) {
      return (
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={fromModels.formField.account_id.label}
          // value={name}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <React.Fragment>
                <div>
                  {renderListMapping
                    ? renderListMapping.map((e, i) => {
                        return (
                          <Chip
                            key={i}
                            tabIndex={i}
                            style={{ margin: 5 }}
                            label={
                              e.account_name && e.account_id
                                ? `${e.account_name} ${
                                    e?.account_id ? ` (${e?.account_id})` : ""
                                  }`
                                : `${e.OC}${e.BC ? ` (${e.BC})` : ""}${
                                    e.AC ? ` (${e.AC})` : ""
                                  }`
                            }
                          />
                        );
                      })
                    : null}
                </div>
              </React.Fragment>
            ),
          }}
          fullWidth
        />
      );
    }
    if (infoAccount?.user_type === API_ACCESS.advisor) {
      return (
        <TextField
          label={"Manage"}
          variant="standard"
          InputProps={{
            readOnly: true,
            startAdornment: (
              <React.Fragment>
                <div>
                  {renderListMapping
                    ? renderListMapping.map((e, i) => {
                        return (
                          <Chip
                            key={i}
                            tabIndex={i}
                            style={{ margin: 5 }}
                            label={
                              e.account_name && e.account_id
                                ? `${e.account_name} ${
                                    e?.account_id ? ` (${e?.account_id})` : ""
                                  }`
                                : `${e.OC}${e.BC ? ` (${e.BC})` : ""}${
                                    e.AC ? ` (${e.AC})` : ""
                                  }`
                            }
                          />
                        );
                      })
                    : null}
                </div>
              </React.Fragment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      );
    }
    if (infoAccount?.user_type === API_ACCESS.operation) {
      return (
        <TextField
          inputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          label={"Manage"}
          value={"Operators have access all Operator API by default"}
          fullWidth
        />
      );
    }
  };
  const userInfo = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>
              User Infomation
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.full_name.label}
              name={fromModels.formField.full_name.name}
              value={infoAccount?.full_name || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.user_login_id.label}
              value={infoAccount?.user_login_id || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.phone.label}
              value={infoAccount?.phone || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.email_template.label}
              value={listDataEmail?.[infoAccount?.email_template || ""] || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.access_method.label}
              value={ACCESS_METHOD_ENUM[infoAccount?.access_method]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.api_access.label}
              value={API_ACCESS[infoAccount?.user_type]?.toUpperCase()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.user_group.label}
              value={USER_GROUP_OPTIONS[infoAccount?.user_group]?.label || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.status.label}
              value={LIST_STATUS_CHECK[infoAccount?.status]?.label || ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.role.label}
              value={listDataUsers?.[infoAccount.role_group]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.last_active.label}
              value={
                infoAccount?.updated
                  ? moment(infoAccount?.updated || "").format(
                      "h:mm:ss DD/MM/YYYY"
                    )
                  : ""
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.whitelable.label}
              value={(infoAccount?.bl_environment || "").toUpperCase()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.create_time.label}
              value={moment(infoAccount?.create_time || "").format(
                "h:mm:ss DD/MM/YYYY"
              )}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const Management = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>Management</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            {renderManagement()}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const Notes = () => {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography style={{ fontWeight: "bold" }}>Notes</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              variant="standard"
              label={fromModels.formField.description.label}
              value={infoAccount?.note || ""}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {userInfo()}
      {Management()}
      {Notes()}
    </React.Fragment>
  );
};

export default FormUserDetail;
