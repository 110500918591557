import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import PasswordField from "src/components/CustomFields/passwordField";
import {
  postData,
  getSessionUrl,
  getAuthUrl,
  getDecodeUrl,
  getRefreshUrl,
  getData,
  getUserDetailUrl,
  getSSEUrl,
} from "src/api/api";
import { NOTIFICATION_TYPE } from "src/constants";
import ReactCodeInput from "react-code-input";
import CryptoJS from "react-native-crypto-js";
import dataStorage from "src/dataStorage";
import { useDispatch } from "react-redux";
import { setLoginState } from "src/redux/auth/auth";
import { LoginPage } from "@patternfly/react-core";
import { makeStyles } from "@mui/styles";
import {
  deleteNotification,
  addNotification,
  editStatusNotification,
  updateNotification,
} from "src/redux/notification/notification";
import logger from "src/utils/logger";

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    minHeight: 0,
    display: "flex",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      "& .pf-c-brand": {
        height: 96,
        maxWidth: "calc(100vw - 32px)",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .pf-c-brand": {
        height: 128,
        maxWidth: 600,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .pf-c-brand": {
        height: 128,
        maxWidth: 600,
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .pf-c-brand": {
        height: 128,
        maxWidth: 600,
      },
    },
    [theme.breakpoints.up("xl")]: {
      "& .pf-c-brand": {
        height: 200,
        maxWidth: 800,
      },
    },
  },
  loginContainer: {
    background: theme.palette.background.paper,
    padding: 24,
    borderRadius: 15,
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const pinRef = React.useRef(undefined);

  const refreshAccessToken = async () => {
    console.log(`RENEW TOKEN => SEND REFRESH TOKEN  - ${new Date().getTime()}`);
    const data = {
      refreshToken: dataStorage.tokenRefresh,
      deviceID: dataStorage.deviceId,
    };
    const [response, error] = await postData(getRefreshUrl(), { data });
    if (error) {
      logger.error(error, "refreshAccessToken error");
    }
    response.scope && (dataStorage.scope = response.scope);
    dataStorage.accessToken = response.accessToken;
    autoRefreshToken;
  };

  const autoRefreshToken = () => {
    const REFRESH_TIME = 2 * 60 * 1000;
    if (dataStorage.refreshTimeoutId)
      clearInterval(dataStorage.refreshTimeoutId);
    dataStorage.refreshTimeoutId = setInterval(
      refreshAccessToken,
      REFRESH_TIME
    );
  };

  const clearPin = () => {
    if (pinRef.current.textInput[0]) pinRef.current.textInput[0].focus();
    pinRef.current.state.input[0] = "";
    pinRef.current.state.input[1] = "";
    pinRef.current.state.input[2] = "";
    pinRef.current.state.input[3] = "";
    pinRef.current.state.input[4] = "";
    pinRef.current.state.input[5] = "";
  };
  const onChangePin = async (pin, values) => {
    try {
      if (pin.length === 6) {
        setLoading(true);
        const sessionId = +new Date();
        const [sessionResponse, sessionError] = await postData(
          getSessionUrl(sessionId)
        );
        if (sessionError) {
          setLoading(false);
          return dataStorage.showAlert?.({
            message: sessionError,
            type: "error",
          });
        }
        const session = sessionResponse.data;
        const sessionData = {
          token: dataStorage.refreshToken,
          pin: CryptoJS.AES.encrypt(pin, session.key).toString(),
          session_id: `${sessionId}`,
        };
        const [decodeResponse, decodeError] = await postData(getDecodeUrl(), {
          data: sessionData,
        });
        if (decodeError) {
          clearPin();
          setLoading(false);
          return dataStorage.showAlert?.({
            message: decodeError,
            type: "error",
          });
        }
        const { token } = decodeResponse;
        dataStorage.tokenRefresh = token;
        autoRefreshToken();
        const pinData = {
          refreshToken: token,
          deviceID: dataStorage.deviceId,
        };
        const [pinResponse, pinError] = await postData(getRefreshUrl(), {
          data: pinData,
        });
        if (pinError) {
          clearPin();
          setLoading(false);
          return dataStorage.showAlert?.({ message: pinError, type: "error" });
        }
        const { accessToken, baseUrl } = pinResponse;
        pinResponse.scope && (dataStorage.scope = pinResponse.scope);
        dataStorage.accessToken = accessToken;
        baseUrl && (dataStorage.baseUrl = `https://${baseUrl}/v1`);
        const [userInfo, errorUser] = await getData(
          getUserDetailUrl(values.email)
        );
        if (errorUser) {
          logger.error(errorUser, "Get user detail error");
        } else if (userInfo) dataStorage.userInfo = userInfo;
        callBackSSE();
        setLoading(false);
        dispatch(setLoginState(true));
        dataStorage.hideAlert?.();
        navigate(`/${dataStorage.env}`, { replace: true });
      }
    } catch (error) {
      setLoading(false);
      return dataStorage.showAlert?.({
        message: JSON.stringify(error),
        type: "error",
      });
    }
  };
  const callBackSSE = () => {
    const sourceSSE = new EventSource(getSSEUrl(dataStorage.accessToken));
    sourceSSE.onerror = (e) => {
      logger.log(e, "err");
    };
    sourceSSE.onopen = (e) => console.log(e);
    sourceSSE.onmessage = (e) => {
      const getData = JSON.parse(e.data);
      try {
        if (getData?.data?.title == "PUSH_ALERT#UPDATE_NOTIFICATION") {
          const getNoti = JSON.parse(JSON.parse(getData.data?.object_changed));
          dispatch(updateNotification(getNoti));
        } else if (
          getData?.data?.title == "PUSH_ALERT#ADD_NOTIFICATION" ||
          getData?.data?.title == "PUSH_ALERT#TRIGGERED"
        ) {
          try {
            const getNoti = JSON.parse(
              JSON.parse(getData.data?.object_changed)
            );
            if (getNoti) {
              if (getNoti.notification_type === NOTIFICATION_TYPE.DIRECT) {
                if (getNoti.status === "Triggered") {
                  return dispatch(editStatusNotification(getNoti));
                } else {
                  dispatch(addNotification(getNoti));
                }

                return;
              }
              if (getNoti.notification_type === NOTIFICATION_TYPE.SEGMENT) {
                dispatch(addNotification(getNoti));
                return;
              }
            }
          } catch (error) {
            console.log(error);
          }
        } else if (getData?.data?.title == "PUSH_ALERT#DELETE") {
          const getNoti = JSON.parse(JSON.parse(getData.data?.object_changed));
          dispatch(deleteNotification(getNoti.notification_id));
        }
      } catch (err) {
        console.log(err);
      }
    };
  };

  const onSignin = async (values) => {
    try {
      setLoading(true);
      const { email, password } = values;
      const sessionId = +new Date();
      const [sessionResponse, sessionError] = await postData(
        getSessionUrl(sessionId)
      );
      if (sessionError) {
        setLoading(false);
        return dataStorage.showAlert?.({
          message: sessionError,
          type: "error",
        });
      }
      const session = sessionResponse.data;
      const sessionData = {
        username: (email + "").trim().toLocaleLowerCase(),
        password: CryptoJS.AES.encrypt(password, session.key).toString(),
        provider: "paritech",
        storage_token: true,
        session_id: `${sessionId}`,
      };
      const [loginResponse, loginError] = await postData(getAuthUrl(), {
        data: sessionData,
      });
      if (loginError) {
        setLoading(false);
        return dataStorage.showAlert?.({ message: loginError, type: "error" });
      }
      const { refreshToken, deviceID, user_type: userType } = loginResponse;
      if (userType === "retail") {
        return dataStorage.showAlert?.({
          message: `You do not have permission to use broker's service`,
          type: "error",
        });
      }
      dataStorage.refreshToken = refreshToken;
      dataStorage.deviceId = deviceID;
      dataStorage.hideAlert?.();
      setLoading(false);
      setStep(1);
    } catch (error) {
      setLoading(false);
      return dataStorage.showAlert?.({
        message: JSON.stringify(error),
        type: "error",
      });
    }
  };

  const renderLogin = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm" className={classes.loginContainer}>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={onSignin}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {step === 0 ? (
                  <React.Fragment>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <PasswordField
                      label="Password"
                      name="password"
                      variant="outlined"
                    />
                    {/* <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      /> */}
                    <Box sx={{ py: 2, width: "100%" }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ textTransform: "capitalize" }}
                      >
                        Sign in now
                        {loading ? (
                          <CircularProgress
                            style={{ position: "absolute" }}
                            size={24}
                          />
                        ) : (
                          <></>
                        )}
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography color="textPrimary" variant="h5">
                      Enter Pin Code
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      {values.email}
                    </Typography>
                    <ReactCodeInput
                      type="password"
                      inputMode="numeric"
                      name="PIN"
                      className="ReactCodeInput"
                      fields={6}
                      ref={pinRef}
                      onChange={(pin) => onChangePin(pin, values, clearPin)}
                      inputStyle={{
                        fontFamily: "monospace",
                        margin: "4px",
                        MozAppearance: "textfield",
                        width: "48px",
                        borderRadius: "4px",
                        fontSize: "24px",
                        height: "56px",
                        paddingLeft: "16px",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid darkgrey",
                      }}
                    />
                  </React.Fragment>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    );
  };

  return (
    <LoginPage
      className={classes.loginWrapper}
      brandImgSrc={dataStorage.config?.logoDark}
      backgroundImgAlt="Images"
    >
      {renderLogin()}
    </LoginPage>
  );
};

export default Login;
