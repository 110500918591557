/* eslint-disable multiline-ternary */
import React, { useState } from "react";
import { Paper, Button, CircularProgress } from "@mui/material";
import { Formik, Form } from "formik";
import ApplicationDetails from "./Forms/ApplicationDetails";
import TrustDetails from "./Forms/TrustDetails";
import TrusteeType from "./Forms/TrusteeType";
import AccountDesignation from "./Forms/AccountDesignation";
import CompanyDetails from "./Forms/CompanyDetails";
import AdditionalInformation from "./Forms/AdditionalInformation";
import ReviewApplication from "./Forms/ReviewApplication";
import ApplicationResult from "./Forms/ApplicationResult";
import ApplicationDeleted from "./Forms/ApplicationDeleted";
import ApplicantButton from "src/components/ApplicantButton";
import Steps from "src/components/Steps";
import validationSchema from "./FormModels/formValidation";
import formModel from "./FormModels/formModels";
import initialValues from "./FormModels/formInitial";
import logger from "src/utils/logger";
import useStyles from "../styles";
import dataStorage from "src/dataStorage";
import {
  saveDraft,
  clone,
  getMobilePhoneValue,
  getAddressData,
  mapDataAddress,
  mapManualAddress,
  checkSubmitted,
  getEnv,
  isBroker,
  isMorrison,
  scrollToTop,
  clearTrashFieldAndMapData,
  renderFullname,
  formatDate,
} from "src/utils/functionUtils";
import { postData, getOpeningAccountUrl } from "src/api/api";
import {
  ACCOUNT_TYPE,
  BANK_ACCOUNT_TYPE,
  CMT_PROVIDER,
  TRANSACTION_TYPE,
  GOVERNMENT_ID_TYPE,
  CMA,
  OCCUPATION_TYPE,
  SETTLEMENT_METHOD,
  MEDICARE_CARD_COLOUR,
  DEFAULT_APPLICANT,
  TAX_STATUS,
  SOURCE_OF_WEALTH,
} from "src/constants";
import { v4 as uuidv4 } from "uuid";
import AdminActions from "src/components/AdminActions";
import AccountStatus from "src/components/AccountStatus";
import FocusError from "src/components/FocusError";

const { formId, formField } = formModel;

const MIN_APPLICANT = 1;
const MAX_APPLICANT = 3;

const STEP_TRUST_INDIVIDUAL = [
  "Trust Details",
  "Account Designation",
  "Additional Information",
  "Review",
];
const STEP_TRUST_COMPANY = [
  "Company Details",
  "Trust Details",
  "Account Designation",
  "Additional Information",
  "Review",
];

export default function Trust(props) {
  const { data = {} } = dataStorage.dicDraft;
  let { formData = {}, step = 0, isSubmitted = false } = data;
  const getSteps = (values) => {
    const { applicant_details: applicantDetails } = values;
    const listStep = ["Trustee Type"];
    if (applicantDetails) {
      applicantDetails.forEach((e, i) => {
        const name =
          e.first_name && e.last_name
            ? renderFullname(applicantDetails[i])
            : "";
        listStep.push(name || `Applicant ${i + 1}`);
      });
    } else {
      listStep.push("Applicant 1");
    }
    if (values.trustee_type?.value === ACCOUNT_TYPE.COMPANY) {
      listStep.push(...STEP_TRUST_COMPANY);
    } else {
      listStep.push(...STEP_TRUST_INDIVIDUAL);
    }
    return listStep;
  };
  const [steps, setSteps] = React.useState(() => {
    const initialSteps = getSteps(formData);
    return initialSteps;
  });

  if (isSubmitted) step = steps.length - 1;
  if (formData.equixId)
    dataStorage.equixId = formData.equix_id || formData.equixId;
  const classes = useStyles();
  const refNext = React.useRef(null);
  const listStepCanPress = React.useRef({
    0: true,
    1: step > 0,
    2: step > 1,
    3: step > 2,
  });
  const [activeStep, setActiveStep] = useState(step);
  const isLastStep = activeStep === steps.length - 1;
  const initialData = React.useRef({ ...clone(initialValues), ...formData });
  const trusteeType = React.useRef(
    initialData.trustee_type?.value || ACCOUNT_TYPE.INDIVIDUAL
  );
  if (!dataStorage.equixId && dataStorage.registerEmail) {
    initialData.current.applicant_details[0].applicant_email =
      dataStorage.registerEmail;
  }

  function _renderStepContent(values) {
    const screen = steps[activeStep];
    switch (screen) {
      case "Trustee Type":
        return <TrusteeType />;
      case "Trust Details":
        return <TrustDetails />;
      case "Account Designation":
        return <AccountDesignation />;
      case "Company Details":
        return <CompanyDetails />;
      case "Additional Information":
        return <AdditionalInformation />;
      case "Review":
        return (
          <ReviewApplication steps={getSteps(values)} onEdit={onStepClick} />
        );
      default:
        return <ApplicationDetails index={activeStep - 1} />;
    }
  }

  async function _submitForm(values, actions) {
    const obj = clone(values);
    clearTrashFieldAndMapData(obj, "", values, formField, ["role_in_trust"]);

    if (
      obj.tax_status !== TAX_STATUS.OTHER ||
      (obj.tax_status === TAX_STATUS.FINANCIAL_INSTITUTION &&
        !obj.is_fi_non_participarting_crs_managed_by_other_fi)
    ) {
      delete obj.is_controlling_person_foreign_tax_resident;
    }

    if (
      obj.tax_status === TAX_STATUS.FINANCIAL_INSTITUTION &&
      !obj.is_fi_non_participarting_crs_managed_by_other_fi
    ) {
      delete obj.tax_resident_of_australia;
      delete obj.country_of_tax_residency;
      delete obj.taxpayer_identification_number;
      delete obj.taxpayer_identification_number_exemption_reason;
    }

    obj.tos_ip = window.ipPublic;
    obj.tos_user_agent = navigator.userAgent;
    if (obj.company_date_of_incorporation)
      obj.company_date_of_incorporation = formatDate(
        obj.company_date_of_incorporation
      );
    if (obj.company_country_of_incorporation)
      obj.company_country_of_incorporation = "AUSTRALIA";
    obj.company_mobile_phone &&
      (obj.company_mobile_phone = getMobilePhoneValue(
        obj.company_mobile_phone,
        "04"
      ));
    obj.company_work_phone &&
      (obj.company_work_phone = getMobilePhoneValue(obj.company_work_phone));
    obj.trade_confirmations.length = obj.applicant_details.length;
    const listAddressId = obj.applicant_details.reduce((acc, cur) => {
      if (
        !cur.residential_address_manual_address &&
        cur.residential_address_full_address?.id
      ) {
        acc.push(cur.residential_address_full_address?.id);
      }
      if (
        !cur.business_owner_trading_address_manual_address &&
        cur.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER &&
        cur.business_owner_trading_address_full_address?.id
      ) {
        acc.push(cur.business_owner_trading_address_full_address?.id);
      }
      return acc;
    }, []);

    if (
      !obj.trust_address_manual_address &&
      obj.trust_address_full_address?.id
    ) {
      listAddressId.push(obj.trust_address_full_address?.id);
    }
    if (
      !obj.mailing_address_manual_address &&
      obj.applicant === "OTHER" &&
      obj.mailing_address_full_address?.id
    ) {
      listAddressId.push(obj.mailing_address_full_address?.id);
    }
    if (
      !obj.company_registered_office_address_manual_address &&
      obj.company_registered_office_address_full_address?.id
    ) {
      listAddressId.push(
        obj.company_registered_office_address_full_address?.id
      );
    }
    if (
      !obj.company_principal_place_of_business_address_manual_address &&
      obj.company_principal_place_of_business_address_full_address?.id &&
      !obj.company_same_as_roa
    ) {
      listAddressId.push(
        obj.company_principal_place_of_business_address_full_address?.id
      );
    }

    if (listAddressId.length) {
      await getAddressData(listAddressId);
    }
    obj.applicant_details.forEach((e, i) => {
      if (!e.applicant_id) e.applicant_id = uuidv4();
      // e.applicant_id = uuidv4()
      // trade_confirmations
      e.applicant_email = e.applicant_email?.trim();
      e.subclass_visa =
        e.source_of_wealth?.value !== SOURCE_OF_WEALTH.FOREIGN_INCOME
          ? false
          : !!e.subclass_visa;
      if (!obj.trade_confirmations[i]) obj.trade_confirmations[i] = {};
      obj.trade_confirmations[i].method = "EMAIL";
      obj.trade_confirmations[i].email = e?.applicant_email;
      obj.trade_confirmations[i].client_address = isBroker()
        ? true
        : e.client_address || false;

      // handle tax
      if (!e.australian_tax_resident) e.tax_exemption = false;

      if (obj.trustee_type === ACCOUNT_TYPE.COMPANY) {
        e.role_in_trust = Object.values(e.role_in_trust || {})
          .filter((e) => e)
          .map((e) => e.value || e)
          .join(", ");
      } else {
        delete e.role_in_trust;
      }

      dataStorage.applicantId = e.applicant_id;
      if (e.government_id.type === GOVERNMENT_ID_TYPE.MEDICARE_CARD) {
        e.government_id.medicare_name_on_card = e.middle_name
          ? e.first_name + " " + e.middle_name + " " + e.last_name
          : e.first_name + " " + e.last_name;
        const expireDate = e.government_id.medicare_card_expiry_date;
        if (
          e.government_id.medicare_card_colour === MEDICARE_CARD_COLOUR.GREEN
        ) {
          e.government_id.medicare_card_expiry_date = formatDate(
            expireDate,
            "MM/YYYY"
          );
        } else {
          e.government_id.medicare_card_expiry_date = formatDate(
            expireDate,
            "DD/MM/YY"
          );
        }
      } else {
        e.government_id.first_name = e.first_name;
        e.government_id.last_name = e.last_name;
        e.government_id.middle_name = e.middle_name;
      }
      e.government_id = [e.government_id];

      // handle address
      if (e.residential_address_manual_address) {
        mapManualAddress(e, e, "residential_address");
        mapManualAddress(e, e, "postal_address", "residential_address");
        if (obj.applicant === e.applicant_id) {
          mapManualAddress(obj, e, "mailing_address", "residential_address");
        }
      } else {
        const addressId = e.residential_address_full_address?.id;
        mapDataAddress(e, addressId, "residential_address");
        mapDataAddress(e, addressId, "postal_address");
        if (obj.applicant === e.applicant_id) {
          mapDataAddress(obj, addressId, "mailing_address");
        }
      }

      if (e.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
        if (e.business_owner_trading_address_manual_address) {
          mapManualAddress(e, e, "business_owner_trading_address");
        } else {
          mapDataAddress(
            e,
            e.business_owner_trading_address_full_address?.id,
            "business_owner_trading_address"
          );
        }
      }

      e.same_as_ra = true;
      e.relationship_type = "OWNER";
      e.residential_address_country = "AUSTRALIA";
      e.country_of_birth = "AUSTRALIA";
      e.applicant_mobile_phone &&
        (e.applicant_mobile_phone = getMobilePhoneValue(
          e.applicant_mobile_phone,
          "04"
        ));
      e.dob = formatDate(e.dob);

      delete e.client_address;
      delete e.total_confirm;
      delete e.morrison_confirm;
      delete e.terms_confirm;
      delete e.quant_edge_privacy_statement_confirm;
      delete e.macquarie_confirm;
      delete e.financial_services_guide_confirm;
      delete e.verification_id;
      delete e.ekyc_overall_status;
      delete e.residential_address_manual_address;
      delete e.business_owner_trading_address_manual_address;
    });

    if (obj.company_same_as_roa) {
      if (obj.company_registered_office_address_manual_address) {
        mapManualAddress(
          obj,
          obj,
          "company_principal_place_of_business_address",
          "company_registered_office_address"
        );
      } else {
        mapDataAddress(
          obj,
          obj.company_registered_office_address_full_address?.id,
          "company_principal_place_of_business_address"
        );
      }
    } else {
      if (obj.company_principal_place_of_business_address_manual_address) {
        mapManualAddress(
          obj,
          obj,
          "company_principal_place_of_business_address"
        );
      } else {
        mapDataAddress(
          obj,
          obj.company_principal_place_of_business_address_full_address?.id,
          "company_principal_place_of_business_address"
        );
      }
    }
    if (obj.company_registered_office_address_manual_address) {
      mapManualAddress(obj, obj, "company_registered_office_address");
    } else {
      mapDataAddress(
        obj,
        obj.company_registered_office_address_full_address?.id,
        "company_registered_office_address"
      );
    }

    if (obj.trust_address_manual_address) {
      mapManualAddress(obj, obj, "trust_address");
    } else {
      mapDataAddress(obj, obj.trust_address_full_address?.id, "trust_address");
    }

    if (obj.applicant === "OTHER") {
      if (obj.mailing_address_manual_address) {
        mapManualAddress(obj, obj, "mailing_address");
      } else {
        mapDataAddress(
          obj,
          obj.mailing_address_full_address?.id,
          "mailing_address"
        );
      }
    }

    delete obj.trust_address_manual_address;
    delete obj.company_registered_office_address_manual_address;
    delete obj.company_principal_place_of_business_address_manual_address;
    delete obj.mailing_address_manual_address;

    // new cma account and settlement_method
    obj.new_cma = !obj.use_existing_CMT_acc;
    obj.settlement_method = obj.settlement_method
      ? SETTLEMENT_METHOD.SPONSORED_NEW_HIN
      : SETTLEMENT_METHOD.SPONSORED_HIN_TRANSFER;
    obj.settlement_existing_hin &&
      (obj.settlement_existing_hin = +obj.settlement_existing_hin);
    if (obj.new_cma) {
      obj.new_cma = CMA.CREATE_NEW;
      obj.bank_account_type = BANK_ACCOUNT_TYPE.BANK_ACCOUNT;
      obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
    } else {
      obj.new_cma = CMA.USE_EXISTING;
      obj.bank_account_type = BANK_ACCOUNT_TYPE.LINKED_CMT_CMA;
      obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
    }
    obj.bank_transaction_type = TRANSACTION_TYPE.BOTH;

    // delete other fields
    delete obj.use_existing_CMT_acc;
    delete obj.equixId;
    delete obj.gin_asked;
    delete obj.tin_ask;

    // link draft id with submit object
    if (dataStorage.dicDraft.id || dataStorage.dicDraft.draft_id)
      obj.draft_id = dataStorage.dicDraft.id || dataStorage.dicDraft.draft_id;
    // debugger;//eslint-disable-line
    const url = getOpeningAccountUrl("/trust");
    delete obj.equix_id;
    delete obj.submit_time;

    obj.account_type = `${obj.account_type}_${obj.trustee_type}`;
    obj.type_of_trust = "Other";
    delete obj.trustee_type;
    delete obj.applicant;

    const [res, error] = await postData(url, obj);
    if (error) {
      logger.log(error, "submit opening account error");
      dataStorage.showAlert?.({ message: error, type: "error" });
      actions.setSubmitting(false);
    } else {
      window.onbeforeunload = null; // remove popup close tab
      if (res.equix_id) dataStorage.equixId = res.equix_id;
      dataStorage.applicantInfo = res || {
        ...dataStorage.applicantInfo,
        ...values,
      };
      actions.setSubmitting(false);
      let id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
      saveDraft({
        formData: {
          ...values,
          equix_id: dataStorage.equixId,
          submit_time: +new Date(),
          tos_ip: obj.tos_ip,
          tos_user_agent: obj.tos_user_agent,
        },
        isSubmitted: true,
        step: activeStep,
        id,
        index: 0,
      });
      setActiveStep(activeStep + 1);
    }
  }

  const onNext = (values, actions) => {
    scrollToTop();
    let id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
    listStepCanPress.current[activeStep + 1] = true;
    saveDraft({
      formData: {
        ...values,
        equix_id: dataStorage.equixId,
      },
      step: activeStep + 1,
      index: dataStorage.activeIndex,
      id,
    });
    actions.setTouched({});
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  };

  const onStepClick = (actions) => (index) => {
    if (index < activeStep) {
      if (listStepCanPress.current[index]) {
        setActiveStep(index);
      } else _handleBack();
    } else if (index > activeStep) {
      actions.validateForm().then((errors) => {
        if (errors && Object.keys(errors).length) {
          actions.setTouched(errors);
        } else {
          if (listStepCanPress.current[index]) {
            setActiveStep(index);
          } else {
            refNext.current && refNext.current.click();
          }
        }
      });
    }
  };

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      onNext(values, actions);
    }
  }

  function _handleBack() {
    if (activeStep === 0) {
      props.backChooseAccountType && props.backChooseAccountType();
    } else {
      scrollToTop();
      setActiveStep(activeStep - 1);
    }
  }

  const checkCanSubmit = (values) => {
    let check = true;
    const checkIndex = isBroker()
      ? 0
      : dataStorage.isSubApplicant
      ? dataStorage.activeIndex
      : 0;
    const element = values.applicant_details[checkIndex];
    const checkTerms = isMorrison()
      ? !element?.terms_confirm
      : !element?.terms_confirm || !element?.morrison_confirm;
    if (
      !element?.client_address ||
      !element?.total_confirm ||
      !element?.macquarie_confirm ||
      !element?.quant_edge_privacy_statement_confirm ||
      checkTerms
    ) {
      check = false;
    }
    return !isLastStep || check;
  };

  const renderButtons = (isSubmitting, values, actions) => {
    const accountType = dataStorage.accountType;
    if (!accountType) return <React.Fragment />;
    if (checkSubmitted()) {
      return (
        <div className={classes.buttons}>
          <div className={classes.leftButtons}></div>
          <div className={classes.rightButtons}>
            <AdminActions />
          </div>
        </div>
      );
    }
    const isAccepted = checkCanSubmit(values);
    return (
      <div className={classes.buttons}>
        {/* Back to Draft Button is on left handside */}
        <div className={classes.leftButtons}>
          {/* add applicants */}
          {renderApplicantButton(values, actions.setTouched)}
        </div>
        <div className={classes.rightButtons}>
          {accountType && (
            <div className={classes.wrapper}>
              <Button
                onClick={() => _handleBack(actions.setFieldValue)}
                className={classes.button}
              >
                Back
              </Button>
            </div>
          )}
          <div className={classes.wrapper}>
            <Button
              ref={refNext}
              disabled={isSubmitting || !isAccepted}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {isLastStep ? "Submit Application" : "next"}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const addApplicant = (values) => () => {
    values.applicant_details.push({
      ...DEFAULT_APPLICANT,
      applicant_id: uuidv4(),
    });
    listStepCanPress.current = {};
    for (let index = 0; index <= activeStep; index++) {
      listStepCanPress.current[index] = true;
    }
    const newSteps = getSteps(values);
    setSteps(newSteps);
  };

  const removeApplicant = (values, setTouched) => () => {
    values.applicant_details.splice(activeStep - 1, 1);
    listStepCanPress.current = Object.keys(listStepCanPress.current).reduce(
      (acc, cur) => {
        if (cur < activeStep) {
          acc[cur] = listStepCanPress.current[cur];
        } else if (cur > activeStep) {
          acc[cur - 1] = listStepCanPress.current[cur];
        }
        return acc;
      },
      {}
    );
    if (activeStep === 1) {
      if (!dataStorage.equixId && dataStorage.registerEmail) {
        values.applicant_details[0].applicant_email = dataStorage.registerEmail;
      }
    }
    setTouched({});
    const newSteps = getSteps(values);
    setSteps(newSteps);
  };

  const renderApplicantButton = (values, setTouched) => {
    const screen = steps[activeStep];
    const isNotAllowAddApplicant =
      [
        "Trustee Type",
        "Trust Details",
        "Account Designation",
        "Company Details",
        "Additional Information",
        "Review",
      ].includes(screen) || values.applicant_details.length >= MAX_APPLICANT;
    const isNotAllowRemoveApplicant =
      [
        "Trustee Type",
        "Trust Details",
        "Account Designation",
        "Company Details",
        "Additional Information",
        "Review",
      ].includes(screen) ||
      values.applicant_details.length <= MIN_APPLICANT ||
      activeStep === 1;
    return (
      <ApplicantButton
        isNotAllowAddApplicant={isNotAllowAddApplicant}
        isNotAllowRemoveApplicant={isNotAllowRemoveApplicant}
        addApplicant={addApplicant(values)}
        removeApplicant={removeApplicant(values, setTouched)}
      />
    );
  };

  const getValidationSchema = () => {
    const screen = steps[activeStep];
    switch (screen) {
      case "Review":
      case "Additional Information":
      case "Company Details":
      case "Trustee Type":
      case "Account Designation":
      case "Trust Details":
        return validationSchema(activeStep)[screen];
      default:
        return validationSchema(activeStep - 1)["Applicant Details"];
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={15}>
        {activeStep === -1 ? (
          <ApplicationDeleted />
        ) : activeStep === steps.length ? (
          <ApplicationResult />
        ) : (
          <React.Fragment>
            <AccountStatus accountStatus={dataStorage.accountStatus} />
            {/* navigation */}
            <Formik
              initialValues={initialData.current}
              validationSchema={getValidationSchema()}
              validateOnBlur={true}
              validateOnChange={false}
              onSubmit={_handleSubmit}
            >
              {({
                isSubmitting,
                setFieldValue,
                values,
                setTouched,
                submitForm,
                setSubmitting,
                validateForm,
              }) => {
                // console.log('YOLO errors: ', errors)
                if (trusteeType.current !== values.trustee_type?.value) {
                  trusteeType.current = values.trustee_type?.value;
                  const newSteps = getSteps(values);
                  setSteps(newSteps);
                }
                return (
                  <div className={classes.container}>
                    <Steps
                      steps={getSteps(values)}
                      activeStep={activeStep}
                      listStepCanPress={listStepCanPress.current}
                      onStepClick={onStepClick({ validateForm, setTouched })}
                    />
                    <div className={classes.formContainer}>
                      <FocusError>
                        <Form id={formId} autoComplete="off" noValidate>
                          {_renderStepContent(values)}
                          {renderButtons(isSubmitting, values, {
                            setFieldValue,
                            submitForm,
                            setTouched,
                            setSubmitting,
                            validateForm,
                          })}
                        </Form>
                      </FocusError>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
}
