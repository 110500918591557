/* eslint-disable no-debugger */
import React from "react";
import { Paper, Typography, IconButton, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import formModels from "./formModels";
import initialValues from "./initialValues";
import EditNotificationModal from "src/components/NewNotification/EditNotificationModal";
import moment from "moment";
import { deleteData, getDeleteNotificationUrl } from "src/api/api";
import FormViewNotification from "./ViewNotification";
import {
  NOTIFICATION_TYPE_OPTIONS,
  DELIVERY_TYPE_OPTIONS,
  ORDER_TYPE,
  Type,
  ORDER,
  TYPE_URL,
  EXPIRE_TIME_OPTIONS,
  STATUS_OPTIONS,
  STATUS,
} from "src/constants";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmModal from "../ConfirmModal";
import { deleteNotification } from "src/redux/notification/notification";
import dataStorage from "src/dataStorage";

const { formId } = formModels;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: theme.spacing(3),
    borderRadius: 10,
    // minHeight: '400px',
    padding: theme.spacing(0, 2, 2, 2),
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 800,
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(52 41 41 / 50%)",
      borderRadius: 10,
      // outline: '1px solid slategrey'
    },
    "@media screen and (max-width: 1700px)": {
      height: "92vh",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  buttons: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 8,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 56,
    alignItems: "center",
    position: "fixed",
    width: "calc(86vw - 56px)",
    background: theme.palette.background.paper,
    zIndex: 99,
    borderRadius: 10,
  },
}));
const ReviewNotification = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cleanModalRef = React.useRef();
  const editModalRef = React.useRef();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const [infoNotiEdit, setInfoNotiEdit] = React.useState({});
  const formatCode = () => {
    const initialForm = { ...props.infoNoti };
    const noti = props.infoNoti;
    // console.log(noti)
    initialForm.notification_type = NOTIFICATION_TYPE_OPTIONS.find(
      (e) => e.value === noti.notification_type
    )?.value;
    initialForm.audience = noti.audience_id;
    initialForm.delivery_type = DELIVERY_TYPE_OPTIONS.find(
      (e) => e.value == noti.delivery_type
    )?.value;
    initialForm.subsribers = [...noti.subscribers];
    const tranFormUrl = new URL(noti.url);
    const urlSearchParams = new URLSearchParams(tranFormUrl.search);
    const checkUrl = urlSearchParams.get("type");
    // const checkUrl = (tranFormUrl.pathname).replaceAll('/', '');
    switch (checkUrl) {
      case Type.new_order: {
        initialForm.symbol = urlSearchParams.get("symbol");
        const orderType = ORDER_TYPE.find(
          (e) => e.value === urlSearchParams.get("order-type")
        );
        initialForm.order_type = orderType?.value;
        initialForm.quantity = urlSearchParams.get("quantity");
        if (orderType?.value == ORDER.limit) {
          initialForm.price = urlSearchParams.get("price");
        }
        break;
      }
      case Type.order_detail: {
        initialForm.orderId = urlSearchParams.get("order-id");
        break;
      }
      case Type.article: {
        initialForm.symbol = urlSearchParams.get("symbol");
        initialForm.titleArticle = urlSearchParams.get("title");
        initialForm.urlArticle = urlSearchParams.get("url");
        break;
      }
      case Type.security_detail: {
        initialForm.symbol = urlSearchParams.get("symbol");
        break;
      }
      default: {
        initialForm.symbol = urlSearchParams.get("symbol");
        const orderType = ORDER_TYPE.find(
          (e) => e.value === urlSearchParams.get("order-type")
        );
        initialForm.order_type = orderType?.value;
        initialForm.quantity = urlSearchParams.get("quantity");
        if (orderType?.value == ORDER.limit) {
          initialForm.price = urlSearchParams.get("price");
        }
      }
    }

    initialForm.Type_Url = TYPE_URL.find((e) => e.value == checkUrl)?.value;
    initialForm.Type_Url_Label = TYPE_URL.find(
      (e) => e.value == checkUrl
    )?.label;
    const getType = noti.expire_time.slice(-1);
    const getExpire = noti.expire_time.slice(0, noti.expire_time.length - 1);
    initialForm.expireNumber = getExpire;
    initialForm.optionType = EXPIRE_TIME_OPTIONS.find(
      (e) => e.value === getType
    )?.label;
    initialForm.action1_title = noti.action1?.[0]?.title || "";
    initialForm.action1_url = noti.action1?.[0]?.url || "";
    initialForm.action2_title = noti.action2?.[0]?.title || "";
    initialForm.action2_url = noti.action2?.[0]?.url || "";
    initialForm.Segment = noti.segment_name || "";
    initialForm.large_image = noti.large_image;
    initialForm.icon = noti.icon;
    initialForm.last_updated = noti.last_updated
      ? moment(noti.last_updated).format("DD/MM/YYYY HH:mm:ss")
      : "";
    initialForm.schedule_time = noti.schedule_time
      ? moment(noti.schedule_time).format("DD/MM/YYYY HH:mm:ss")
      : "";
    initialForm.status = STATUS_OPTIONS.find(
      (e) => e?.value == noti?.status
    )?.value;
    return initialForm;
  };
  const deleteNoti = async () => {
    const idNoti = props?.infoNoti?.notification_id;
    if (!idNoti) return;

    const [, error] = await deleteData(getDeleteNotificationUrl(idNoti));
    if (error) {
      dataStorage.showAlert?.({ message: error, type: "error" });
    } else {
      dataStorage.showAlert?.({
        message: "Delete notification successfully",
        type: "success",
      });
      dispatch(deleteNotification(idNoti));
      props.handleModalToggle();
    }
  };
  const modalConfirm = () => {
    cleanModalRef?.current?.showModal?.(() => deleteNoti());
  };
  const openFormEdit = () => {
    const getIdnoti = props?.infoNoti?.notification_id;
    const findNoti = notifications.find((e) => e.notification_id == getIdnoti);
    setInfoNotiEdit(findNoti);
    editModalRef.current.showModal(0);
  };
  const renderButtons = () => {
    return (
      <div className={classes.buttons}>
        {props.infoNoti.status !== STATUS.Triggered ? (
          <>
            <Button variant="contained" color="primary" onClick={openFormEdit}>
              Edit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={modalConfirm}
              style={{ margin: "0 5px" }}
            >
              Delete
            </Button>
          </>
        ) : null}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => props.handleModalToggle()}
        >
          Close
        </Button>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className={classes.headerContainer}>
        <IconButton
          onClick={props.handleModalToggle}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="top" component="h1" variant="h6" align="center">
          PREVIEW NOTIFICATION
        </Typography>
      </div>
    );
  };
  return (
    <>
      <Paper className={classes.paper} elevation={15}>
        {renderHeader()}
        <Formik
          initialValues={formatCode() || initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          onSubmit={() => {
            // console.log('xin chao')
          }}
        >
          {({ isSubmitting, values, ...formik }) => (
            <Form id={formId} autoComplete="off" style={{ paddingTop: 62 }}>
              <FormViewNotification />
              {renderButtons(isSubmitting, values, formik)}
            </Form>
          )}
        </Formik>
      </Paper>
      <EditNotificationModal ref={editModalRef} infoNoti={infoNotiEdit} />
      <ConfirmModal
        title={" "}
        textButton={"Delete"}
        textCancel={"Close"}
        content={"Are you sure you want to continue?"}
        ref={cleanModalRef}
      />
    </>
  );
};

export default ReviewNotification;
