import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import dataStorage from "src/dataStorage";
import {
  ACCOUNT_STATUS,
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_TEXT,
  USER_TYPE_ACCOUNT,
} from "src/constants";
import { makeStyles } from "@mui/styles";
import {
  kycApplication,
  approveAplication,
  moveAplicationToDraft,
  deleteApplication,
  rejectApplication,
} from "src/utils/functionUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const listCanDelete = [
  ACCOUNT_STATUS.REJECTED,
  ACCOUNT_STATUS.IN_KYC,
  ACCOUNT_STATUS.PENDING_KYC_APPROVAL,
  ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT,
  ACCOUNT_STATUS.PENDING_BANK_ACCOUNT_INFO,
  ACCOUNT_STATUS.PENDING_BANK_TPA,
];

const listMoveToDraft = [
  ACCOUNT_STATUS.IN_KYC,
  ACCOUNT_STATUS.PENDING_KYC_APPROVAL,
  ACCOUNT_STATUS.PENDING_BANK_ACCOUNT_INFO,
  ACCOUNT_STATUS.PENDING_BANK_TPA,
];

const ACTION = {
  DELETE: "delete",
  MOVE_TO_DRAFT: "move_to_draft",
  APPROVE: "approve",
  KYC: "kyc",
  REJECT: "reject",
};

export default function AdminActions() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error] = React.useState("");
  const action = React.useRef("");
  const content = React.useRef("");
  const title = React.useRef("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const confirmDelete = () => {
    deleteApplication(dataStorage.equixId);
    handleClose();
  };
  const confirmReject = () => {
    rejectApplication(dataStorage.equixId);
    handleClose();
  };

  const confirmKYC = () => {
    kycApplication(
      dataStorage.equixId,
      ACCOUNT_TYPE_TEXT[dataStorage.accountType],
      () => {
        const root = document.getElementById("topModal");
        root &&
          root.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        handleClose();
      }
    );
  };

  const confirmApprove = () => {
    approveAplication(dataStorage.equixId, dataStorage.accountStatus);
    handleClose();
  };

  const confirmMoveToDraft = () => {
    moveAplicationToDraft(
      dataStorage.equixId,
      () => {},
      dataStorage.accountStatus
    );
    handleClose();
  };

  const onConfirm = async () => {
    switch (action.current) {
      case ACTION.DELETE:
        confirmDelete();
        break;
      case ACTION.REJECT:
        confirmReject();
        break;
      case ACTION.KYC:
        confirmKYC();
        break;
      case ACTION.APPROVE:
        confirmApprove();
        break;
      case ACTION.MOVE_TO_DRAFT:
        confirmMoveToDraft();
        break;
      default:
        break;
    }
  };

  const onKYC = () => {
    action.current = ACTION.KYC;
    title.current = "Confirm perform kyc on people";
    content.current = "Are you sure you want to perform kyc on people?";
    handleClickOpen();
  };

  const renderKYCBtn = () => {
    if (
      ![ACCOUNT_TYPE.COMPANY, ACCOUNT_TYPE.FUND, ACCOUNT_TYPE.TRUST].includes(
        dataStorage.accountType
      ) ||
      dataStorage.accountStatus !== ACCOUNT_STATUS.IN_KYC
    )
      return <React.Fragment />;
    return (
      <Button onClick={onKYC} variant="contained">
        Perform KYC on people
      </Button>
    );
  };

  const onMoveToDraft = () => {
    action.current = ACTION.MOVE_TO_DRAFT;
    title.current = "Confirm reject & move to draft";
    content.current =
      "Are you sure you want to reject the application & move to draft?";
    handleClickOpen();
  };

  const renderMoveToDraftBtn = () => {
    if (!listMoveToDraft.includes(dataStorage.accountStatus))
      return <React.Fragment />;
    return (
      <Button onClick={onMoveToDraft} variant="contained">
        Reject the Application & move to Draft
      </Button>
    );
  };

  const onApprove = () => {
    action.current = ACTION.APPROVE;
    title.current = "Confirm approve application";
    content.current = "Are you sure you want to approve this application?";
    handleClickOpen();
  };

  const renderApproveBtn = () => {
    if (
      ![
        ACCOUNT_STATUS.PENDING_KYC_APPROVAL,
        ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT,
      ].includes(dataStorage.accountStatus)
    )
      return <React.Fragment />;
    return (
      <Button onClick={onApprove} variant="contained">
        Approve
      </Button>
    );
  };

  const onDelete = () => {
    action.current = ACTION.DELETE;
    title.current = "Are you sure you want to reject the application?";
    content.current =
      "To delete application, please confirm your email address here.";
    handleClickOpen();
  };
  const onReject = () => {
    action.current = ACTION.REJECT;
    title.current = "Are you sure you want to close the application?";
    content.current =
      "To reject application, please confirm your email address here.";
    handleClickOpen();
  };

  const renderDeleteBtn = () => {
    if (
      dataStorage.accountType !== ACCOUNT_TYPE.JOINT ||
      !dataStorage.equixId ||
      dataStorage.isSubApplicant ||
      !listCanDelete.includes(dataStorage.accountStatus) ||
      dataStorage.userInfo?.user_type == USER_TYPE_ACCOUNT.Advisor
    ) {
      return <React.Fragment />;
    }
    return (
      <Button onClick={onDelete} variant="contained">
        Delete
      </Button>
    );
  };
  const renderRejectBtn = () => {
    if (
      dataStorage.userInfo?.user_type != USER_TYPE_ACCOUNT.Advisor &&
      dataStorage.dicDraft.account_status ===
        ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT
    ) {
      return (
        <Button onClick={onReject} variant="contained">
          Reject
        </Button>
      );
    }
    return <React.Fragment />;
  };

  if (dataStorage.accountStatus === ACCOUNT_STATUS.IN_DEVELOPMENT)
    return <React.Fragment />;

  return (
    <div className={classes.container}>
      {renderRejectBtn()}
      {renderDeleteBtn()}
      {dataStorage.userInfo.user_type == USER_TYPE_ACCOUNT.Operation ? (
        <>
          {renderKYCBtn()}
          {renderMoveToDraftBtn()}
          {renderApproveBtn()}
        </>
      ) : null}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title.current}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content.current}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingRight: 24 }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={!!error}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
