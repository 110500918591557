import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import {
  InputField,
  CheckboxField,
  AutocompleteFieldAsync,
  AutocompleteField,
  BooleanRadio,
  SelectField,
} from "src/components/CustomFields";
import {
  CMA_SOURCE_OF_FUNDS_OPTIONS,
  SETTLEMENT_METHOD_OPTIONS,
  CMA_ACCOUNT_PURPOSE_OPTIONS,
  ACCOUNT_TYPE,
  LIST_OF_PIDS,
  STREET_TYPE,
  STATE_OF_ISSUE_OPTIONS,
  BSB_OPTION,
} from "src/constants";
import ChessInfo from "src/components/ChessInfo";
import formModel from "../FormModels/formModels";
import {
  getEnv,
  getFullAddress,
  renderApplicants,
} from "src/utils/functionUtils";
import HelpText from "src/components/HelpText";
import HeadText from "src/components/HeadText";

const { formField } = formModel;

export default function AdditionalInformationForm() {
  const { values: formValues } = useFormikContext();

  const applicants = renderApplicants(formValues.applicant_details);

  const activeIndex = formValues.applicant_details.findIndex(
    (e) => e.applicant_id === formValues.applicant?.value
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HeadText>Account mailing address</HeadText>
      </Grid>
      <Grid item xs={12}>
        <HelpText>
          Official communication such as CHESS correspondence will go to this
          address. Choose an applicant from the dropdown below to choose an
          account mailing address. Or choose ‘Other’ to enter a different
          address
        </HelpText>
      </Grid>
      <Grid item xs={12}>
        <SelectField
          required
          name={formField.applicant.name}
          label={formField.applicant.label}
          data={applicants}
          fullWidth
        />
      </Grid>
      {formValues.applicant.value === "OTHER" ? (
        <React.Fragment>
          <Grid item xs={12} className="addressContainer">
            {formValues.mailing_address_manual_address ? (
              <React.Fragment />
            ) : (
              <AutocompleteFieldAsync
                required
                name={formField.mailing_address_full_address.name}
                label={formField.mailing_address_full_address.label}
                fullWidth
              />
            )}
            <CheckboxField
              name={formField.mailing_address_manual_address.name}
              label="Cannot find your address? Manual Input"
            />
          </Grid>
          {formValues.mailing_address_manual_address ? (
            <React.Fragment>
              <Grid item xs={6} sm={6}>
                <InputField
                  name={formField.mailing_address_unit_flat_number.name}
                  label={formField.mailing_address_unit_flat_number.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  required
                  name={formField.mailing_address_street_number.name}
                  label={formField.mailing_address_street_number.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  required
                  name={formField.mailing_address_street_name.name}
                  label={formField.mailing_address_street_name.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectField
                  required
                  name={formField.mailing_address_street_type.name}
                  label={formField.mailing_address_street_type.label}
                  data={STREET_TYPE}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  required
                  name={formField.mailing_address_city_suburb.name}
                  label={formField.mailing_address_city_suburb.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectField
                  required
                  name={formField.mailing_address_state.name}
                  label={formField.mailing_address_state.label}
                  data={STATE_OF_ISSUE_OPTIONS}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <InputField
                  required
                  name={formField.mailing_address_postcode.name}
                  label={formField.mailing_address_postcode.label}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}></Grid>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      ) : formValues.applicant.value ? (
        <React.Fragment>
          <Grid item xs={12}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="outlined"
              label={formField.mailing_address_full_address.label}
              value={getFullAddress(
                formValues.applicant_details[activeIndex],
                "residential_address"
              )}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <ChessInfo />
      <Grid item xs={12} sm={12}>
        <BooleanRadio
          required
          name={formField.settlement_method.name}
          label={formField.settlement_method.label}
          data={SETTLEMENT_METHOD_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>Cash Settlement</Typography>
      </Grid>
      {!formValues.use_existing_CMT_acc ? (
        <React.Fragment>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              inputProps={{ readOnly: true }}
              variant="outlined"
              label={"Settlement Bank Account"}
              value={"New Macquarie CMA Bank Account"}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      ) : null}
      {/* HIN transfer */}
      {formValues.settlement_method ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <Grid item xs={12} sm={12}>
            <InputField
              required
              name={formField.settlement_existing_hin.name}
              label={formField.settlement_existing_hin.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AutocompleteField
              required
              name={formField.settlement_pid.name}
              label={formField.settlement_pid.label}
              data={LIST_OF_PIDS}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      )}

      {/* new CMT account */}
      {!formValues.use_existing_CMT_acc ? (
        <React.Fragment>
          <Grid item xs={12} sm={12}>
            <SelectField
              required
              name={formField.cma_source_of_funds.name}
              label={formField.cma_source_of_funds.label}
              data={CMA_SOURCE_OF_FUNDS_OPTIONS[getEnv()]}
              fullWidth
            />
          </Grid>
          {formValues.cma_source_of_funds?.value === "OTHER" ? (
            <Grid item xs={12} sm={12}>
              <InputField
                required
                name={formField.cma_source_of_funds_desc.name}
                label={formField.cma_source_of_funds_desc.label}
                fullWidth
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12}>
            <SelectField
              required
              name={formField.cma_account_purpose.name}
              label={formField.cma_account_purpose.label}
              data={CMA_ACCOUNT_PURPOSE_OPTIONS[ACCOUNT_TYPE.COMPANY]}
              fullWidth
            />
          </Grid>
          {formValues.cma_account_purpose?.value === "OTHER" ? (
            <Grid item xs={12} sm={12}>
              <InputField
                required
                name={formField.cma_account_purpose_desc.name}
                label={formField.cma_account_purpose_desc.label}
                fullWidth
              />
            </Grid>
          ) : null}
        </React.Fragment>
      ) : null}

      {/* Link existing CMT account */}
      {!formValues.use_existing_CMT_acc ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <Grid item xs={12} sm={12}>
            <SelectField
              required
              name={formField.bank_bsb.name}
              label={formField.bank_bsb.label}
              data={BSB_OPTION}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField
              required
              name={formField.bank_account_number.name}
              label={formField.bank_account_number.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField
              required
              name={formField.bank_account_name.name}
              label={formField.bank_account_name.label}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}
