import Yup from "src/utils/customYup";
import formModels from "./formModels";
import { NOTIFICATION_TYPE, DELIVERY_TYPE, Type, ORDER } from "src/constants";

const validationSchema = Yup.object().shape({
  [formModels.formField.title.name]: Yup.string()
    .checkRequired("Title is required")
    .checkMaxLength(64),
  [formModels.formField.message.name]: Yup.string()
    .checkRequired("Message is required")
    .checkMaxLength(192),
  // [formModels.formField.url.name]: Yup.mixed().checkRequired("Url is required"),
  [formModels.formField.subsribers.name]: Yup.mixed().when(
    [formModels.formField.notification_type.name],
    (type, schema) => {
      return type.value === NOTIFICATION_TYPE.DIRECT
        ? schema.checkRequired("Email is required")
        : schema;
    }
  ),
  [formModels.formField.segment.name]: Yup.mixed().when(
    [formModels.formField.notification_type.name],
    (type, schema) => {
      return type.value === NOTIFICATION_TYPE.SEGMENT
        ? schema.checkRequired("Segment is required")
        : schema;
    }
  ),
  [formModels.formField.mobile_push.name]: Yup.mixed().checkTypeChannel(),
  [formModels.formField.desktop_push.name]: Yup.mixed().checkTypeChannel(),
  [formModels.formField.email_push.name]: Yup.mixed().checkTypeChannel(),
  [formModels.formField.sms_push.name]: Yup.mixed().checkTypeChannel(),
  [formModels.formField.symbol.name]: Yup.mixed().when(
    [formModels.formField.type_url.name],
    (value, schema) => {
      const checkType = [Type.new_order, Type.security_detail];
      return checkType.includes(value.value)
        ? schema.checkRequired("Symbol is required")
        : schema;
    }
  ),
  [formModels.formField.symbol_multiple.name]: Yup.mixed().when(
    [formModels.formField.type_url.name],
    (value, schema) => {
      const checkType = [Type.article];
      return checkType.includes(value.value)
        ? schema.checkRequired("Symbol is required")
        : schema;
    }
  ),
  [formModels.formField.quantity.name]: Yup.mixed().when(
    [formModels.formField.type_url.name],
    (value, schema) => {
      const checkType = [Type.new_order];
      return checkType.includes(value.value)
        ? schema.checkRequired("Quantity is required")
        : schema;
    }
  ),
  [formModels.formField.price.name]: Yup.mixed().test(
    "require",
    "",
    function (value, context) {
      const TypeUrl = context.parent?.Type_Url?.value;
      const TypeOrderType = context.parent?.order_type?.value;
      if (TypeUrl === Type.new_order && TypeOrderType === ORDER.limit) {
        const { path, createError } = this;
        if ([null, undefined, ""].includes(value)) {
          return createError({ path, message: "Price is required" });
        } else if ((value.match(/\./g) || []).length) {
          const findIndexString = value?.indexOf(".");
          const checkInvalid = value.slice(
            findIndexString + 1,
            value.length
          ).length;
          if (checkInvalid > 4 || checkInvalid <= 0)
            return createError({ path, message: "Price is invalid" });
          return true;
        }
        return true;
      }
      return true;
    }
  ),
  [formModels.formField.order_id.name]: Yup.mixed().when(
    [formModels.formField.type_url.name],
    (value, schema) => {
      return value.value === Type.order_detail
        ? schema.checkRequired("OrderId is required")
        : schema;
    }
  ),
  [formModels.formField.title_article.name]: Yup.mixed().when(
    [formModels.formField.type_url.name],
    (value, schema) => {
      const checkType = [Type.article];
      return checkType.includes(value.value)
        ? schema.checkRequired("Title is required")
        : schema;
    }
  ),
  [formModels.formField.url_article.name]: Yup.mixed().when(
    [formModels.formField.type_url.name],
    (value, schema) => {
      const checkType = [Type.article];
      return checkType.includes(value.value)
        ? schema.checkRequired("Url is required")
        : schema;
    }
  ),
  [formModels.formField.schedule_time.name]: Yup.mixed().when(
    [formModels.formField.delivery_type.name],
    (value, schema) => {
      return value?.value !== DELIVERY_TYPE.INSTANT
        ? schema.checkRequired("Schedule Time is required").checkInvalid()
        : schema;
    }
  ),
});

export default validationSchema;
