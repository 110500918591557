import React from "react";
import dataStorage from "./dataStorage";
import { Helmet } from "react-helmet-async";

export default function MetaData() {
  switch (dataStorage.env) {
    case "optixtrading":
      return (
        <Helmet>
          <title>{"Optix Trading CRM Portal"}</title>
          <meta property="og:title" content="Optix Trading CRM Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="Simple eKYC and onboarding for retail and AFSLs"
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/preview/optixtrading/preview.png"
          />
          <meta property="og:url" content="https://onboarding.equix.app" />
          <link rel="icon" href="/optixtrading/favicon.png" />
          <link rel="apple-touch-icon" href="/optixtrading/favicon.png" />
        </Helmet>
      );
    case "morrison":
      return (
        <Helmet>
          <title>{"Morrison Securities CRM Portal"}</title>
          <meta property="og:title" content="Morrison Securities CRM Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="Simple eKYC and onboarding for retail and AFSLs"
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/preview/equix/preview.png"
          />
          <meta property="og:url" content="https://onboarding.equix.app" />
          <link rel="icon" href="/morrison/favicon.png" />
          <link rel="apple-touch-icon" href="/morrison/favicon.svg" />
        </Helmet>
      );
    case "equitystorytrader":
      return (
        <Helmet>
          <title>{"Equity Story Trader CRM Portal"}</title>
          <meta property="og:title" content="Morrison Securities CRM Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="Simple eKYC and onboarding for retail and AFSLs"
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/preview/equix/preview.png"
          />
          <meta property="og:url" content="https://onboarding.equix.app" />
          <link rel="icon" href="/equitystorytrader/favicon.svg" />
          <link rel="apple-touch-icon" href="/equitystorytrader/favicon.svg" />
        </Helmet>
      );
    case "ricard":
      return (
        <Helmet>
          <title>{"Shares Exchange CRM Portal"}</title>
          <meta property="og:title" content="Morrison Securities CRM Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="Simple eKYC and onboarding for retail and AFSLs"
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/preview/equix/preview.png"
          />
          <meta property="og:url" content="https://onboarding.equix.app" />
          <link rel="icon" href="/ricard/favicon.png" />
          <link rel="apple-touch-icon" href="/ricard/favicon.svg" />
        </Helmet>
      );
    case "tradeforgood":
      return (
        <Helmet>
          <title>{"Trade For Good CRM Portal"}</title>
          <meta property="og:title" content="Trade For Good CRM Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="Simple eKYC and onboarding for retail and AFSLs"
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/preview/tradeforgood/preview.png"
          />
          <meta property="og:url" content="https://onboarding.equix.app" />
          <link rel="icon" href="/tradeforgood/favicon.png" />
          <link rel="apple-touch-icon" href="/tradeforgood/favicon.png" />
        </Helmet>
      );
    case "sharewise":
      return (
        <Helmet prioritizeSeoTags>
          <title>{"Sharewise Onboarding Portal"}</title>
          <meta property="og:title" content="Sharewise Onboarding Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="At Sharewise, our mission is to help our clients achieve their financial goals by providing safe and reliable investment advice through expert market analysis and personalised trading strategies. From retirement funds, more family holidays or just extra cash on the side, manage your portfolio with decreased risk and expert wealth."
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/sharewise/preview.png"
          />
          <meta
            property="og:url"
            content="https://onboarding.equix.app/sharewise"
          />
          <link rel="icon" href="/sharewise/favicon.svg" />
          <link rel="apple-touch-icon" href="/sharewise/favicon.svg" />
        </Helmet>
      );
    default:
      return (
        <Helmet>
          <title>{"EQUIX CRM Portal"}</title>
          <meta property="og:title" content="EQUIX CRM Portal" />
          <meta
            name="keywords"
            content="stockbroking, ekyc, asx, onboarding, stocktrading, ssx, chix"
          />
          <meta name="author" content="EQUIX" />
          <meta
            property="og:description"
            content="Simple eKYC and onboarding for retail and AFSLs"
          />
          <meta
            property="og:image"
            content="https://onboarding.equix.app/preview/equix/preview.png"
          />
          <meta property="og:url" content="https://onboarding.equix.app" />
          <link rel="icon" href="/equix/favicon.png" />
          <link rel="apple-touch-icon" href="/equix/favicon.png" />
        </Helmet>
      );
  }
}
