/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  styleButtonClose: {
    background: "#B75D69",
    "&:hover": {
      background: "#9D4358",
    },
  },
}));

const ConfirmModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(false);
  const [content, setContent] = React.useState(false);
  const confirmFunc = React.useRef();
  const classes = useStyles();

  useImperativeHandle(
    ref,
    () => ({
      showModal: showModal,
    }),
    []
  );

  const showModal = (func, options) => {
    setTitle(options?.title);
    setContent(options?.content);
    confirmFunc.current = func;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    setOpen(false);
    confirmFunc.current && confirmFunc.current();
    confirmFunc.current = null;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title || "Confirm delete"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content || "Are you sure you want to delete this item?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          className={classes.styleButtonClose}
        >
          {props.textCancel || "Close"}
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
          variant="contained"
          color="primary"
        >
          {props.textButton || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmModal;
