import React from "react";
import MUIDataTable, {
  debounceSearchRender,
  TableFilterList,
  TableFilter,
} from "mui-datatables";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {
  SelectFieldDataGrid,
  CheckboxDataGrid,
} from "src/components/CustomFields";

export const TABLE_ACTION = {
  SORT: "sort",
  FILTER_CHANGE: "filterChange",
  PAGE_CHANGE: "changePage",
  SEARCH: "search",
  SEARCH_CLOSE: "onSearchClose",
  PAGE_SIZE_CHANGE: "changeRowsPerPage",
};
export const TYPE = {
  DROPDOWN: "dropdown",
  CHECKBOX: "checkbox",
};

const CustomChip = () => {
  return <React.Fragment />;
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const CustomTableFilter = (props) => {
  return <TableFilter {...props} className="YOLO" />;
};

const CustomTable = React.forwardRef((props, ref) => {
  const [dataFields, setdDataFields] = React.useState({});
  const {
    title,
    loading = false,
    rows = [],
    columns = [],
    editModel = false,
    rowsPerPageOptions = [10, 20, 50],
    rowCount = 0,
    pageSize = 10,
    pageId = 0,
    onTableChange,
    onRowClick = null,
  } = props;
  React.useImperativeHandle(ref, () => ({
    dataFields: dataFields,
  }));
  const onchangeDropdown = (nameField) => (value) => {
    setdDataFields((e) => ({ ...e, [nameField]: value }));
  };
  const formatColumns = React.useCallback(() => {
    if (!editModel) return columns;
    const cellColumns = [...columns];
    for (const key in cellColumns) {
      const optionsField = cellColumns[key].options;
      switch (cellColumns[key]?.type) {
        case TYPE.DROPDOWN: {
          cellColumns[key].options = {
            ...optionsField,
            customBodyRender: (value, tableMeta) => {
              const nameField = `${tableMeta.rowData[0]}/${cellColumns[key]?.name}`;
              return (
                <SelectFieldDataGrid
                  data={cellColumns[key]?.dataOptions}
                  value={value}
                  handleChange={onchangeDropdown(nameField)}
                  fullWidth
                />
              );
            },
          };
          break;
        }
        case TYPE.CHECKBOX: {
          cellColumns[key].options = {
            ...optionsField,
            customBodyRender: (value, tableMeta) => {
              const nameField = `${tableMeta.rowData[0]}/${cellColumns[key]?.name}`;
              return (
                <CheckboxDataGrid
                  value={value}
                  handleChange={onchangeDropdown(nameField)}
                />
              );
            },
          };
          break;
        }
      }
    }
    return cellColumns;
  }, [editModel, columns]);

  return (
    <MUIDataTable
      title={
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">
              {title?.title || ""}
              {loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </Typography>
            <div style={{ padding: 3 }}></div>
            {title?.actionTitle || null}
          </div>
        </>
      }
      data={rows}
      columns={formatColumns()}
      components={{
        TableFilterList: CustomFilterList,
        TableFilter: CustomTableFilter,
      }}
      options={{
        confirmFilters: true,
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
          return (
            <div style={{ marginTop: "40px" }}>
              <Button variant="contained" onClick={() => applyNewFilters()}>
                Apply Filters
              </Button>
            </div>
          );
        },
        serverSide: true,
        pagination: true,
        setRowProps: (row, dataIndex) => ({
          onDoubleClick: () => {
            onRowClick?.(dataIndex);
          },
        }),
        customSearchRender: debounceSearchRender(500),
        onTableChange: onTableChange,
        count: rowCount,
        page: pageId - 1,
        rowsPerPageOptions: rowsPerPageOptions,
        rowsPerPage: pageSize,
        filterType: "textField",
        fixedHeader: false,
        draggableColumns: {
          enabled: true,
        },
        selectableRows: "single",
        selectableRowsOnClick: false,
        selectableRowsHideCheckboxes: true,
        responsive: "standard",
      }}
    />
  );
});

CustomTable.displayName = "CustomTable";
export default CustomTable;
