import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import dataStorage from "./dataStorage";
import { Provider } from "react-redux";
import store from "./redux/store";
import Meta from "./meta";
import { HelmetProvider } from "react-helmet-async";
import { makeCapitalizeFunc } from "src/utils/functionUtils";
import logger from "src/utils/logger";
makeCapitalizeFunc();
const initialWebsite = () => {
  if (!dataStorage.env) return <></>;
  ReactDOM.render(
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Meta />
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>,
    document.getElementById("root")
  );
};

const path = window.location.pathname;
let env = path.match(/^\/([^\/]*)/)[1]; // eslint-disable-line
if (!dataStorage.listEnv.includes(env)) env = "";
console.log("=====> ", env);
dataStorage.env = env;
if (env) {
  fetch(`${window.location.origin}/web.json?${+new Date()}`)
    .then((response) => response.json())
    .then((configWeb) => {
      window.__data = dataStorage;
      dataStorage.configAll = configWeb;
      dataStorage.config = configWeb && configWeb[env];
      dataStorage.baseUrl = `${dataStorage.config?.baseUrl}/${dataStorage.config.version}`;
      dataStorage.addressKey = dataStorage.config.addressKey;
      dataStorage.baseUrlSSE = `${configWeb[env].baseUrlSSE}/${dataStorage.config.version}`;
      initialWebsite();
    })
    .catch((error) => {
      logger.error(error, "get config error");
      initialWebsite();
    });
} else {
  initialWebsite();
}
