import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {},
  filterField: {
    display: "flex",
    width: "50%",
  },
  dateRanger: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& > div": {
      width: "calc(50% - 16px)",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 32,
  },
}));

const DateTimeFilter = ({ data, options, onChange }) => {
  const classes = useStyles();
  const field = React.useRef(options[0].value);
  const from = React.useRef(data?.from);
  const to = React.useRef(data?.to);
  const timeid = React.useRef("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    field.current = data?.field;
    from.current = data?.from;
    to.current = data?.to;
    forceUpdate();
  }, [data]);

  const onChangeDate = (type) => (value) => {
    const date = moment(value);
    if (type === "from") from.current = date.isValid() ? +date : "";
    if (type === "to") to.current = date.isValid() ? +date : "";
    timeid.current && clearTimeout(timeid.current);
    timeid.current = setTimeout(() => {
      onChange({
        from: from.current,
        to: to.current,
        field: field.current,
      });
    }, 350);
  };

  const onChangeField = (e) => {
    field.current = e.target.value;
    timeid.current && clearTimeout(timeid.current);
    timeid.current = setTimeout(() => {
      onChange({
        from: from.current,
        to: to.current,
        field: field.current,
      });
    }, 350);
  };

  return (
    <div className={classes.container}>
      <FormControl className={classes.filterField} variant="standard" fullWidth>
        <InputLabel id="demo-simple-select-label">
          Time filter column
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          value={field.current}
          onChange={onChangeField}
        >
          {options.map((e, i) => (
            <MenuItem key={i} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.dateRanger}>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              clearable
              label="From"
              ampm={false}
              value={from.current ? new Date(from.current) : null}
              onChange={onChangeDate("from")}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ width: 32 }} />
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              clearable
              label="To"
              ampm={false}
              value={to.current ? new Date(to.current) : null}
              onChange={onChangeDate("to")}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default DateTimeFilter;
