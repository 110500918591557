import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/styles";
import { BackgroundImage } from "@patternfly/react-core";
import { Footer } from "./Footer";
import dataStorage from "src/dataStorage";

const MainLayoutRoot = styled("div")(() => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutWrapper = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  height: "100%",
  flexDirection: "column",
  overflow: "auto",
});

export const MainLayout = () => (
  <MainLayoutRoot>
    <BackgroundImage src={`${dataStorage.config?.background}`} />
    {/* <img
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: -1,
                width: '100%',
                height: '100%'
            }}
            src={`${dataStorage.config?.background}`} /> */}
    <MainLayoutWrapper>
      <MainLayoutContainer>
        <MainLayoutContent>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Outlet />
          </div>
          <Footer color="white" />
        </MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutWrapper>
  </MainLayoutRoot>
);
