import {
  NOTIFICATION_TYPE_OPTIONS,
  DELIVERY_TYPE_OPTIONS,
  ORDER_TYPE,
  TYPE_URL,
} from "src/constants";

const initialValues = {
  notification_type: NOTIFICATION_TYPE_OPTIONS[0],
  audience: "",
  delivery_type: DELIVERY_TYPE_OPTIONS[0],
  expire_time: "",
  channel: "",
  title: "",
  message: "",
  url: "",
  icon: "",
  large_image: "",
  action1_title: "",
  action1_url: "",
  action2_title: "",
  action2_url: "",
  subsribers: [],
  optionType: "",
  expireNumber: "",
  mobile_push: true,
  desktop_push: true,
  email_push: true,
  sms_push: false,
  order_type: ORDER_TYPE[0],
  Type_Url: TYPE_URL[0],
  symbol: "",
  symbolMultiple: [],
  // Type_Url:
};
export default initialValues;
