import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function BasicSelect({ ...props }) {
  const { data, value, ...rest } = props;
  const [valueField, setvalueField] = React.useState(value);
  // console.log(props?.value)
  const onChangeField = (event) => {
    const valueField = event.target.value;
    setvalueField(valueField);
    props?.handleChange && props.handleChange(valueField);
  };
  return (
    <Select
      // labelId="demo-simple-select-label"
      // id="demo-simple-select"
      defaultValue={value}
      value={valueField}
      label="Age"
      onChange={onChangeField}
      {...rest}
    >
      {data?.map((e, i) => {
        return (
          <MenuItem value={e.value} key={i}>
            {e?.label || ""}
          </MenuItem>
        );
      }) || null}
    </Select>
  );
}
