import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useFormikContext } from "formik";
import {
  InputField,
  CheckboxField,
  BooleanRadio,
  SelectField,
  DatePickerField,
  AutocompleteFieldAsync,
  PhoneNumberField,
  AutocompleteField,
} from "src/components/CustomFields";
import {
  COMPANY_TYPE_OPTIONS,
  COMPANY_SECTOR_OPTIONS,
  TAX_EXEMPTION_DETAILS_OPTIONS,
  AUSTRALIAN_TAX_OPTIONS,
  STREET_TYPE,
  STATE_OF_ISSUE_OPTIONS,
  TAX_STATUS_COMPANY_OPTIONS,
  TAX_STATUS,
  FATCA_STATUS,
  FATCA_STATUS_OPTIONALS,
  TIN_REASON_OPTIONS,
  SOURCE_OF_WEALTH_ENTITY_OPTIONS,
  INDUSTRY_OPTIONS,
  NATURE_OF_TRUST_ACTIVITY_OPTIONS,
} from "src/constants";
import formModel from "../FormModels/formModels";
import HeadText from "src/components/HeadText";
import COUNTRIES from "src/countries";
import HelpIcon from "src/components/HelpIcon";
import HelpLabel from "src/components/HelpLabel";

const { formField } = formModel;

export default function CompanyDetails() {
  const { values: formValues } = useFormikContext();

  const renderTaxStatus = () => {
    switch (formValues.tax_status?.value) {
      case TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY:
        return (
          <React.Fragment>
            <Grid item xs={12}>
              <BooleanRadio
                name={formField.tax_resident_of_australia.name}
                label={formField.tax_resident_of_australia.label}
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
            {!formValues.tax_resident_of_australia ? (
              <React.Fragment />
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <AutocompleteField
                    required
                    name={formField.country_of_tax_residency.name}
                    label={formField.country_of_tax_residency.label}
                    data={COUNTRIES}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <BooleanRadio
                    name={formField.tin_ask.name}
                    label={formField.tin_ask.label}
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
                {formValues.tin_ask ? (
                  <Grid item xs={12} sm={12}>
                    <InputField
                      required
                      name={formField.taxpayer_identification_number.name}
                      label={formField.taxpayer_identification_number.label}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <SelectField
                      required
                      name={
                        formField
                          .taxpayer_identification_number_exemption_reason.name
                      }
                      label={
                        formField
                          .taxpayer_identification_number_exemption_reason.label
                      }
                      data={TIN_REASON_OPTIONS}
                      fullWidth
                    />
                  </Grid>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case TAX_STATUS.FINANCIAL_INSTITUTION:
        return (
          <React.Fragment>
            <Grid item xs={12}>
              <BooleanRadio
                name={formField.gin_asked.name}
                label={formField.gin_asked.label}
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
            {formValues.gin_asked ? (
              <Grid item xs={12} sm={12}>
                <InputField
                  required
                  name={
                    formField.global_intermediary_identification_number.name
                  }
                  label={
                    formField.global_intermediary_identification_number.label
                  }
                  fullWidth
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <SelectField
                    required={true}
                    name={formField.fatca_status.name}
                    label={formField.fatca_status.label}
                    data={FATCA_STATUS_OPTIONALS}
                    fullWidth
                  />
                </Grid>
                {formValues.fatca_status?.value === FATCA_STATUS.OTHER ? (
                  <Grid item xs={12}>
                    <InputField
                      required
                      name={formField.fatca_status_other.name}
                      label={formField.fatca_status_other.label}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <React.Fragment />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case TAX_STATUS.OTHER:
        return (
          <React.Fragment>
            <Grid item xs={12}>
              <BooleanRadio
                name={formField.is_controlling_person_foreign_tax_resident.name}
                label={
                  formField.is_controlling_person_foreign_tax_resident.label
                }
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <BooleanRadio
                name={formField.tax_resident_of_australia.name}
                label={formField.tax_resident_of_australia.label}
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
            {!formValues.tax_resident_of_australia ? (
              <React.Fragment />
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <AutocompleteField
                    required
                    name={formField.country_of_tax_residency.name}
                    label={formField.country_of_tax_residency.label}
                    data={COUNTRIES}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <BooleanRadio
                    name={formField.tin_ask.name}
                    label={formField.tin_ask.label}
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
                {formValues.tin_ask ? (
                  <Grid item xs={12} sm={12}>
                    <InputField
                      required
                      name={formField.taxpayer_identification_number.name}
                      label={formField.taxpayer_identification_number.label}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <SelectField
                      required
                      name={
                        formField
                          .taxpayer_identification_number_exemption_reason.name
                      }
                      label={
                        formField
                          .taxpayer_identification_number_exemption_reason.label
                      }
                      data={TIN_REASON_OPTIONS}
                      fullWidth
                    />
                  </Grid>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
    }
  };

  const renderCompanyInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <InputField
            required
            name={formField.company_name.name}
            label={formField.company_name.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <SelectField
            required
            name={formField.company_type.name}
            label={formField.company_type.label}
            data={COMPANY_TYPE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <SelectField
            required
            name={formField.company_sector.name}
            label={formField.company_sector.label}
            data={COMPANY_SECTOR_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <DatePickerField
            name={formField.company_date_of_incorporation.name}
            label={formField.company_date_of_incorporation.label}
            required
            inputFormat="dd/MM/yyyy"
            minDate={new Date("1920/1/1")}
            maxDate={Date.now()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <InputField
            required
            name={formField.company_acn.name}
            label={formField.company_acn.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <InputField
            name={formField.company_abn.name}
            label={formField.company_abn.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <AutocompleteField
            required
            name={formField.company_industry.name}
            label={formField.company_industry.label}
            data={INDUSTRY_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <AutocompleteField
            required
            name={formField.company_nature_of_business_activity.name}
            label={formField.company_nature_of_business_activity.label}
            data={
              NATURE_OF_TRUST_ACTIVITY_OPTIONS[
                formValues.company_industry?.value
              ]
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <SelectField
            required
            name={formField.company_source_of_wealth.name}
            label={formField.company_source_of_wealth.label}
            data={SOURCE_OF_WEALTH_ENTITY_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <HeadText>Company Contact Information</HeadText>
        </Grid>
        <Grid item xs={12} className="addressContainer">
          {formValues.company_registered_office_address_manual_address ? (
            <React.Fragment />
          ) : (
            <AutocompleteFieldAsync
              required
              name={
                formField.company_registered_office_address_full_address.name
              }
              label={
                formField.company_registered_office_address_full_address.label
              }
              fullWidth
            />
          )}
          <CheckboxField
            name={
              formField.company_registered_office_address_manual_address.name
            }
            label="Cannot find your address? Manual Input"
          />
        </Grid>
        {formValues.company_registered_office_address_manual_address ? (
          <React.Fragment>
            <Grid item xs={6} sm={6}>
              <InputField
                name={
                  formField.company_registered_office_address_unit_flat_number
                    .name
                }
                label={
                  formField.company_registered_office_address_unit_flat_number
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={
                  formField.company_registered_office_address_street_number.name
                }
                label={
                  formField.company_registered_office_address_street_number
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={
                  formField.company_registered_office_address_street_name.name
                }
                label={
                  formField.company_registered_office_address_street_name.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={
                  formField.company_registered_office_address_street_type.name
                }
                label={
                  formField.company_registered_office_address_street_type.label
                }
                data={STREET_TYPE}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={
                  formField.company_registered_office_address_city_suburb.name
                }
                label={
                  formField.company_registered_office_address_city_suburb.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={formField.company_registered_office_address_state.name}
                label={formField.company_registered_office_address_state.label}
                data={STATE_OF_ISSUE_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={formField.company_registered_office_address_postcode.name}
                label={
                  formField.company_registered_office_address_postcode.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <CheckboxField
            name={formField.company_same_as_roa.name}
            label={formField.company_same_as_roa.label}
          />
        </Grid>
        {formValues.company_same_as_roa ? (
          <React.Fragment />
        ) : (
          <React.Fragment>
            <Grid item xs={12} className="addressContainer">
              {formValues.company_principal_place_of_business_address_manual_address ? (
                <React.Fragment />
              ) : (
                <AutocompleteFieldAsync
                  required
                  name={
                    formField
                      .company_principal_place_of_business_address_full_address
                      .name
                  }
                  label={
                    formField
                      .company_principal_place_of_business_address_full_address
                      .label
                  }
                  fullWidth
                />
              )}
              <CheckboxField
                name={
                  formField
                    .company_principal_place_of_business_address_manual_address
                    .name
                }
                label="Cannot find your address? Manual Input"
              />
            </Grid>
            {formValues.company_principal_place_of_business_address_manual_address ? (
              <React.Fragment>
                <Grid item xs={6} sm={6}>
                  <InputField
                    name={
                      formField
                        .company_principal_place_of_business_address_unit_flat_number
                        .name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_unit_flat_number
                        .label
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    required
                    name={
                      formField
                        .company_principal_place_of_business_address_street_number
                        .name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_street_number
                        .label
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    required
                    name={
                      formField
                        .company_principal_place_of_business_address_street_name
                        .name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_street_name
                        .label
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SelectField
                    required
                    name={
                      formField
                        .company_principal_place_of_business_address_street_type
                        .name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_street_type
                        .label
                    }
                    data={STREET_TYPE}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    required
                    name={
                      formField
                        .company_principal_place_of_business_address_city_suburb
                        .name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_city_suburb
                        .label
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SelectField
                    required
                    name={
                      formField
                        .company_principal_place_of_business_address_state.name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_state.label
                    }
                    data={STATE_OF_ISSUE_OPTIONS}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <InputField
                    required
                    name={
                      formField
                        .company_principal_place_of_business_address_postcode
                        .name
                    }
                    label={
                      formField
                        .company_principal_place_of_business_address_postcode
                        .label
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6}></Grid>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <PhoneNumberField
            maxLength={10}
            name={formField.company_work_phone.name}
            label={formField.company_work_phone.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PhoneNumberField
            prefix="04"
            name={formField.company_mobile_phone.name}
            label={formField.company_mobile_phone.label}
            helperText={"Note: Provide at least one contact phone number"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            required
            name={formField.company_email.name}
            label={formField.company_email.label}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  };

  const renderTaxInformation = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <BooleanRadio
            tooltip={
              <div>
                Some people or organisations are exempt from quoting a TFN in
                relation to their investments. For details to see if this
                applies to you,{" "}
                <Link
                  target="_blank"
                  href="https://www.ato.gov.au/print-publications/tfn-and-abn-information-for-savings-accounts-and-investments/?page=8"
                >
                  see the ATO website.
                </Link>
              </div>
            }
            name={formField.company_tax_exemption.name}
            label={formField.company_tax_exemption.label}
            data={AUSTRALIAN_TAX_OPTIONS}
            fullWidth
          />
        </Grid>
        {formValues.company_tax_exemption ? (
          <Grid item xs={12}>
            <SelectField
              required
              name={formField.company_tax_exemption_details.name}
              label={formField.company_tax_exemption_details.label}
              data={TAX_EXEMPTION_DETAILS_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InputField
              disabled={formValues.company_tax_exemption}
              name={formField.company_tfn.name}
              label={formField.company_tfn.label}
              helperText={
                <>
                  Note: Quotation of your Australian Tax File Number(s) (TFN) is
                  optional
                  <HelpIcon
                    tooltip={
                      <div>
                        <p>
                          Morrison Securities is an Australian financial service
                          licensee that is authorised by law to request your
                          TFN. You are not required to provide your TFN and
                          failing to provide your TFN to Morrison Securities is
                          not an offence. If Morrison Securities is unable to
                          quote your tax file number or exemption to registries,
                          it may be obliged to take tax at the highest marginal
                          rate from any dividends, distributions, interest and
                          payments to which you are entitled.
                        </p>
                        <p>
                          Accordingly, failing to provide your TFN or not
                          permitting Morrison Securities to quote it in relation
                          to an investment may have taxation consequences. You
                          may wish to seek independent advice in this regard.
                        </p>
                        <p>By providing a TFN with this Application you:</p>
                        <p>
                          a. appoint Morrison Securities as your agent and
                          request and authorise Morrison Securitiesto;
                        </p>
                        <p>
                          (i) provide your Tax File Number to all investment
                          bodies with whom Morrison Securities acts on
                          yourbehalf;
                        </p>
                        <p>
                          (ii) apply your TFN to any investment or account which
                          you may in future make or open with or through
                          Morrison Securities (and their related bodies
                          corporate) to which your TFN may lawfully be
                          applied;and
                        </p>
                        <p>
                          b. acknowledge that this authority will apply until
                          such time as it is revoked in writing to
                          MorrisonSecurities.&quot
                        </p>
                        <p>
                          Despite the other terms in this section, you may
                          instruct Morrison Securities in writing at the time of
                          making an investment, not to quote your TFN in
                          relation to that investment.
                        </p>
                      </div>
                    }
                    placement="right"
                    iconName="Info"
                  />
                </>
              }
              fullWidth
            />
          </Grid>
        )}
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HeadText>Company Information</HeadText>
      </Grid>
      {renderCompanyInfo()}
      <Grid item xs={12}>
        <HeadText>Tax Information</HeadText>
      </Grid>
      {renderTaxInformation()}
      <Grid item xs={12}>
        <HelpLabel
          label="Tax Status"
          tooltip={
            <div>
              <Typography component="span" variant="body2">
                <span style={{ fontWeight: "bold" }}>
                  • ACTIVE NON-FINANCIAL ENTITY (NFE)
                </span>
                 - Active NFEs include entities where, during the previous
                reporting period, less than 50% of their gross income was
                passive income (e.g. dividends, interests and royalties) and
                less than 50% of assets held produced passive income.
              </Typography>
              <br />
              <Typography component="span" variant="body2">
                <span style={{ fontWeight: "bold" }}>
                  • FINANCIAL INSTITUTION
                </span>
                 - A custodial or depository institution, an investment entity
                or a specified insurance company for FATCA/CRS purposes e.g. a
                bank or insurance company.
              </Typography>
              <br />
              <Typography component="span" variant="body2">
                <span style={{ fontWeight: "bold" }}>
                  • AUSTRALIAN REGISTERED CHARITY
                </span>
                 - A company that is an Australian Registered Charity.
              </Typography>
              <br />
              <Typography component="span" variant="body2">
                <span style={{ fontWeight: "bold" }}>• OTHER</span> - Entities
                that are not previously listed e.g. Passive Non-Financial
                entities.
              </Typography>
            </div>
          }
        />
        <SelectField
          required
          name={formField.tax_status.name}
          label={formField.tax_status.label}
          data={TAX_STATUS_COMPANY_OPTIONS}
          fullWidth
        />
      </Grid>
      {renderTaxStatus()}
    </Grid>
  );
}
