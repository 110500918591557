import React from "react";
import { getData } from "src/api/api";

const useFetch = ({ url = "" }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const errorMessage = React.useRef(null);
  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getData(url)
      .then((res) => {
        isMounted && setData(res?.[0]?.data || res?.[0]);
      })
      .catch((error) => {
        if (isMounted) {
          setData(null);
          errorMessage.current = error;
          setLoading(false);
        }
      })
      .finally(() => {
        isMounted && setLoading(false);
      });
    return () => (isMounted = false);
  }, [url]);
  return { data, loading, errorMessage };
};

export default useFetch;
