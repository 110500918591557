import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import Activities from "./Activities";

const useStyles = makeStyles(() => ({
  modalContainer: {
    width: "86vw",
    outline: "none",
  },
  closeIcon: {
    position: "absolute",
    top: 24,
    right: 24,
  },
}));

const ModalActivities = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const infoAccount = React.useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      showModal: showModal,
    }),
    []
  );

  const showModal = (infoUser = "") => {
    infoUser && (infoAccount.current = infoUser);
    setOpen(true);
  };

  const handleModalToggle = () => {
    setOpen((old) => !old);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={classes.modalContainer}>
        <Activities
          handleModalToggle={handleModalToggle}
          infoAccount={infoAccount.current}
        />
      </div>
    </Modal>
  );
});

ModalActivities.displayName = "ModalActivities";
export default ModalActivities;
