import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 2)
  },
  paper: {
    borderRadius: 10,
    minHeight: '400px',
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  styleCheckBox: {
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 0,
      '& legend': {
        marginRight: 16,
        color: 'black',
      }
    }
  },
  styleButtonClose: {
    background: "#B75D69",
    '&:hover': {
      background: '#9D4358'
    }
  }
}));
