import React from "react";
import MUIDataTable, {
  debounceSearchRender,
  TableFilterList,
  TableFilter,
} from "mui-datatables";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export const TABLE_ACTION = {
  SORT: "sort",
  FILTER_CHANGE: "filterChange",
  PAGE_CHANGE: "changePage",
  SEARCH: "search",
  SEARCH_CLOSE: "onSearchClose",
  PAGE_SIZE_CHANGE: "changeRowsPerPage",
};

const useStyles = makeStyles(() => ({
  dataTableContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    minHeight: 0,
    "& > div:first-child": {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100%",
      minHeight: 0,
    },
    "& thead tr th": {
      borderRight: "2px solid white",
    },
    "& tbody tr:nth-child(even)": {
      background: "#f3f4f6 !important",
    },
    "& tbody tr:nth-child(even) td:first-child": {
      background: "#f3f4f6 !important",
    },
  },
}));

const CustomChip = () => {
  return <React.Fragment />;
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const CustomTableFilter = (props) => {
  return <TableFilter {...props} className="YOLO" />;
};

const CustomTable = (props) => {
  const classes = useStyles();
  const {
    title,
    loading = false,
    rows = [],
    columns = [],
    rowsPerPageOptions = [10, 20, 50],
    rowCount = 0,
    pageSize = 10,
    pageId = 0,
    onTableChange,
    onRowClick = null,
  } = props;

  return (
    <div className={classes.dataTableContainer}>
      <MUIDataTable
        title={
          <Typography variant="h6">
            {title || ""}
            {loading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={rows}
        columns={columns}
        components={{
          TableFilterList: CustomFilterList,
          TableFilter: CustomTableFilter,
        }}
        options={{
          confirmFilters: true,
          customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
              <div style={{ marginTop: "40px" }}>
                <Button variant="contained" onClick={() => applyNewFilters()}>
                  Apply Filters
                </Button>
              </div>
            );
          },
          serverSide: true,
          pagination: true,
          setRowProps: (row, dataIndex) => ({
            onDoubleClick: () => {
              onRowClick?.(dataIndex);
            },
          }),
          customSearchRender: debounceSearchRender(500),
          onTableChange: onTableChange,
          count: rowCount,
          page: pageId - 1,
          rowsPerPageOptions: rowsPerPageOptions,
          rowsPerPage: pageSize,
          filterType: "textField",
          fixedHeader: false,
          draggableColumns: {
            enabled: true,
          },
          selectableRows: "single",
          selectableRowsOnClick: false,
          selectableRowsHideCheckboxes: true,
          responsive: "standard",
        }}
      />
    </div>
  );
};

export default CustomTable;
