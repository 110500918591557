import React from "react";
import moment from "moment";
import { getData, getUrlDataBusinessLog } from "src/api/api";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "src/components/ConfirmModal";
import { Paper } from "@mui/material";
import CustomTable, { TABLE_ACTION } from "src/components/CustomTable";
import dataStorage from "src/dataStorage";
import { getFilterObj, getAccountStatus } from "src/utils/functionUtils";
import logger from "src/utils/logger";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    minHeight: 0,
    overflow: "hidden",
    width: "100%",
    position: "relative",
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0,
    },
  },
}));

const FIELD = {
  TIME: "time",
};

const LIST_FILTER = [...Object.values(FIELD)];

const defaultPaginate = {
  pageSize: 10,
  currentPage: 1,
  totalCount: 0,
  totalPages: 0,
};

const showDevice = (data) => {
  try {
    let detail;
    if (
      data &&
      data.device_info &&
      data.device_info !== "#" &&
      typeof data.device_info === "string"
    )
      detail = this.parseJson(data.device_info, "showInfoDevice");
    if (detail) {
      const os = detail.os && detail.os.name ? detail.os.name : "";
      const browser =
        detail.browser && detail.browser.name ? detail.browser.name : "";
      const model =
        detail.device && detail.device.model ? detail.device.model : "";
      const vendor =
        detail.device && detail.device.vendor ? detail.device.vendor : "";
      const osUpperCase = os ? os.toUpperCase() : "";
      let deviceInfoShow = (
        <span className="text-uppercase">
          equix web on {browser} browser (desktop)
        </span>
      );
      if (os && ["ANDROID", "IOS"].indexOf(osUpperCase) >= 0) {
        if (vendor && model) {
          deviceInfoShow = (
            <span className="text-uppercase">
              {" "}
              equix app on {vendor} {model} (mobile){" "}
            </span>
          );
        } else {
          deviceInfoShow = (
            <span className="text-uppercase"> equix app on {os} (mobile) </span>
          );
        }
        return os ? (
          deviceInfoShow
        ) : (
          <div className="text-capitalize">unknown device</div>
        );
      }
      return browser ? (
        deviceInfoShow
      ) : (
        <div className="text-capitalize">unknown device</div>
      );
    } else return <div className="text-capitalize">unknown device</div>;
  } catch (error) {
    logger.log("parse device info error", error);
  }
};

const renderTime = (x) => {
  if (x.action === "save_balance" || x.action === "export_eod") return null;
  const timeWraith = x.time
    ? moment(x.time).tz("Australia/Sydney").format("DD/MM/YYYY HH:mm:ss")
    : "--";
  return timeWraith;
};
const parseJson = (str) => {
  try {
    if (typeof str === "object") return str;
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};
const UserManagement = (props) => {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const paginate = React.useRef(defaultPaginate);
  const searchText = React.useRef("");
  const confirmModalRef = React.useRef();
  const modalViewApplicationRef = React.useRef();
  const timeout = React.useRef(0);
  const currentData = React.useRef(null);
  const renderColumn = () => {
    let COLUMNS = [
      {
        name: FIELD.TIME,
        label: " ",
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
          filterType: "custom",
          customBodyRender: (value) => {
            const findItem = currentData.current?.find((e) => e.time == value);
            if (findItem) {
              return (
                <>
                  <Stack direction="row" spacing={1}>
                    <div>{renderTime(findItem)}</div>
                    <div>{props?.infoAccount?.actor || ""}</div>
                    <div>{renderCell(findItem)}</div>
                  </Stack>
                </>
              );
            }
            return <>{value}</>;
          },
        },
      },
    ];
    return COLUMNS.filter((e) => e != null);
  };

  const updatePaginate = (obj) => {
    paginate.current = {
      pageSize: obj.page_size,
      currentPage: obj.current_page,
      totalCount: obj.total_count,
      totalPages: obj.total_pages,
    };
  };
  const renderLoginLogOutAction = (data) => {
    let accountDetails = data && data.action_details;
    accountDetails = parseJson(accountDetails);
    return (
      <Stack direction="row" spacing={1}>
        <div className="grayBox text-uppercase size--2">
          {" "}
          {(data.action + "").toUpperCase()}{" "}
        </div>
        <div className="grayBox size--2 text-uppercase">
          {showDevice(data)}{" "}
        </div>
        <div className="normalText size--3"> with </div>
        <div className="grayBox text-uppercase size--2">
          Ip Address {data.ip_address}{" "}
        </div>
        {data.action === "sign_in" ? (
          <div className="grayBox text-uppercase size--2">
            {" "}
            {accountDetails?.res_data?.errorCode === "SUCCESSFUL"
              ? "SUCCESSFULLY"
              : "UNSUCCESSFULLY"}
          </div>
        ) : null}
      </Stack>
    );
  };
  const renderCell = (data) => {
    try {
      switch (data.action) {
        case "sign_in":
        case "sign_out":
          return renderLoginLogOutAction(data);
        case "place_order":
        case "modify_order":
        case "cancel_order":
          return <>xin chao</>;
        case "query_cash_report":
        case "query_holdings_report":
        case "query_financial_report":
        case "query_transaction_report":
          return <>xin chao</>;
        case "update_saxo_account":
        case "enter_pin":
        case "update_setting":
        case "change_news_source":
        case "delete_watchlist":
        case "reset_password":
        case "change_AO":
        case "forgot_password":
        case "create_user":
        case "update_user":
        case "create_role_group":
        case "update_role_group":
        case "delete_role_group":
        case "change_market_data":
        case "update_vetting_rule":
          return <>xin chao</>;
        case "add_symbol":
        case "remove_symbol":
        case "update_symbol":
          return <>xin chao</>;
        case "change_status":
          return <>xin chao</>;
        default:
          return null;
      }
    } catch (error) {
      console.log("error render cell bussiness log", error);
    }
  };
  const getDraft = async ({ pageId, pageSize }) => {
    setLoading(true);
    // console.log(restDraft)
    const [response, error] = await getData(
      getUrlDataBusinessLog(
        "",
        props.infoAccount.user_id,
        "",
        pageId,
        pageSize,
        "",
        "",
        { fromDate: "27/04/22-14:00:00.000", toDate: "04/05/22-13:59:59.999" }
      )
    );
    setLoading(false);
    if (error) {
      dataStorage.showAlert?.({ type: "error", message: error });
      return;
    }
    const { data, ...rest } = response;
    currentData.current = data;
    setLoading(false);
    updatePaginate({ ...rest, page_size: pageSize });
    const listData = data
      ? Object?.keys(data)?.map((k) => ({
          ...data[k],
        }))
      : [];
    setRows(listData);
  };

  React.useEffect(() => {
    getDraft({
      pageId: paginate.current.currentPage,
      pageSize: paginate.current.pageSize,
      search: searchText.current,
    });
  }, []);

  const onTableChange = (action, tableState) => {
    if (
      ["propsUpdate", "onFilterDialogOpen", "onFilterDialogClose"].includes(
        action
      )
    )
      return;
    timeout.current && clearTimeout(timeout.current);
    const filterItemRedundant = LIST_FILTER.filter((e) =>
      tableState.columns.find((item) => item.name == e)
    );
    timeout.current = window.setTimeout(() => {
      const filterObj = getFilterObj(tableState, filterItemRedundant);
      switch (action) {
        case TABLE_ACTION.SORT:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.FILTER_CHANGE:
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.PAGE_CHANGE:
          getDraft({
            pageId: tableState.page + 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.PAGE_SIZE_CHANGE:
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: tableState.rowsPerPage,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.SEARCH:
          searchText.current = tableState.searchText;
          getDraft({
            pageId: 1,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        case TABLE_ACTION.SEARCH_CLOSE:
          if (!searchText.current) return;
          getDraft({
            pageId: paginate.current.currentPage,
            pageSize: paginate.current.pageSize,
            search: searchText.current,
            ...filterObj,
          });
          break;
        default:
          break;
      }
    }, 500);
  };

  const onRowClick = async (index) => {
    const rowData = rows[index];
    dataStorage.dicDraft = rowData;
    dataStorage.accountType =
      dataStorage.dicDraft?.data?.formData?.account_type;
    if (dataStorage.dicDraft?.data?.index) {
      dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0;
      dataStorage.isSubApplicant = true;
    }
    dataStorage.registerEmail =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_email;
    dataStorage.applicantId =
      dataStorage.dicDraft?.data?.formData?.applicant_details?.[
        dataStorage.dicDraft?.data?.index
      ]?.applicant_id;
    const equixId = dataStorage.dicDraft?.data?.formData?.equix_id;
    dataStorage.equixId = equixId;
    if (equixId && !dataStorage.isSubApplicant) await getAccountStatus(equixId);
    else dataStorage.accountStatus = null;
    if (dataStorage.accountType.value)
      dataStorage.accountType = dataStorage.accountType.value;
    // navigate(`/${dataStorage.env}/onboarding/new_application`)
    modalViewApplicationRef?.current?.showModal?.();
  };

  const renderTable = () => {
    return (
      <CustomTable
        title="User Management"
        loading={loading}
        rows={rows}
        onRowClick={onRowClick}
        columns={renderColumn()}
        rowsPerPageOptions={[10, 25, 100]}
        rowCount={paginate.current.totalCount}
        pageSize={paginate.current.pageSize}
        pageId={paginate.current.currentPage}
        onTableChange={onTableChange}
      />
    );
  };

  return (
    <Paper className={classes.paperContainer}>
      {/* <Activities ref={modelActivities} /> */}
      {renderTable()}
      <ConfirmModal ref={confirmModalRef} />
      {/* <UserManagementComponent ref={modalViewApplicationRef} /> */}
    </Paper>
  );
};

export default UserManagement;
