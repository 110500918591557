import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import { booleaner } from "src/utils/functionUtils";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import HelpIcon from "src/components/HelpIcon";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    marginTop: theme.spacing(1),
    "&.radio-horizontal": {
      width: "fit-content",
      "& $group": {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
      },
    },
  },
  group: {
    margin: theme.spacing(0),
  },
}));

function BooleanRadio(props) {
  const { label, isHorizontal, tooltip, data, style, ...rest } = props;
  const classes = useStyles();
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm } = helper || {};
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const _onChange = (event) => {
    const valueSelected = event.target.value;
    setValueForm && setValueForm(booleaner(valueSelected));
  };

  return (
    <FormControl
      // component="fieldset"
      // required
      {...rest}
      error={isError}
      className={classnames(classes.formControl, {
        "radio-horizontal": isHorizontal,
        "radio-vertical": !isHorizontal,
      })}
    >
      {label ? (
        <FormLabel component="legend">
          {label}
          {tooltip ? (
            <HelpIcon tooltip={tooltip} placement="right" iconName="Info" />
          ) : (
            <></>
          )}
        </FormLabel>
      ) : (
        <React.Fragment />
      )}
      <RadioGroup
        aria-label={label}
        name={label}
        className={classes.group}
        style={style}
        value={
          typeof selectedValue === "boolean"
            ? selectedValue
            : (selectedValue && selectedValue.value) ?? selectedValue
        }
        onBlur={field.onBlur}
        onChange={_onChange}
      >
        <FormControlLabel
          key={"yes"}
          value={true}
          control={
            <Radio
              color="secondary"
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
            />
          }
          label={data[0].label}
        />
        <FormControlLabel
          key={"no"}
          value={false}
          control={
            <Radio
              color="secondary"
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
            />
          }
          label={data[1].label}
        />
      </RadioGroup>
      <input
        readOnly
        style={{
          opacity: 0,
          position: "absolute",
          pointerEvents: "none",
          left: 136,
        }}
        type="checkbox"
        id="gender"
        required={rest.required}
        checked={
          !!(selectedValue && data.length
            ? selectedValue.value ?? selectedValue
            : "")
        }
      />
      {_renderHelperText()}
    </FormControl>
  );
}

BooleanRadio.defaultProps = {
  data: [],
};

BooleanRadio.propTypes = {
  data: PropTypes.array.isRequired,
};

export default BooleanRadio;
