export const initialState = {
  full_name: '',
  user_login_id: '',
  phone: '',
  password: '',
  change_password: true,
  send_password: true,
  role_group: 'RG0',
  user_group: '3',
  email_template: 'E1',
  note: '',
  access_method: '0',
  status: '2',
  api_access: '0',
  list_mapping: []
}
