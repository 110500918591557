import React from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomIcon from "./CustomIcon";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 540,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function CustomizedTooltips({
  iconName,
  tooltip,
  hover,
  placement = "bottom",
  style = {},
  iconColor = "#A7A7A8",
}) {
  const [isOpen, setOpened] = React.useState(false);

  const onClose = () => {
    if (hover) return;
    setOpened(false);
  };

  const onOpen = () => {
    if (hover) return;
    setOpened(true);
  };

  const props = React.useMemo(() => {
    if (hover) return {};
    else {
      return {
        open: isOpen,
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
      };
    }
  }, [hover, isOpen]);
  return (
    <ClickAwayListener onClickAway={onClose}>
      <HtmlTooltip
        placement={placement}
        onClose={onClose}
        {...props}
        title={<React.Fragment>{tooltip}</React.Fragment>}
      >
        <IconButton onClick={onOpen} style={style}>
          <CustomIcon
            name={iconName || "Help"}
            color={iconColor}
            style={{ width: 16, height: 16 }}
          />
        </IconButton>
      </HtmlTooltip>
    </ClickAwayListener>
  );
}
