import dataStorage from "src/dataStorage";
import Axios from "axios";
import { getErrorMessage } from "src/errors/errors";

export const ENVIROMENT_BACK = {
  equix: "equix",
  morrison: "morrison",
  equitystorytrader: "equity-story",
  ricard: "ricard",
  mps: "mps",
  dev1: "dev1",
  uat: "uat",
  optixtrading: "optixtrading",
  tradeforgood: "tradeforgood",
  sharewise: "sharewise",
};

export function getPerformSearchUrl(referenceNo) {
  return `${dataStorage.baseUrl}/kyc-greenid/business-verification?primary_business=${referenceNo}`;
}

export function getVerificationUrl(verificationId, individualId) {
  return `${dataStorage.baseUrl}/kyc-greenid/register-verification?type=business&verification_id=${verificationId}&individual_id=${individualId}`;
}

export function getBusinessInfoUrl() {
  return `${dataStorage.baseUrl}/kyc-greenid/business-verification`;
}

export function getCheckEmailExistUrl(email) {
  return `${dataStorage.baseUrl}/user/account-opening/check-user?email=${email}`;
}

export function getBrokerDetailUrl() {
  return `${dataStorage.baseUrl}/user/account/config?type=all`;
}

export function getVettingRuleUrl() {
  return `${dataStorage.baseUrl}/user/branch`;
}

export function getSearchAddressUrl(text) {
  return `${dataStorage.baseUrl}/address-finder/autocomplete?filter=${text}&post_box=0`;
}

export function getMetaDataAddressUrl(id) {
  return `${dataStorage.baseUrl}/address-finder/metadata?id=${id}`;
}

export function getSessionUrl(id) {
  return `${dataStorage.baseUrl}/auth/session?session_id=${id}`;
}
export function getUserGroupUrl(userGroupId) {
  if (userGroupId) {
    return `${dataStorage.baseUrl}/user/role-group/${userGroupId}`;
  } else {
    return `${dataStorage.baseUrl}/user/role-group`;
  }
}
export function getAllAccountUrl(pageId = 1, pageSize = 100, filterText) {
  let res = `${dataStorage.baseUrl}/user/account/inquery?user_id=${dataStorage.userInfo.user_id}&page_id=${pageId}&page_size=${pageSize}`;
  if (filterText && filterText !== "") {
    res += `&filter=${filterText.toLowerCase()}`;
  }
  return res;
}
export function getUrlCreateUser() {
  return `${dataStorage.baseUrl}/user/user-details`;
}
export function getResetPasswordUrl() {
  return `${dataStorage.baseUrl}/auth/send-verify-username`;
}
export function getEmailTempUrl() {
  return `${dataStorage.baseUrl}/user/email-template`;
}

export function getAuthUrl() {
  return `${dataStorage.baseUrl}/auth`;
}

export function getRefreshUrl() {
  return `${dataStorage.baseUrl}/auth/refresh`;
}

export function getDecodeUrl() {
  return `${dataStorage.baseUrl}/auth/decode`;
}

export function getOpeningAccountUrl(path) {
  return `${dataStorage.baseUrl}/user/account-opening${path || ""}`;
}

export function getSendOTPUrl(email) {
  return `${dataStorage.baseUrl}/user/account-opening/otp?user_login_id=${email}`;
}

export function verifyOTP() {
  return `${dataStorage.baseUrl}/user/account-opening/authorise`;
}
export function verifyOTPApplicantOther(idApplicant) {
  return `${dataStorage.baseUrl}/user/account-opening/authorise?applicant_id=${idApplicant}`;
}
export function getSubmitPrimaryApplicantUrl() {
  return `${dataStorage.baseUrl}/user/account-opening/joint-primary-applicant`;
}

export function getSubmitOtherApplicantUrl(EquixId = "") {
  return `${dataStorage.baseUrl}/user/account-opening/joint-other-applicant/${EquixId}`;
}

export function getKycByOperatorUrl(EquixId = "") {
  return `${dataStorage.baseUrl}/user/account-opening/joint/${EquixId}`;
}

export function getDeleteNotificationUrl(notification_id) {
  return `${dataStorage.baseUrl}/dynamic-push/notifications/${notification_id}`;
}

export function getUserDetailUrl(email) {
  return `${dataStorage.baseUrl}/user/user-details?user_login_id=${email}`;
}
export function getForceChangePassUrl(idAccount) {
  return `${dataStorage.baseUrl}/user/user-details/${idAccount}`;
}

export function getSSEUrl(token) {
  return `${dataStorage.baseUrlSSE}/noti/notification/streaming-data/operation/operation?handshake-interval=3000&retry=3000&lastEventId=12345&access_token=${token}`;
}
export function getSearchUserUrl(page_id = 1, page_size = 50) {
  return `${dataStorage.baseUrl}/search/user?page_id=${page_id}&page_size=${page_size}`;
}
export function getSearchSymbolUrl(symbol) {
  return `${dataStorage.baseUrl}/market-info/symbol/company_name?class=equity,future,etf,mf,warrant,option,forex&status=active&symbol=${symbol}&top=30`;
}
export function getSearchSegmentUrl(text) {
  return `${dataStorage.baseUrl}/dynamic-push/segment/query?search=${text}`;
}
export function getSendSegmentUrl(segment_id) {
  return `${dataStorage.baseUrl}/dynamic-push/segment/${segment_id}/send`;
}
export function getUpdateNotificationUrl(noti_id) {
  return `${dataStorage.baseUrl}/dynamic-push/notifications/${noti_id}`;
}
export function getPushDirectUrl() {
  return `${dataStorage.baseUrl}/dynamic-push/direct/send`;
}
export function getPushIcontUrl() {
  return `${dataStorage.baseUrl}/dynamic-push/upload/icon`;
}
export function getPushLargeImagetUrl() {
  return `${dataStorage.baseUrl}/dynamic-push/upload/large-image`;
}
export function getLogUrl() {
  return `${dataStorage.baseUrl}/log/data`;
}
export function getNotificationUrl({ pageId, pageSize, search, sort = {} }) {
  return `${
    dataStorage.baseUrl
  }/dynamic-push/notifications/query?page_id=${pageId}&page_size=${pageSize}${
    search ? "&search=" + search : ""
  }${sort.sortField ? "&sort_field=" + sort.sortField : ""}${
    sort.sortType ? "&sort_type=" + sort.sortType : ""
  }`;
}
export function getSearchSubscriberUrl(
  pageId,
  pageSize,
  search,
  status = "Active"
) {
  return `${
    dataStorage.baseUrl
  }/subscriber/subscribers/query?page_id=${pageId}&page_size=${pageSize}${
    search ? "&search=" + search : ""
  }&status=${status}`;
}
export function getSubscriberUrl({ pageId, pageSize, search, sort = {} }) {
  return `${
    dataStorage.baseUrl
  }/subscriber/subscribers/query?page_id=${pageId}&page_size=${pageSize}${
    search ? "&search=" + search : ""
  }${sort.sortField ? "&sort_field=" + sort.sortField : ""}${
    sort.sortType ? "&sort_type=" + sort.sortType : ""
  }`;
}
export function getSearchCodeUrl(codeType, search = "") {
  let type = "";
  switch (codeType) {
    case 0:
      type = "organisation";
      break;
    case 1:
      type = "branch";
      break;
    case 2:
      type = "advisor";
      break;
  }
  return `${dataStorage.baseUrl}/user/account/code?type=${type}&filter=${search}`;
}
export function getUrlDataBusinessLog(
  isOperation,
  userId,
  accountId,
  pageId,
  pageSize,
  filterText,
  duration,
  rangeTime
) {
  let url = `${dataStorage.baseUrl}/business-log/inquery?`;
  if (userId) url += `user_id=${userId}`;
  if (pageId) url += `&page_id=${pageId}`;
  if (pageSize) url += `&page_size=${pageSize}`;
  if (filterText) url += `&filter=${filterText}`;
  if (duration === "All") url += `&duration=all`;
  else {
    if (rangeTime) url += `&from=${rangeTime.fromDate}&to=${rangeTime.toDate}`;
  }
  if (url.indexOf("?&") >= 0) {
    url = url.replace("?&", "?");
  }
  return url;
}

export function getPushNotificationUrl() {
  return `${dataStorage.baseUrl}/dynamic-push/direct/send`;
}

export function getSegmentUrl({ pageId, pageSize, search, sort = {} }) {
  return `${
    dataStorage.baseUrl
  }/dynamic-push/segment/query?page_id=${pageId}&page_size=${pageSize}${
    search ? "&search=" + search : ""
  }${sort.sortField ? "&sort_field=" + sort.sortField : ""}${
    sort.sortType ? "&sort_type=" + sort.sortType : ""
  }`;
}

export function getAllSegmentUrl() {
  return `${dataStorage.baseUrl}/dynamic-push/segment/list`;
}

export function getSegmentDetailUrl(segment_id) {
  return `${dataStorage.baseUrl}/dynamic-push/segment/get/${segment_id}`;
}

export function getDeleteSegmentUrl(segment_id) {
  return `${dataStorage.baseUrl}/dynamic-push/segment/${segment_id}`;
}

export function getCreateNewSegmentUrl() {
  return `${dataStorage.baseUrl}/dynamic-push/segment/create`;
}

export function getUpdateSegmentUrl(segment_id) {
  return `${dataStorage.baseUrl}/dynamic-push/segment/${segment_id}/update`;
}

export function postData(url, data, headers = {}) {
  return new Promise((resolve) => {
    Axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
        ...headers,
      },
    })
      .then((response) => {
        resolve([response.data, undefined]);
      })
      .catch((error) => {
        console.log(error);
        resolve([undefined, getErrorMessage(error)]);
      });
  });
}

export function getData(url) {
  return new Promise((resolve) => {
    Axios.get(url, {
      headers: {
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      },
    })
      .then((response) => {
        resolve([response.data, undefined]);
      })
      .catch((error) => {
        resolve([undefined, getErrorMessage(error)]);
      });
  });
}

export function deleteData(url) {
  return new Promise((resolve) => {
    Axios.delete(url, {
      headers: {
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      },
    })
      .then((response) => {
        resolve([response.data, undefined]);
      })
      .catch((error) => {
        resolve([undefined, getErrorMessage(error)]);
      });
  });
}

export function putData(url, data) {
  return new Promise((resolve) => {
    Axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      },
    })
      .then((response) => {
        resolve([response.data, undefined]);
      })
      .catch((error) => {
        resolve([undefined, getErrorMessage(error)]);
      });
  });
}
