import React from "react";
import Typography from "@mui/material/Typography";

export default function HeadText({ children, ...props }) {
  return (
    <Typography
      {...props}
      component="h1"
      variant="h6"
      style={{ textTransform: "uppercase", marginTop: 16 }}
    >
      {children}
    </Typography>
  );
}
