import React from "react";
import {
  TextField,
  CircularProgress,
  ClickAwayListener,
  Autocomplete,
} from "@mui/material";
import { at } from "lodash";
import { useField } from "formik";
import { getSearchSymbolUrl, getData } from "src/api/api";
import CheckIcon from "@mui/icons-material/Check";
import logger from "src/utils/logger";

export default function SearchSymbol(props) {
  const { label, readOnly, searchMultiple, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const { setValue: setValueForm } = helper;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const timeoutId = React.useRef(0);

  React.useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    };
  }, []);

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const getListSuggestUser = async (text = "") => {
    if (text?.length < 2) return;
    setLoading(true);
    const url = getSearchSymbolUrl(text?.toLowerCase());
    // const data = { query: { bool: { must: [{ query_string: { query: `*${text?.toLowerCase()}*` } }] } }, sort: [] }
    const [response, error] = await getData(url);
    // console.log(response)
    if (error) {
      setLoading(false);
      logger.error(error, "getListSuggestUser error: ");
    } else {
      setOptions(response || []);
      setLoading(false);
    }
  };

  function onChangeText(e) {
    if (!open && !readOnly) setOpen(true);
    const text = e.target.value || "";
    timeoutId.current && clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(() => getListSuggestUser(text), 500);
  }
  const checkExistSymbol = React.useCallback(
    (symbol) => {
      if (!symbol) return false;
      return Boolean(meta.value.find((e) => e.symbol === symbol));
    },
    [meta.value]
  );
  function _onChange(event, option, reason) {
    // console.log(option)
    if (reason === "selectOption") {
      if (searchMultiple) {
        const email = option[option?.length - 1]?.symbol;
        if (checkExistSymbol(email)) return;
        return setValueForm && setValueForm(option);
      }
      setValueForm && setValueForm(option);
    } else if (reason === "removeOption") {
      setValueForm && setValueForm(option);
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Autocomplete
          // className={classes.container}
          {...field}
          {...rest}
          multiple={searchMultiple}
          onChange={_onChange}
          disableClearable
          open={open}
          onOpen={() => {
            readOnly || setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          fullWidth
          filterOptions={(x) => x}
          getOptionLabel={(option) => option?.display_name || ""}
          renderOption={(props, option, { selected }) => {
            const { company_name, company, display_name } = option;
            return (
              <div
                {...props}
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "8px 16px",
                  cursor: "pointer",
                }}
              >
                <span>
                  {company_name || company} - {display_name}
                </span>
                {selected ? (
                  <CheckIcon style={{ fill: "green" }} />
                ) : (
                  <React.Fragment />
                )}
              </div>
            );
          }}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              {...rest}
              variant="outlined"
              error={touched && error && true}
              helperText={_renderHelperText()}
              onChange={onChangeText}
              label={label}
              type="search"
              fullWidth
              InputProps={{
                ...params.InputProps,
                readOnly: readOnly,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
    </ClickAwayListener>
  );
}
