import React from "react";
import { Grid, Typography } from "@mui/material";

export default function ChessInfo() {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h6"
          style={{ textTransform: "uppercase", marginTop: 16 }}
        >
          SETTLEMENT INFORMATION
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>
          CHESS Sponsorship
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {
            "CHESS Sponsored Accounts are allocated a Holder Identification Number (HIN). The HIN is a unique identifier that your shares are registered to, meaning that you own them directly. For your HIN on this new account, the sponsor will be Morrison Securities Pty Ltd ABN 50 001 430 342 AFSL No. 241737. For more information please refer to the Explanation of CHESS Sponsorship Agreement and the Morrison Securities Sponsorship Agreement in the Terms and Conditions on the last page of this application."
          }
        </Typography>
      </Grid>
    </React.Fragment>
  );
}
