/* eslint-disable no-debugger */
import React from "react";
import { Paper, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import TableActivities from "../component/TableActivities";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: theme.spacing(3),
    borderRadius: 10,
    // minHeight: '400px',
    padding: theme.spacing(0, 2, 2, 2),
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 800,
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(52 41 41 / 50%)",
      borderRadius: 10,
      // outline: '1px solid slategrey'
    },
    "@media screen and (max-width: 1700px)": {
      height: "92vh",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  buttons: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 8,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 56,
    alignItems: "center",
    position: "fixed",
    width: "calc(86vw - 56px)",
    background: theme.palette.background.paper,
    zIndex: 9,
    borderRadius: 10,
  },
  styleButtonClose: {
    background: "#B75D69",
    "&:hover": {
      background: "#9D4358",
    },
  },
}));
const Activities = (props) => {
  const classes = useStyles();
  const renderHeader = () => {
    return (
      <div className={classes.headerContainer}>
        <IconButton
          onClick={props.handleModalToggle}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="top" component="h1" variant="h6" align="center">
          ACTIVITIES
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Paper className={classes.paper} elevation={15}>
        {renderHeader()}
        <div style={{ padding: 10 }}></div>
        <TableActivities infoAccount={props.infoAccount} />
      </Paper>
    </>
  );
};

export default Activities;
