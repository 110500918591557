import React from "react";
import { makeStyles, styled } from "@mui/styles";
import { useFormikContext, useField } from "formik";
import { IconButton, Button, Modal, Box, Slider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-easy-crop";
import { aspectFile } from "src/constants";
import formModels from "src/components/NewNotification/formModels";
import { generateDownload } from "src/utils/functionUtils";
const useStyles = makeStyles(() => ({
  box: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  ImageBox: {
    borderRadius: 5,
    maxWidth: 150,
    maxHeight: 150,
  },
  styleDelete: {
    position: "absolute",
    top: "-9px",
    right: "-11px",
    "& svg": {
      width: 20,
      height: 20,
      // paddingBottom: 8,
      color: "white",
      background: "black",
      borderRadius: "50%",
      padding: 2,
    },
  },
  styleSlider: {
    position: "absolute",
    bottom: 50,
    /* margin: auto; */
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%",
  },
  styleButton: {
    position: "absolute",
    bottom: 10,
    /* margin: auto; */
    // left: '50%',
    transform: "translateX(-50%)",
    right: -4,
    // width: '90%'
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 1,
    color: "white",
  },
}));
const Input = styled("input")({
  display: "none",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: "90vh",
  width: "100%",
  maxWidth: 775,
};
const UploadField = ({ ...props }) => {
  const classes = useStyles();
  const { name, ...rest } = props;
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  // const image = React.useRef()
  const [open, setopen] = React.useState(false);

  const [displayImage, setImage] = React.useState("");
  const [crop, setcrop] = React.useState({ x: 0, y: 0 });
  const [zoomImage, setZoomImage] = React.useState(1);
  const [getCompleteCrop, setGetCompleteCrop] = React.useState({});
  const getCurrentFile = React.useRef(null);

  const onChange = async (event) => {
    const getValueField = event.target.files[0];
    const renderFile = new FileReader();
    renderFile.readAsDataURL(getValueField);
    renderFile.addEventListener("load", (res) => {
      setImage(res.currentTarget.result);
      setFieldValue(name, getValueField);
      getCurrentFile.current = getValueField;
      openModal();
    });
  };
  const deleteClearImage = () => {
    props.uploadReset.current.value = "";
    setFieldValue(name, "");
    setImage("");
  };
  const openModal = () => setopen(true);
  const getCompleteCropImage = React.useCallback(
    (croppedArea, croppedAreaPixels) => {
      setGetCompleteCrop(croppedAreaPixels);
    },
    []
  );
  const getFileCropped = (FileCropped) => {
    const renderFile = new FileReader();
    renderFile.readAsDataURL(FileCropped);
    renderFile.addEventListener("load", (res) => {
      try {
        setImage(res.currentTarget.result);
        const blob = FileCropped.slice(0, FileCropped.size, FileCropped.type);
        const newFile = new File([blob], getCurrentFile.current.name, {
          type: FileCropped.type,
        });
        setFieldValue(name, newFile);
        setopen(false);
      } catch (err) {
        console.log(err);
      }
    });
  };
  const acceptChange = async () => {
    generateDownload(
      displayImage || meta.value,
      getCompleteCrop,
      getFileCropped,
      props.aspectImage
    );
  };
  const handleModalToggle = () => {
    setopen((old) => !old);
  };
  return (
    <>
      <div className={classes.box}>
        {/* <p style={{ textTransform: 'capitalize' }}>{rest?.label || ''}</p> */}
        <label
          htmlFor={name}
          style={props.spaceBox ? { marginBottom: 10 } : {}}
        >
          <Input
            accept="image/*"
            name={"icon"}
            // {...field}
            ref={props.uploadReset}
            onChange={onChange}
            id={name}
            type="file"
          />
          <Button
            color="primary"
            aria-label="upload picture"
            component="span"
            variant="outlined"
            style={{ textTransform: "capitalize" }}
          >
            {rest?.label}
          </Button>
          {/* <IconButton size="small" className={classes.styleDelete} onClick={deleteClearImage}>x</IconButton> */}
          {/* <Button color="primary" aria-label="upload picture" component="span" variant='outlined' style={{ textTransform: 'capitalize' }}>x</Button> */}
        </label>
        <div style={{ position: "relative", marginRight: 8 }}>
          {field.value ? (
            <IconButton
              size="small"
              className={classes.styleDelete}
              onClick={deleteClearImage}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          {props.createFile ? (
            <>
              {field.value ? (
                <img
                  src={displayImage}
                  className={classes.ImageBox}
                  style={
                    formModels.formField.icon.name === name
                      ? { maxWidth: 75 }
                      : {}
                  }
                  alt=""
                />
              ) : null}
            </>
          ) : (
            <>
              {field.value ? (
                <img
                  src={displayImage || meta.value}
                  className={classes.ImageBox}
                  style={
                    formModels.formField.icon.name === name
                      ? { maxWidth: 75 }
                      : {}
                  }
                  alt=""
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setopen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Cropper
            image={displayImage || meta.value}
            crop={crop}
            zoom={zoomImage}
            aspect={props.aspectImage === aspectFile.Image ? 2 / 1 : 1 / 1}
            onCropChange={setcrop}
            onCropComplete={getCompleteCropImage}
            onZoomChange={setZoomImage}
          />
          <Slider
            size="small"
            step={0.001}
            value={zoomImage}
            min={1}
            max={3}
            onChange={(e, zoom) => setZoomImage(zoom)}
            valueLabelDisplay="auto"
            className={classes.styleSlider}
          />
          <Button
            className={classes.styleButton}
            variant="contained"
            onClick={acceptChange}
          >
            Accept
          </Button>
          <IconButton onClick={handleModalToggle} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default UploadField;
