/* eslint-disable multiline-ternary */
import React, { useState } from "react";
import { Paper, Button, Typography, CircularProgress } from "@mui/material";
import { Formik, Form } from "formik";
import ApplicationDetails from "./Forms/ApplicationDetails";
import AdditionalInformation from "./Forms/AdditionalInformation";
import ReviewApplication from "./Forms/ReviewApplication";
import ApplicationResult from "./Forms/ApplicationResult";
import ApplicationDeleted from "./Forms/ApplicationDeleted";
import Steps from "src/components/Steps";
import ApplicantButton from "src/components/ApplicantButton";
import validationSchema from "./FormModels/formValidation";
import formModel from "./FormModels/formModels";
import initialValues from "./FormModels/formInitial";
import useStyles from "../styles";
import dataStorage from "src/dataStorage";
import logger from "src/utils/logger";
import {
  saveDraft,
  clone,
  getMobilePhoneValue,
  getAddressData,
  mapDataAddress,
  mapManualAddress,
  checkSubmitted,
  getEnv,
  checkKycVerify,
  isOtherApplicant,
  isMorrison,
  scrollToTop,
  clearTrashFieldAndMapData,
  renderFullname,
  formatDate,
} from "src/utils/functionUtils";
import {
  putData,
  postData,
  getOpeningAccountUrl,
  getSubmitOtherApplicantUrl,
  getSubmitPrimaryApplicantUrl,
} from "src/api/api";
import {
  BANK_ACCOUNT_TYPE,
  CMT_PROVIDER,
  TRANSACTION_TYPE,
  GOVERNMENT_ID_TYPE,
  CMA,
  OCCUPATION_TYPE,
  SETTLEMENT_METHOD,
  MEDICARE_CARD_COLOUR,
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_DISPLAY,
  DEFAULT_APPLICANT,
  EKYC_GOVID_STATUS,
  SOURCE_OF_WEALTH,
} from "src/constants";
import { v4 as uuidv4 } from "uuid";
import AdminActions from "src/components/AdminActions";
import FocusError from "src/components/FocusError";

const { formId, formField } = formModel;

const MIN_APPLICANT = 2;
const MAX_APPLICANT = 3;

export default function Joint(props) {
  const { data = {} } = dataStorage.dicDraft;
  let { formData = {}, step = 0, indexApplicant = 0 } = data;

  const getSteps = (values) => {
    if (isOtherApplicant()) return ["Application Details", "Review"]; // ['Application 1', 'Additional Information', 'Review'];
    const { applicant_details: applicantDetails } = values;
    const listStep = [];
    if (applicantDetails) {
      applicantDetails.forEach((e, i) => {
        const name =
          e.first_name && e.last_name
            ? renderFullname(applicantDetails[i])
            : "";
        listStep.push(name || `Applicant ${i + 1}`);
      });
    } else {
      listStep.push("Applicant 1", "Applicant 2");
    }
    listStep.push("Additional Information", "Review");
    return listStep;
  };
  const [steps, setSteps] = React.useState(() => {
    const initialSteps = getSteps(formData);
    return initialSteps;
  });
  if (
    dataStorage.accountStatus &&
    dataStorage.accountStatus !== ACCOUNT_STATUS.IN_KYC
  )
    step = steps.length - 1;
  if (indexApplicant) dataStorage.indexApplicant = indexApplicant;
  if (formData.equixId)
    dataStorage.equixId = formData.equix_id || formData.equixId;
  const classes = useStyles();
  const refNext = React.useRef(null);
  const listStepCanPress = React.useRef({
    0: true,
    1: step > 0,
    2: step > 1,
    3: step > 2,
  });
  const [activeStep, setActiveStep] = useState(step);
  const isLastStep = activeStep === steps.length - 1;
  const initialData = React.useRef({ ...clone(initialValues), ...formData });
  if (!dataStorage.equixId && dataStorage.registerEmail) {
    initialData.current.applicant_details[0].applicant_email =
      dataStorage.registerEmail;
  }

  function _renderStepContent() {
    if (isOtherApplicant()) {
      switch (activeStep) {
        case 0:
          return <ApplicationDetails index={dataStorage.indexApplicant} />;
        case 1:
          return <ReviewApplication />;
        default:
          return <div>Not Found</div>;
      }
    } else {
      const screen = steps[activeStep];
      switch (screen) {
        case "Additional Information":
          return <AdditionalInformation />;
        case "Review":
          return <ReviewApplication />;
        default:
          return <ApplicationDetails index={activeStep} />;
      }
    }
  }

  async function _submitForm(values, actions) {
    if (isOtherApplicant()) {
      const url = getOpeningAccountUrl(
        `/joint/agreement/${dataStorage?.equixId}`
      );
      const applicant = values.applicant_details[dataStorage.activeIndex];
      // eslint-disable-next-line camelcase
      const {
        client_address,
        applicant_id,
        total_confirm,
        macquarie_confirm,
        quant_edge_privacy_statement_confirm,
        morrison_confirm,
        terms_confirm,
      } = applicant;
      const checkTerms = isMorrison()
        ? terms_confirm
        : terms_confirm && morrison_confirm; // eslint-disable-line
      // eslint-disable-next-line camelcase
      if (
        client_address &&
        total_confirm &&
        macquarie_confirm &&
        quant_edge_privacy_statement_confirm &&
        checkTerms
      ) {
        const params = {
          applicant_id,
          applicant_agreement: true,
        };
        const [response, err] = await postData(url, params);
        if (err) {
          dataStorage.showAlert?.({ message: err, type: "error" });
          actions.setSubmitting(false);
        } else {
          window.onbeforeunload = null; // remove popup close tab
          dataStorage.applicantInfo = response || { ...values };
          response?.all_application_submitted &&
            (dataStorage.all_application_submitted =
              response?.all_application_submitted);
          actions.setSubmitting(false);
          let id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
          values.applicant_details[
            dataStorage.indexApplicant
          ].applicant_agreement = true;
          saveDraft({
            formData: {
              ...values,
              equix_id: dataStorage.equixId,
              submit_time: +new Date(),
              tos_ip: window.ipPublic,
              tos_user_agent: navigator.userAgent,
            },
            step: activeStep,
            id,
            indexApplicant: dataStorage.indexApplicant,
            index: dataStorage.activeIndex,
          });
          setActiveStep(activeStep + 1);
        }
      }
      return;
    }
    const obj = clone(values);
    clearTrashFieldAndMapData(obj, "", values, formField);

    // get user agent info for compliance
    obj.tos_ip = window.ipPublic;
    obj.tos_user_agent = navigator.userAgent;

    obj.trade_confirmations.length = obj.applicant_details.length;
    const listAddressId = obj.applicant_details.reduce((acc, cur) => {
      if (
        !cur.residential_address_manual_address &&
        cur.residential_address_full_address?.id
      ) {
        acc.push(cur.residential_address_full_address?.id);
      }
      if (
        cur.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER &&
        cur.business_owner_trading_address_full_address?.id
      ) {
        acc.push(cur.business_owner_trading_address_full_address?.id);
      }
      return acc;
    }, []);
    if (listAddressId.length) {
      await getAddressData(listAddressId);
    }
    const otherData = [];
    obj.applicant_details.forEach((e, i) => {
      if (!e.applicant_id) e.applicant_id = uuidv4();
      // e.applicant_id = uuidv4()
      // trade_confirmations
      e.applicant_email = e.applicant_email?.trim();
      e.subclass_visa =
        e.source_of_wealth?.value !== SOURCE_OF_WEALTH.FOREIGN_INCOME
          ? false
          : !!e.subclass_visa;
      if (!obj.trade_confirmations[i]) obj.trade_confirmations[i] = {};
      obj.trade_confirmations[i].method = "EMAIL";
      obj.trade_confirmations[i].email = e?.applicant_email;
      obj.trade_confirmations[i].client_address = true;
      delete e.client_address;
      delete e.ekyc_aml_consent;
      delete e.total_confirm;
      delete e.morrison_confirm;
      delete e.terms_confirm;
      delete e.quant_edge_privacy_statement_confirm;
      delete e.macquarie_confirm;
      delete e.financial_services_guide_confirm;
      delete e.verification_id;
      delete e.ekyc_overall_status;
      delete e.tax_identification;
      delete e.is_trust_beneficial_owner;
      delete e.is_trust_beneficiary;

      if (!i) {
        dataStorage.applicantId = e.applicant_id;
        if (e.government_id.type === GOVERNMENT_ID_TYPE.MEDICARE_CARD) {
          e.government_id.medicare_name_on_card = e.middle_name
            ? e.first_name + " " + e.middle_name + " " + e.last_name
            : e.first_name + " " + e.last_name;
          const expireDate = e.government_id.medicare_card_expiry_date;
          if (
            e.government_id.medicare_card_colour === MEDICARE_CARD_COLOUR.GREEN
          ) {
            e.government_id.medicare_card_expiry_date = formatDate(
              expireDate,
              "MM/YYYY"
            );
          } else {
            e.government_id.medicare_card_expiry_date = formatDate(
              expireDate,
              "DD/MM/YY"
            );
          }
        } else {
          e.government_id.first_name = e.first_name;
          e.government_id.last_name = e.last_name;
          e.government_id.middle_name = e.middle_name;
        }
        e.government_id = [e.government_id];
      } else {
        otherData.push(values.applicant_details[i]);
        delete e.government_id;
      }

      // handle address
      if (e.residential_address_manual_address) {
        mapManualAddress(e, e, "residential_address");
        mapManualAddress(e, e, "postal_address", "residential_address");
      } else {
        const addressId = e.residential_address_full_address?.id;
        mapDataAddress(e, addressId, "residential_address");
        mapDataAddress(e, addressId, "postal_address");
      }
      if (e.occupation_type === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
        mapDataAddress(
          e,
          e.business_owner_trading_address_full_address?.id,
          "business_owner_trading_address"
        );
      }
      delete e.residential_address_manual_address;
      e.same_as_ra = true;
      e.relationship_type = "OWNER";
      e.residential_address_country = "AUSTRALIA";
      e.country_of_birth = "AUSTRALIA";
      e.applicant_mobile_phone &&
        (e.applicant_mobile_phone = getMobilePhoneValue(
          e.applicant_mobile_phone,
          "04"
        ));
      e.dob = formatDate(e.dob);
    });

    // new cma account and settlement_method
    obj.new_cma = !obj.use_existing_CMT_acc;
    obj.settlement_method = obj.settlement_method
      ? SETTLEMENT_METHOD.SPONSORED_NEW_HIN
      : SETTLEMENT_METHOD.SPONSORED_HIN_TRANSFER;
    obj.settlement_existing_hin &&
      (obj.settlement_existing_hin = +obj.settlement_existing_hin);
    if (obj.new_cma) {
      obj.new_cma = CMA.CREATE_NEW;
      obj.bank_account_type = BANK_ACCOUNT_TYPE.BANK_ACCOUNT;
      obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
    } else {
      obj.new_cma = CMA.USE_EXISTING;
      obj.bank_account_type = BANK_ACCOUNT_TYPE.LINKED_CMT_CMA;
      obj.bank_cmt_provider = CMT_PROVIDER.MBLA;
    }
    obj.bank_transaction_type = TRANSACTION_TYPE.BOTH;

    // link draft id with submit object
    if (dataStorage.dicDraft.id || dataStorage.dicDraft.draft_id)
      obj.draft_id = dataStorage.dicDraft.id || dataStorage.dicDraft.draft_id;
    const url = getOpeningAccountUrl(`/joint/${dataStorage.equixId}`);
    delete obj.equix_id;
    delete obj.submit_time;
    delete obj.use_existing_CMT_acc;
    const [res, error] = await postData(url, obj);
    if (error) {
      logger.log(error, "submit opening account error");
      dataStorage.showAlert?.({ message: error, type: "error" });
      actions.setSubmitting(false);
    } else {
      window.onbeforeunload = null; // remove popup close tab
      dataStorage.applicantInfo = res || {
        ...dataStorage.applicantInfo,
        ...values,
      };
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
      let id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
      saveDraft({
        formData: {
          ...values,
          equix_id: dataStorage.equixId,
          submit_time: +new Date(),
          tos_ip: obj.tos_ip,
          tos_user_agent: obj.tos_user_agent,
        },
        step: activeStep,
        index: 0,
        indexApplicant: dataStorage.indexApplicant,
        id,
      });
    }
  }

  const onConfirm = async (values, actions) => {
    const index = isOtherApplicant() ? dataStorage.indexApplicant : activeStep;
    const data = clone(values);
    const { applicant_details: applicantDetails } = data;
    const obj = {
      applicant_id: applicantDetails[index]?.applicant_id || uuidv4(),
      ekyc_aml_consent: applicantDetails[index].ekyc_aml_consent,
      title: applicantDetails[index]?.title?.value,
      first_name: applicantDetails[index]?.first_name,
      middle_name: applicantDetails[index]?.middle_name,
      last_name: applicantDetails[index]?.last_name,
      gender: applicantDetails[index]?.gender?.value,
      nationality: applicantDetails[index]?.nationality?.value,
      occupation_type: applicantDetails[index]?.occupation_type?.value,
      occupation_category: applicantDetails[index]?.occupation_category?.value,
      source_of_wealth: applicantDetails[index]?.source_of_wealth?.value,
      australian_tax_resident: applicantDetails[index]?.australian_tax_resident,
    };
    if (
      applicantDetails[index]?.occupation_type?.value ===
      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
    ) {
      obj.business_owner_trading_name =
        applicantDetails[index]?.business_owner_trading_name;
      obj.abn_acn_registration_number =
        applicantDetails[index]?.abn_acn_registration_number;
    }
    if (applicantDetails[index]?.australian_tax_resident) {
      obj.tax_exemption = applicantDetails[index]?.tax_exemption;
      if (applicantDetails[index]?.tax_exemption) {
        obj.tax_exemption_details =
          applicantDetails[index]?.tax_exemption_details?.value;
      }
      if (applicantDetails[index]?.tfn) {
        obj.tfn = applicantDetails[index]?.tfn;
      }
    } else {
      obj.tax_exemption = false;
    }
    if (applicantDetails[index]?.dob)
      obj.dob = formatDate(applicantDetails[index].dob);
    const getTypeGoverment =
      applicantDetails[index]?.government_id?.type?.value;

    switch (getTypeGoverment) {
      case GOVERNMENT_ID_TYPE.DRIVER_LICENSE:
        obj.government_id = [
          {
            type: applicantDetails[index]?.government_id.type?.value,
            number: applicantDetails[index]?.government_id.number,
            state_of_issue:
              applicantDetails[index]?.government_id.state_of_issue?.value,
            first_name: applicantDetails[index]?.first_name,
            middle_name: applicantDetails[index]?.middle_name,
            last_name: applicantDetails[index]?.last_name,
          },
        ];
        break;
      case GOVERNMENT_ID_TYPE.PASSPORT:
        obj.government_id = [
          {
            type: applicantDetails[index]?.government_id.type?.value,
            number: applicantDetails[index]?.government_id.number,
            first_name: applicantDetails[index]?.first_name,
            middle_name: applicantDetails[index]?.middle_name,
            last_name: applicantDetails[index]?.last_name,
          },
        ];
        break;
      default:
        break;
    }
    obj.relationship_type = "OWNER";
    obj.residential_address_country = "AUSTRALIA";
    obj.country_of_birth = "AUSTRALIA";
    obj.tos_consent = true;
    // obj.dob = moment(obj.dob, moment.ISO_8601).format('DD/MM/YYYY')
    obj.same_as_ra = applicantDetails[index]?.same_as_ra;

    obj.applicant_email = applicantDetails[index]?.applicant_email?.trim();
    obj.applicant_mobile_phone = getMobilePhoneValue(
      applicantDetails[index]?.applicant_mobile_phone,
      "04"
    );

    // map address
    const listAddress = [];
    if (!applicantDetails[index]?.residential_address_manual_address) {
      listAddress.push(
        applicantDetails[index]?.residential_address_full_address?.id
      );
    }
    if (
      applicantDetails[index]?.occupation_type?.value ===
      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
    ) {
      listAddress.push(
        applicantDetails[index]?.business_owner_trading_address_full_address?.id
      );
    }
    if (listAddress.length) {
      await getAddressData(listAddress);
    }

    if (applicantDetails[index]?.residential_address_manual_address) {
      mapManualAddress(obj, applicantDetails[index], "residential_address");
      mapManualAddress(
        obj,
        applicantDetails[index],
        "postal_address",
        "residential_address"
      );
    } else if (applicantDetails[index]?.residential_address_full_address?.id) {
      const addressId =
        applicantDetails[index]?.residential_address_full_address?.id;
      mapDataAddress(obj, addressId, "residential_address");
      mapDataAddress(obj, addressId, "postal_address");
    }
    if (
      applicantDetails[index]?.occupation_type?.value ===
      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
    ) {
      mapDataAddress(
        obj,
        applicantDetails[index]?.business_owner_trading_address_full_address
          ?.id,
        "business_owner_trading_address"
      );
    }

    const verificationId = values.applicant_details[index]?.verification_id;
    let url = "";
    if (verificationId || dataStorage.isOperatorSupport) {
      url = getOpeningAccountUrl(`/joint/${dataStorage.equixId}`);
    } else {
      url =
        dataStorage.isSubApplicant || activeStep > 0
          ? getSubmitOtherApplicantUrl(dataStorage.equixId)
          : getSubmitPrimaryApplicantUrl();
    }
    if (!verificationId && !dataStorage.isOperatorSupport)
      obj.draft_id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
    const requestMethod =
      verificationId || dataStorage.isOperatorSupport ? putData : postData;
    const [response, error] = await requestMethod(url, obj);
    if (error) {
      actions.setSubmitting(false);
      if (
        error.ekyc_status &&
        error.ekyc_status[index] &&
        error.ekyc_status[index].message
      ) {
        dataStorage.showAlert?.({
          message: error.ekyc_status[index].message,
          type: "error",
        });
      } else {
        dataStorage.showAlert?.({ message: error, type: "error" });
      }
    } else {
      actions.setSubmitting(false);
      const {
        ekyc_govid_status: ekycGovidStatus,
        ekyc_overall_status: ekycOverallStatus,
        verification_id: verificationID,
      } = response.ekyc_status[0];
      verificationID &&
        (values.applicant_details[index].verification_id = verificationID);
      if (response.equix_id) {
        dataStorage.equixId = response.equix_id;
        values.equix_id = response.equix_id;
      }
      let id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
      saveDraft({
        formData: {
          ...values,
          equix_id: dataStorage.equixId,
        },
        step: activeStep,
        id,
      });
      if (checkKycVerify(ekycOverallStatus)) {
        values.applicant_details[index].ekyc_overall_status = ekycOverallStatus;
        return onNext(values, actions);
      }
      if (ekycGovidStatus === EKYC_GOVID_STATUS.EKYC_LOCKED_OUT) {
        return dataStorage.showAlert?.({
          message: `We’re unable to verify your details, please contact ${dataStorage.config.supportEmail} for support`,
          type: "error",
        });
      }
      if (!checkKycVerify(ekycOverallStatus)) {
        return dataStorage.showAlert?.({
          message:
            "Your KYC application was unsuccessful. Please double check your Applicant and Identification Details are accurate. Please try again or contact hello@equix.app for assistance.",
          type: "error",
        });
      }
    }
  };

  const onNext = (values, actions) => {
    scrollToTop();
    let id = dataStorage.dicDraft?.id || dataStorage.dicDraft?.draft_id;
    listStepCanPress.current[activeStep + 1] = true;
    saveDraft({
      formData: {
        ...values,
        equix_id: dataStorage.equixId,
      },
      step: activeStep + 1,
      index: dataStorage.activeIndex,
      indexApplicant: dataStorage.indexApplicant,
      id,
    });
    actions.setTouched({});
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  };

  const onStepClick = (actions) => (index) => {
    if (index < activeStep) {
      if (listStepCanPress.current[index]) {
        setActiveStep(index);
      } else _handleBack();
    } else if (index > activeStep) {
      actions.validateForm().then((errors) => {
        if (errors && Object.keys(errors).length) {
          actions.setTouched(errors);
        } else {
          if (listStepCanPress.current[index]) {
            setActiveStep(index);
          } else {
            refNext.current && refNext.current.click();
          }
        }
      });
    }
  };

  function _handleSubmit(values, actions) {
    const index = isOtherApplicant() ? dataStorage.indexApplicant : activeStep;
    const step = steps[activeStep];
    const isApplicantDetails = !["Additional Information", "Review"].includes(
      step
    );
    if (isLastStep) {
      _submitForm(values, actions);
    } else if (
      isApplicantDetails &&
      !checkKycVerify(values.applicant_details[index].ekyc_overall_status)
    ) {
      onConfirm(values, actions);
    } else {
      onNext(values, actions);
    }
  }

  function _backChooseDraft() {
    dataStorage.equixId = null;
    dataStorage.isSubApplicant = false;
    dataStorage.indexApplicant = 0;
    dataStorage.accountStatus = "";
    props.backChooseDraft && props.backChooseDraft();
  }

  function _handleBack() {
    if (activeStep === 0) {
      props.backChooseAccountType && props.backChooseAccountType();
    } else {
      scrollToTop();
      setActiveStep(activeStep - 1);
    }
  }

  const checkCanSubmit = (values) => {
    let check = true;
    const element = values.applicant_details[0];
    const checkTerms = isMorrison()
      ? !element?.terms_confirm
      : !element?.terms_confirm || !element?.morrison_confirm;
    if (
      !element?.client_address ||
      !element?.total_confirm ||
      !element?.macquarie_confirm ||
      !element?.quant_edge_privacy_statement_confirm ||
      checkTerms
    ) {
      check = false;
    }
    return !isLastStep || check;
  };

  const renderButtons = (isSubmitting, values, actions) => {
    const index = isOtherApplicant() ? dataStorage.indexApplicant : activeStep;
    const step = steps[activeStep];
    const isApplicantDetails = !["Additional Information", "Review"].includes(
      step
    );
    const checkCondition =
      isApplicantDetails &&
      !checkKycVerify(values.applicant_details?.[index]?.ekyc_overall_status);
    const accountType = dataStorage.accountType;
    if (!accountType) return <React.Fragment />;
    if (checkSubmitted()) {
      return (
        <div className={classes.buttons}>
          <div className={classes.leftButtons}></div>
          <div className={classes.rightButtons}>
            <AdminActions />
          </div>
        </div>
      );
    }
    const isCheckAML = values.applicant_details[index]?.ekyc_aml_consent;
    const isAccepted = checkCondition ? isCheckAML : checkCanSubmit(values);
    const textSubmit = isLastStep
      ? "Submit Application"
      : checkCondition
      ? "confirm"
      : "next";
    return (
      <div className={classes.buttons}>
        {/* Back to Draft Button is on left handside */}
        <div className={classes.leftButtons}>
          {/* add applicants */}
          {renderApplicantButton(values, actions.setTouched)}
        </div>
        <div className={classes.rightButtons}>
          <AdminActions />
          {accountType && (
            <div className={classes.wrapper}>
              <Button
                onClick={() => _handleBack(actions.setFieldValue)}
                className={classes.button}
              >
                Back
              </Button>
            </div>
          )}
          <div className={classes.wrapper}>
            <Button
              ref={refNext}
              disabled={isSubmitting || !isAccepted}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {textSubmit}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const addApplicant = (values) => () => {
    values.applicant_details.push({
      ...DEFAULT_APPLICANT,
      applicant_id: uuidv4(),
    });
    const newSteps = getSteps(values);
    setSteps(newSteps);
  };

  const removeApplicant = (values, setTouched) => () => {
    values.applicant_details.splice(activeStep, 1);
    listStepCanPress.current = Object.keys(listStepCanPress.current).reduce(
      (acc, cur) => {
        if (cur < activeStep) {
          acc[cur] = listStepCanPress.current[cur];
        } else if (cur > activeStep) {
          acc[cur - 1] = listStepCanPress.current[cur];
        }
        return acc;
      },
      {}
    );
    if (activeStep === 0) {
      if (!dataStorage.equixId && dataStorage.registerEmail) {
        values.applicant_details[0].applicant_email = dataStorage.registerEmail;
      }
    }
    setTouched({});
    const newSteps = getSteps(values);
    setSteps(newSteps);
  };

  const renderApplicantButton = (values, setTouched) => {
    if (isOtherApplicant()) return <React.Fragment />;
    const screen = steps[activeStep];
    const isNotAllowAddApplicant =
      ["Additional Information", "Review"].includes(screen) ||
      values.applicant_details.length >= MAX_APPLICANT;
    const isNotAllowRemoveApplicant =
      ["Additional Information", "Review"].includes(screen) ||
      values.applicant_details.length <= MIN_APPLICANT ||
      activeStep === 0;
    return (
      <ApplicantButton
        isNotAllowAddApplicant={isNotAllowAddApplicant}
        isNotAllowRemoveApplicant={isNotAllowRemoveApplicant}
        addApplicant={addApplicant(values)}
        removeApplicant={removeApplicant(values, setTouched)}
      />
    );
  };

  const getValidationSchema = () => {
    const screen = steps[activeStep];
    switch (screen) {
      case "Review":
      case "Additional Information":
        return validationSchema(activeStep)[screen];
      default:
        if (isOtherApplicant())
          return validationSchema(dataStorage.indexApplicant)[
            "Applicant Details"
          ];
        return validationSchema(activeStep)["Applicant Details"];
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={15}>
        {activeStep === -1 ? (
          <ApplicationDeleted backChooseDraft={_backChooseDraft} />
        ) : activeStep === steps.length ? (
          <ApplicationResult backChooseDraft={_backChooseDraft} />
        ) : (
          <React.Fragment>
            <Typography
              id="topJoint"
              component="h1"
              variant="h6"
              align="center"
            >
              {dataStorage.accountStatus
                ? ACCOUNT_STATUS_DISPLAY[dataStorage.accountStatus] ||
                  dataStorage.accountStatus
                : "NEW TRADING ACCOUNT"}
            </Typography>
            {/* navigation */}
            <Formik
              initialValues={initialData.current}
              validationSchema={getValidationSchema()}
              validateOnBlur={true}
              validateOnChange={false}
              onSubmit={_handleSubmit}
            >
              {({
                isSubmitting,
                setFieldValue,
                values,
                setTouched,
                submitForm,
                setSubmitting,
                validateForm,
              }) => (
                <div className={classes.container}>
                  <Steps
                    steps={getSteps(values)}
                    activeStep={activeStep}
                    listStepCanPress={listStepCanPress.current}
                    onStepClick={onStepClick({ validateForm, setTouched })}
                  />
                  <div className={classes.formContainer}>
                    <FocusError>
                      <Form id={formId} autoComplete="off" noValidate>
                        {_renderStepContent(values)}
                        {renderButtons(isSubmitting, values, {
                          setFieldValue,
                          submitForm,
                          setSubmitting,
                          validateForm,
                        })}
                      </Form>
                    </FocusError>
                  </div>
                </div>
              )}
            </Formik>
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
}
