const ERROR_MAPPING = {
  OK: "(#20001) Application Submitted.",
  INVALID_ARGUMENT:
    "(#10032) Unfortunately, we cannot process your request right now. Please try again later.",
  FAILED_PRECONDITION:
    "(#10001) Unfortunately, we cannot process your request right now. Please try again later.",
  OUT_OF_RANGE:
    "(#10002) Unfortunately, we cannot process your request right now. Please try again later.",
  UNAUTHENTICATED:
    "(#10003) Your session is expired. Please refresh the screen and continue with your application.",
  PERMISSION_DENIED:
    "(#10004) Unfortunately, we cannot process your request right now. Please try again later.",
  NOT_FOUND:
    "(#10005) Unfortunately, we cannot process your request right now. Please try again later.",
  ABORTED:
    "(#10006) Unfortunately, we cannot process your request right now. Please try again later.",
  ALREADY_EXISTS:
    "(#10007) Unfortunately, we cannot process your request right now. Please try again later.",
  RESOURCE_EXHAUSTED:
    "(#10008) Unfortunately, we cannot process your request right now. Please try again later.",
  CANCELLED:
    "(#10009) Unfortunately, we cannot process your request right now. Please try again later.",
  DATA_LOSS:
    "(#10010) Unfortunately, we cannot process your request right now. Please try again later.",
  UNKNOWN:
    "(#10011) Unfortunately, we cannot process your request right now. Please try again later.",
  INTERNAL:
    "(#10012) Unfortunately, we cannot process your request right now. Please try again later.",
  NOT_IMPLEMENTED:
    "(#10013) Unfortunately, we cannot process your request right now. Please try again later.",
  "N/A":
    "(#10014) Unfortunately, we cannot process your request right now. Please try again later.",
  UNAVAILABLE:
    "(#10015) Unfortunately, we cannot process your request right now. Please try again later.",
  DEADLINE_EXCEEDED:
    "(#10016) Unfortunately, we cannot process your request right now. Please try again later.",
  2032: "Your username has been temporarily blocked for security reason. Please contact Equix Support.",
  2062: "Incorrect Password or User Login. Please make sure your details are correct and try again",
  3001: "It looks like you are accessing the wrong environment. Questions or confusions? Email hello@equix.app, and a team member of us will be happy to help.",
  OTP_EXPIRED:
    "(#10018) This email verification code is expired. Please request a new code and try again.",
  INVALID_OTP: "(#10021) Incorrect email verification code. Please try again.",
  USED_OTP:
    "(#10020) This email verification code has been used. Please request a new code and try again.",
  OTP_UNAVAILABLE:
    "(#10017) This email already has Advisor access. Please use a different email for onboarding service.",
  INVALID_ENVIRONMENT:
    "(#10022) Unfortunately, we cannot process your request right now. Please try again.",
  INVALID_PASSWORD: "(#10023) Incorrect email or password. Please try gain.",
  ALREADY_APPROVED_KYC:
    "(#10024) This application KYC has been already approved.",
  ALREADY_REJECTED: "(#10025) This application has been already rejected.",
  INVALID_APPLICANT:
    "(#10027) Unfortunately, we cannot process your request right now. Please try again later.",
  INVALID_APPLICATION:
    "(#10028) Unfortunately, we cannot process your request right now. Please try again later.",
  INVALID_APPLICATION_STATUS:
    "(#10029) Unfortunately, we cannot process your request right now. Please try again later.",
  INVALID_KYC_STATUS:
    "(#10030) Unfortunately, we cannot process your request right now. Please try again later.",
  ALREADY_APPROVED_BANK:
    "(#10026) This application Bank Info has been already approved.",
  INVALID_TOKEN:
    "(#10031) Incorrect email verification code. Please try again.",
  MISSING_BENEFICIARY:
    "(#10033) The Application should have at least one beneficiary.",
  MISSING_BENEFICIAL_OWNER:
    "(#10034) The Application should have at least one beneficial owner.",
  USER_ALREADY_EXISTS:
    "(#10035) This email is already used by another account.",
  INVALID_ROLE_APPLICANT:
    "(#10036) Please select that there is at least one 'Director' if there is one or more company officers.",
  INVALID_CONTACT_NUMBER_PHONE_COMPANY:
    "(#10037) Provide at least one contact phone number",
  ERROR_AREA_CODE_MOBILE_PHONE:
    '(#10038) Company mobile phone must be started by "04"',
  UnknownError: "Unknown Error",
  RateLimit: "API rate limit exceeded",
  2001: "Full name is invalid",
  1900: "Daily net trading limit exceeded",
  1706: "You cannot Modify the order at Triggered status",
  1708: "Trading in this security is restricted. Please contact Equix Support for further details.",
  1707: "You cannot Modify or Cancel child order",
  2002: "This user login is already taken. Please try another one.",
  2003: "Your user login must use 3-255 characters and cannot contain spaces.",
  2004: "Invalid email address. Please try again",
  2005: "Invalid phone number format. Please enter the phone in the form of (xxx) xxx-xxx-xxx",
  2006: "Invalid permission",
  8000: "Failed to create an account",
  2007: "User group is invalid",
  2008: "Invalid user status. Please try again.",
  2009: "This account is taken. Try another",
  2010: "Your password must include 8-25 characters and contain at least one lowercase, one capital letter and one number.",
  2011: "Incorrect Password",
  2012: "Username is invalid",
  2014: "Invalid account ID. Please try another one.",
  2015: "Invalid status. Please try another one.",
  2016: "Unknown Error",
  2017: "No data change",
  2020: "Email login format is not recognized. Please check and try again.",
  2021: "Email contact format is not recognized. Please check and try again.",
  2024: "This role is existed. Please try another one.",
  2025: "This role is not existed. Please try another one.",
  2026: "There is no change in the role information",
  2027: "This role ID is not existed. Please try another one.",
  2028: "x",
  2029: "This role cannot be removed as there is user record in this role.",
  2030: "Role name must not exceed 255 characters.",
  2031: "Invalid user group ID. Please try another one.",
  2033: "Description must not exceed 255 characters",
  2035: "This user group ID cannot be removed.",
  2036: "User group is required for reference. Please try again.",
  2037: "Role name is required",
  2038: "",
  4000: "Only one account is mapped for a retail client",
  2013: "Your User ID is inactive",
  2022: "Your account is closed",
  2023: "Your username has been temporarily blocked for security reason. Please contact Equix Support.",
  2048: "Only one layout should be chosen",
  2049: "Layout is required",
  2080: "New password needs to be different from three latest ones",
  2090: "Password is required",
  2091: "Email template doesn't exist",
  2092: "ACCOUNT ID/ ADVISOR CODE/ BRANCH CODE/ ORGANISATION CODE doesn't exist",
  2094: "Live news is invalid",
  2095: "For access to add-on, please contact Equix Support.",
  2096: "You are not able to create this role.",
  2097: "User group does not exist.",
  2098: "You are not able to create this role.",
  2099: "Roles group is not existing",
  4001: "Invalid API Access. Please try another one.",
  3000: "This account is not assigned to trade with this symbol",
  1303: "User is in View Only Mode",
  2078: "Incorrect verification code. Please retry again",
  7000: "After market order placement is currently not available in Equix. Any instruction placed outside market hours will not be accepted. Please try again when the market is open (M-F 7:00am-4:10pm AEST).",
  1703: "You cannot modify the order at Pending Cancel status",
  1704: "You cannot Cancel the order at Pending Cancel status",
  1705: "You cannot Modify the order at Pending Replace status",
  7001: "External Order Placement is not available on EQUIX.",
  2100: "Alert type is invalid",
  2101: "Alert trigger is invalid",
  2102: "Target value is invalid",
  2103: "Target is invalid",
  2104: "Alert repeat is invalid",
  2105: "Method is invalid",
  2106: "Alert ID is invalid",
  4100: "We cannot process your order due to the exchange rate error. Please contact your brokers to investigate.",
  5000: "Symbol does not exist",
  5001: "The To date must be greater than the From date",
  9002: "invalid time",
  9004: "Product unavailable for trading.",
  1100: "Invalid symbol",
  1101: "Invalid side",
  1102: "Invalid quantity",
  1103: "Quantity must be greater than 0",
  1104: "Invalid order type",
  1105: "Invalid condition",
  1106: "Invalid limit price",
  1107: "Invalid stop price",
  1112: "Invalid duration",
  1113: "Invalid exchange",
  1200: "Not available to trade",
  1201: "Not available to trade",
  1300: "Account is not enabled to trade",
  1305: "Account is not enabled to trade",
  1306: "Account is not enabled to trade AU markets",
  1307: "Account is not enabled to trade US markets",
  1400: "Account is not enabled to trade US markets",
  1402: "Account is not enabled to trade AU markets",
  1403: "Account is not enabled to buy AU securities",
  1404: "Account is not enabled to buy US markets",
  1405: "Account is not enabled to buy US securities",
  1500: "Not available to trade",
  1501: "Order type is not supported",
  1502: "Order type is not supported",
  1503: "Order type is not supported",
  1504: "Duration is not supported",
  1505: "Duration is not supported",
  1506: "Order value too small",
  1507: "Order value too small",
  1508: "Order value too big",
  1509: "Order value too big",
  1510: "The sell order is greater than your available holdings",
  1511: "Not available to trade",
  1512: "Duration is not supported",
  1513: "Duration is not supported",
  1514: "Duration is not supported",
  1515: "Duration is not supported",
  1516: "Trading market is not supported",
  1517: "Trading market is not supported",
  1518: "The sell order is greater than your available holdings",
  1519: "Not available to trade",
  1520: "Order type is not supported",
  1521: "Order type is not supported",
  1522: "Order value too small",
  1523: "Order value too small",
  1524: "Order value too big",
  1525: "Order value too big",
  1526: "Stop price cannot be less than offer price",
  1527: "Stop price cannot be less than offer price",
  1528: "Stop price cannot be greater than limit price",
  1529: "Stop price cannot be greater than bid price",
  1530: "Stop price cannot be greater than bid price",
  1531: "Stop price cannot be less than limit price",
  1532: "Stop price cannot be less than offer price",
  1533: "Stop price cannot be less than offer price",
  1534: "Stop price cannot be greater than limit price",
  1535: "Stop price cannot be greater than bid price",
  1536: "Stop price cannot be greater than bid price",
  1537: "Stop price cannot be less than limit price",
  1538: "Exchange is not supported",
  1539: "Order type is not supported",
  1540: "Order type is not supported",
  1541: "Order type is not supported",
  1542: "Order type is not supported",
  1543: "Order type is not supported",
  1544: "Order type is not supported",
  1545: "Order type is not supported",
  1546: "Duration is not supported",
  1547: "Duration is not supported",
  1548: "Duration is not supported",
  1549: "Duration is not supported",
  1550: "Duration is not supported",
  1551: "Duration is not supported",
  1552: "Duration is not supported",
  1600: "Cannot get market price ",
  1601: "Insufficient cash to trade",
  1602: "Cannot get market price ",
  1603: "Insufficient cash to modify the order",
  1604: "Cannot get currency exchange rate",
  1605: "Cannot get market price ",
  1606: "Insufficient cash to trade",
  1607: "Failed to deposit",
  1608: "Cannot get currency exchange rate",
  1609: "Cannot get market price ",
  1610: "Insufficient cash to modify the order",
  1611: "Failed to deposit",
  1700: "Cannot be connected server",
  1701: "Invalid quantity",
  1702: "Invalid position",
  1711: "Duration is not supported",
  2000: "Sorry, an unknown error has occurred. Please contact EQUIX Support.",
  2050: "This branch name is already existed. Please try another one.",
  2052: "Invalid branch name. Please try another one.",
  2053: "Branch name must not exceed 255 characters.",
  2054: "Invalid branch name. Please try another one.",
  2055: "This branch cannot be removed as there is user ID in this branch.",
  2056: "This vetting rules group ID cannot delete.",
  2066: "Value of rule is invalid.",
  2061: "Please wait a minute and reset password again",
  2063: "Invalid vetting rules group ID. Please try another one.",
  2077: "Verification code expired.",
  2086: "Notes must be less than 1,000 characters",
  8001: "Margin Ratio (%) is required.",
  8002: "Margin Ratio format is not valid. Please enter valid percentage.",
  8003: "Margin Ratio must be unique. Please try another Margin Ratio",
  8004: "Description must be less than 255 characters. Please try again.",
  8005: "Please choose at least one language in which sms/notification/email can be sent.",
  8015: "Cannot save Margin Details. Please choose a Margin Rule before selecting a Notification Method.",
  8016: "Cannot save Margin Details. Please choose a Margin Rule before selecting a Notification Language.",
  8017: "A server communication error has occured. Please try again in a few minutes. If the error remains, please contact us.",
  8011: "A server communication error has occured. Please try again in a few minutes. If the error remains, please contact us.",
  2019: "Vetting Group must be unique. Please try another name for the Vetting Group.",
  8006: "You cannot take this action. There is an account assigned to this group.",
  8009: "Your Margin Ratio has been reached. You are not allowed to place an order that increases your current positions",
  8012: "You cannot modify this order due to margin call reason",
  8014: "You cannot take this action.This action has been processing in another Margin Ratio already.",
  8013: "You cannot cancel this order due to margin call reason",
  Account: "Account is not existed",
  "Account.DailyNet": "Account daily net value would be exceeded",
  "Account.DailyGross": "Account daily gross value would be exceeded",
  Authority: "User does not have permission to trade",
  Connection: "Trading requires a secure connection",
  Details: "No order details were provided",
  Error: "An undefined server error occurred",
  Exchange: "Invalid or not existed Exchange",
  Order: "The given Order ID for a cancel or amend is invalid",
  Operation: "The operation is not supported for this Order",
  Retry: "A temporary error occurred, please retry",
  Route: "A routing algorithm was not supplied",
  "Route.Algorithm": "Not existed routing algorithm",
  "Route.Market": "Invalid or not existed Trading Market",
  "Route.Symbol": "The symbol cannot be routed to the target Trading Market",
  Status: "The state of order can not be changed",
  Style:
    "Order Style must be provided. If amending, it must match the Order being amended",
  Submitted:
    "A fault occurred after the Order was submitted to the Exchange. The Order may have been successfully processed. Please check the Orders subscription",
  Symbol: "Selected symbol is invalid",
  "Symbol.Authority":
    "Current use does not have permission to trade the selected symbol",
  "Symbol.Status":
    "The target symbol is not in a state where the Order operation is supported",
  "TotalValue.Balance": "Not enough funds to cover this Order",
  "TotalValue.Maximum": "Greater than the maximum allowed value",
  ExpiryDate: "Expiry date is invalid",
  HiddenQuantity: "Hidden quantity is out of range or invalid",
  "HiddenQuantity.Symbol": "Not supported hidden quantity by this Symbol",
  LimitPrice: "Limit price is out of range or invalid",
  "LimitPrice.Distance": "Limit price is too far from the market",
  "LimitPrice.Given": "Limit price must be empty",
  "LimitPrice.Maximum": "Limit price is above the maximum ",
  "LimitPrice.Missing": "Limit price is required ",
  MinimumQuantity: "Minimum quantity is out of range or invalid",
  "MinimumQuantity.Symbol": "Minimum quantity is not supported ",
  OrderType: "Order type is invalid",
  "OrderType.Market": "Order type is not supported by the Trading Market",
  "OrderType.Status": "Order type is not supported in this Trading State",
  "OrderType.Symbol": "Symbol does not support this order type",
  Side: "Invalid side of the Market",
  "Side.Maximum": "Excessive amount of open orders on this side",
  TotalQuantity: "Visible and hidden quantities are both zero",
  "TotalQuantity.Minimum": "Order is below the minimum value",
  "TotalQuantity.Holdings": "Sell order is greater than available holdings",
  Validity: "Invalid validity period",
  "Validity.Symbol": "Validity period is not supported by this Symbol",
  VisibleQuantity: "Visible quantity is out of range or invalid",
  UnitType: "invalid unit type",
  UnitAmount: "Out of range unit amount",
  Currency: "Unit Type need a valid currency",
  "Flags.PDS": "Flag 'PDS' must be set",
  "TotalQuantity.Maximum": "Total quantity is above the maximum allowed",
  "Internal.AccessDenied": "Denied access internal component ",
  "Internal.Authority": "User authority details are not properly setup",
  "Internal.Database": "Unavailable database ",
  "Internal.Error": "An undefined error occurred",
  "Internal.Symbol": "This symbol can not be traded in the selected exchange",
  "Internal.NotFound": "A required piece of data was not found",
  "Internal.Path": "Server could not find a path to trade this Symbol",
  "Internal.Service": "Unavailable internal service ",
  TradingIsHalted: "Trading is halted",
  "Internal.Unavailable": "Unexpectedly unavailable internal component",
  Timeout: "Timeout occurred while processing the request",
  TIMEOUT: "Time out occured while processing request",
  server_timeout: "Timeout, server not responding!",
  "Cancelled By Exchange": "Order was cancelled by Exchange",
  "Cancelled By User": "Order was cancelled by User",
  INVALID_ORDER: "Invalid order",
  SYMBOL_INVALID: "Invalid symbol",
  "Service is not avaiable": "Service is not available",
  PlaceMarketOrderTimeOut: "Market order is time out",
  "Order is Expired": "Order is expired",
  "Validity.FillOrKill": "Order is killed due to not filled (FillorKill)",
  "Internal Retry": "Service is not available",
  "Disconnected.Paritech": "Interrupted connection to the Exchange",
  Untradedeletes: "Banned due to too many untraded deletes",
  "Operation TimeOut": "Operation is time out",
  "Authority.Subcribe": "Authority Subcribe",
  "Order.Error": "Order Error",
  "Order.Retry": "Order Retry",
  AF_ERROR_NEW_ORDER: "Insufficient cash to trade",
  AF_ERROR_MODIFY_ORDER: "Insufficient cash to modify the order",
  ACCOUNT_LOCKED: "Saxo account is locked",
  ACCOUNT_NOT_EXIST: "Account is not supported to trade international stock",
  ACCOUNT_NOT_MAPPING: "Account is not supported to trade international stocks",
  ACCOUNT_NULL: "Invalid Account ID",
  AF_ERR: "Insufficient cash to trade",
  AF_ERR_AMEND: "Insufficient cash to modify the order",
  BOOKING_TYPE_CUSTOM_NULL: "Invalid booking type custom",
  CLORDID_NULL: "Invalid Client Order ID",
  CURRENCY_NULL: "Invalid currency ",
  DATA_EXIST: "Data exist",
  DEPOSIT_FAILED: "Failed to deposit",
  ERR_CANNOT_GET_MARKET_PRICE: "Cannot get market price ",
  ERR_CANNOT_GET_RATE: "Cannot get currency exchange rate",
  ERROR: "Error",
  EX_DESTINATION_NULL: "Invalid exchange",
  FIX_NOT_CONNECTED: "Cannot be connected server",
  GET_FAILED: "Failed to get information",
  HAN_NULL: "Invalid Handllnst",
  INFO_REDUNDANT: "Info redundant",
  INSERT_FAILED: "Failed to insert",
  INTERNAL_ERROR: "Internal error",
  INVAILID_POSITION_HOLDING:
    "The sell order is greater than your available holdings",
  ACCOUNT_NOSAXOTRADING:
    "Account is not supported to trade international stocks",
  CLOSE_ONLY_POSITION: "Account is not supported to buy international stocks",
  INVALID_MAPPING: "Invalid mapping",
  INVALID_PARAMS: "Invalid parameter",
  INVALID_RESPONSE: "Invalid response",
  INVALID_SYMBOL: "Invalid symbol",
  INVALID_USER: "Invalid user",
  ISIN_CODE_NULL: "Invalid ISIN",
  MISSING_LIMIT_PRICE: "Invalid limit price ",
  MISSING_STOP_PRICE: "Invalid stop price ",
  NOT_EXIST: "Invalid information",
  NOTHING_CHANGED: "Nothing has been changed",
  ON_BEHALF_OF_COMP_ID_NULL: "Invalid On behalf of comp ID",
  ORDER_CAN_NOT_MODIFY: "Order cannot be modified",
  ORDER_NOT_EXIST: "Order is not existed",
  ORDER_REQUEST_NULL: "Invalid order request",
  ORDER_TYPE_INVALID: "Invalid order type",
  ORDQTY_NULL: "Invalid quantity",
  ORDTYPE_NULL: "Invalid order type",
  PARAM_INVALID: "Invalid parameter",
  PARAM_IS_NULL: "Invalid parameter",
  PRICE_NOT_NEGATIVE: "Invalid price",
  PRODUCT_NULL: "Invalid product",
  QUERY_INVALID: "Invalid query",
  SECURITY_ID_NULL: "Invalid ISIN",
  SECURITY_TYPE_NULL: "Invalid security type",
  SEND_ORDER_TO_SYNCSERVICE_FAILE: "Failed to request",
  SIDE_NULL: "Invalid side",
  SUCCESS: "Sucessfully",
  SYMBOL_NULL: "Invalid symbol",
  TIME_IN_FORCE_NULL: "Invalid duration",
  TRANSACTION_NULL: "Invalid transaction time",
  UNAUTHORIZED: "Unauthorized",
  UNKNOWN_ERROR: "Unknown error",
  UPDATE_FAILED: "Failed to update",
  UPDATE_REQUEST_ORDER_FAILE: "Failed to update order's information",
  USER_NOT_MAPPING: "Invalid user",
  VALIDATE_FAILED: "Invalid input",
  VALUE_NULL: "Invalid input",
  ACCOUNT_CAN_NOT_CHANGE:
    "ERROR: Trading Status cannot be changed to No Saxo Trading due to Saxo Account Value is not ZERO",
  ORDER_NOT_SYNC: "Order is not synchronize",
};

export default ERROR_MAPPING;

export function getErrorMessage(error) {
  const errorCode =
    error?.response?.data?.errorCode || error?.response?.data?.error_code;
  return (
    (errorCode && ERROR_MAPPING[errorCode] && ERROR_MAPPING[errorCode]) ||
    error?.message ||
    ""
  );
}
