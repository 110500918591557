import React from "react";
import Grid from "@mui/material/Grid";
import { InputField, SearchSymbol } from "../CustomFields";
import { NOTIFICATION_TYPE, ORDER, Type } from "src/constants";
import { useFormikContext } from "formik";
import formModels from "./formModels";
const ChooseTypeUrlReadOnly = ({ ...props }) => {
  const formik = useFormikContext();
  const typeNewOrder = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  {props?.checkReview ? (
                    <InputField
                      name={formModels.formField.symbol.name}
                      label={formModels.formField.symbol.label}
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      fullWidth
                      variant="standard"
                    />
                  ) : (
                    <SearchSymbol
                      name={formModels.formField.symbol.name}
                      label={formModels.formField.symbol.label}
                      fullWidth
                    />
                  )}
                </Grid>

                <Grid item xs={6} md={6}>
                  <InputField
                    name={formModels.formField.order_type.name}
                    label={formModels.formField.order_type.label}
                    InputProps={{ readOnly: Boolean(props?.checkReview) }}
                    fullWidth
                    variant="standard"
                  />
                  {/* <SelectField required name={'order_type'} label={'Order Type'} fullWidth checkReview={props?.checkReview} data={ORDER_TYPE} variant="standard" /> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT &&
              props?.checkReview ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.clicked.name}
                      label={formModels.formField.clicked.label}
                      fullWidth
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.ctr.name}
                      label={formModels.formField.ctr.label}
                      fullWidth
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <InputField
                    type="number"
                    name={formModels.formField.quantity.name}
                    label={formModels.formField.quantity.label}
                    InputProps={{ readOnly: Boolean(props?.checkReview) }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                {formik.values?.order_type === ORDER.limit ? (
                  <Grid item xs={6} md={6}>
                    <InputField
                      name={formModels.formField.price.name}
                      label={formModels.formField.price.name}
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {formik.values?.notification_type === NOTIFICATION_TYPE.DIRECT &&
              props?.checkReview ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.actor.name}
                      label={formModels.formField.actor.label}
                      fullWidth
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputField
                      name={formModels.formField.last_updated.name}
                      label={formModels.formField.last_updated.label}
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const typeSecDetail = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  {props?.checkReview ? (
                    <InputField
                      name={formModels.formField.symbol.name}
                      label={formModels.formField.symbol.label}
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      fullWidth
                      variant="standard"
                    />
                  ) : (
                    <SearchSymbol
                      name={formModels.formField.symbol.name}
                      label={formModels.formField.symbol.label}
                      fullWidth
                    />
                  )}
                </Grid>

                {/* <Grid item xs={6} md={6} >
                <SelectField required name={'order_type'} label={'Order Type'} fullWidth data={ORDER_TYPE} />
              </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const typeArticle = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  {props?.checkReview ? (
                    <InputField
                      name={formModels.formField.symbol.name}
                      label={formModels.formField.symbol.label}
                      InputProps={{ readOnly: Boolean(props?.checkReview) }}
                      fullWidth
                      variant="standard"
                    />
                  ) : (
                    <SearchSymbol
                      name={formModels.formField.symbol.name}
                      label={formModels.formField.symbol.label}
                      fullWidth
                    />
                  )}
                </Grid>

                <Grid item xs={6} md={6}>
                  <InputField
                    name={formModels.formField.title_article.name}
                    label={formModels.formField.title_article.label}
                    InputProps={{ readOnly: Boolean(props?.checkReview) }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <InputField
                    name={formModels.formField.url_article.name}
                    label={formModels.formField.url_article.label}
                    InputProps={{ readOnly: Boolean(props?.checkReview) }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                {/* {formik.values.order_type.value === ORDER.limit ?
                <Grid item xs={6} md={6} className={classes.spaceBox}>
                  <InputField name={'price'} label={'Price'} fullWidth />
                </Grid> : null
              } */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container style={{ width: "100%" }}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const typeOrderId = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xs={12}
          md={12}
          style={{ margin: 0, padding: "0 5px 0 5px" }}
        >
          <Grid
            container
            spacing={2}
            style={{ margin: 0, paddingRight: 0, width: "100%" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <InputField
                    name={formModels.formField.order_id.name}
                    label={formModels.formField.order_id.label}
                    InputProps={{ readOnly: Boolean(props?.checkReview) }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>

                {/* <Grid item xs={6} md={6} >
                <SelectField required name={'order_type'} label={'Order Type'} fullWidth data={ORDER_TYPE} />
              </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  const renderTypeUrl = () => {
    switch (formik.values.Type_Url) {
      case Type.new_order:
        return typeNewOrder();
      case Type.order_detail:
        return typeOrderId();
      case Type.security_detail:
        return typeSecDetail();
      case Type.article:
        return typeArticle();
      default: {
        console.log("not found...");
        return "";
      }
    }
  };
  return <>{renderTypeUrl()}</>;
};

export default ChooseTypeUrlReadOnly;
