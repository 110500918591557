/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  OutlinedInput,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  Stack,
  Button,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useFormikContext } from "formik";
import { autoGerarateCharacters } from "src/utils/functionUtils";
export default function passwordField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const [visibility, setVisibility] = useState(false);
  const { setFieldValue } = useFormikContext();
  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }
  const handleClickShowPassword = () => {
    setVisibility((value) => !value);
  };
  const generatePassword = () => {
    setFieldValue(field.name, autoGerarateCharacters(), true);
  };
  return (
    <React.Fragment>
      <Stack direction={"row"} spacing={2}>
        <FormControl fullWidth variant="outlined" required={props.required}>
          <InputLabel
            htmlFor="standard-adornment-password"
            style={_renderHelperText() ? { color: "red" } : {}}
          >
            {props.label}
          </InputLabel>
          <OutlinedInput
            type={visibility ? "text" : "password"}
            fullWidth
            error={touched && error && true}
            {...field}
            {...rest}
            value={field.value || ""}
            label={`${props.label}1`}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!visibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                ,{" "}
                <Button
                  variant="outlined"
                  onClick={generatePassword}
                  size="small"
                  style={{ marginLeft: 10 }}
                >
                  Genarate
                </Button>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="standard-weight-helper-text"
            style={{ color: "red" }}
          >
            {_renderHelperText()}
          </FormHelperText>
        </FormControl>
      </Stack>
    </React.Fragment>
  );
}
