import { Modal } from "@mui/material";
import NewApplication from "src/pages/new_application";
import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    height: "100vh",
    overflow: "auto",
    padding: theme.spacing(8),
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    right: 80,
    top: 80,
    zIndex: 99,
  },
}));

const ViewApplicationModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useImperativeHandle(
    ref,
    () => ({
      showModal: showModal,
    }),
    []
  );

  const showModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const handleModalToggle = () => {
    setOpen((old) => !old);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={classes.modalContainer}>
        <div id="topModal" />
        <IconButton onClick={handleModalToggle} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
        <NewApplication open={open} closeModal={closeModal} />
      </div>
    </Modal>
  );
});

ViewApplicationModal.displayName = "ViewApplicationModal";
export default ViewApplicationModal;
