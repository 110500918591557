import * as Yup from "yup";
import moment from "moment";
import {
  GOVERNMENT_ID_TYPE,
  OCCUPATION_CATEGORY,
  ACCOUNT_TYPE,
} from "src/constants";
import dataStorage from "src/dataStorage";
import { getEnv, isMyApplicant } from "src/utils/functionUtils";
import logger from "src/utils/logger";

const CHECKLENGTH_ENUM = {
  [GOVERNMENT_ID_TYPE.PASSPORT]: {
    maxLength: 31,
  },
  [GOVERNMENT_ID_TYPE.DRIVER_LICENSE]: {
    maxLength: 20,
  },
};
export const VALIDATE_ENUM = {
  PHONE: {
    REGEX: /^[0-9]{6,16}$/,
    ERROR: "Phone is invalid",
  },
  EMAIL: {
    REGEX:
      /^([a-z0-9]+[_+.-])*[a-z0-9]+@(([a-z0-9]+-)*([a-z0-9]+)\.)+[a-z]{2,}$/i,
    ERROR: "Email is invalid",
  },
  NAME_ON_CARD: {
    REGEX: /^[A-Za-z0-9\s'-]*$/,
    ERROR: "Name on Card is invalid",
  },
};

Yup.addMethod(
  Yup.string,
  "checkApplicantEmail",
  function (message, checkLength) {
    return this.test("email", message, function (value) {
      const { path, createError } = this;
      if (dataStorage.isSubApplicant) return true;
      // if (typeof value === 'string') value = value.trim()
      if ([null, undefined, ""].includes(value)) {
        // delete existApplicant[path]
        return createError({ path, message: "Email is required" });
      } else {
        if (VALIDATE_ENUM.EMAIL.REGEX.test(value)) {
          if (checkLength) return true;
          if (value.length > 80) {
            // delete existApplicant[path]
            return createError({
              path,
              message: "Email address is maximum 80 characters",
            });
          } else {
            return true;
          }
        } else {
          // delete existApplicant[path]
          return createError({
            path,
            message: message || VALIDATE_ENUM.EMAIL.ERROR,
          });
        }
      }
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkRequired",
  function (message = "This field is required") {
    return this.test("required", message, function (data) {
      let value = data;
      if (data && Object.prototype.hasOwnProperty.call(data, "value")) {
        value = data.value;
      }
      if (typeof value === "string") value = value.trim();
      const { path, createError } = this;
      if ([null, undefined, ""].includes(value)) {
        return createError({ path, message });
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkRequiredOccupationCategory",
  function (message = "This field is required") {
    return this.test("required", message, function (value) {
      if (typeof value === "string") value = value.trim();
      const { path, createError, parent } = this;
      if ([null, undefined, ""].includes(value)) {
        return createError({ path, message });
      }
      const { occupation_type: occupationType } = parent;
      const occupationCategory =
        OCCUPATION_CATEGORY[getEnv()][occupationType?.value] || [];
      if (!occupationCategory.includes(value?.value)) {
        return createError({ path, message });
      }
      return true;
    });
  }
);

Yup.addMethod(Yup.mixed, "checkMaxLength", function (max, label, message) {
  return this.test("length", message, function (value) {
    if (!max) return true;
    const { path, createError } = this;
    if ((value + "").length > max) {
      if (!message) message = `${label} must be 1 to ${max} characters`;
      return createError({ path, message });
    }
    return true;
  });
});

Yup.addMethod(Yup.mixed, "checkEqualLength", function (equal, label, message) {
  return this.test("length", message, function (value) {
    if (!equal) return true;
    const { path, createError } = this;
    if ((value + "").length !== equal) {
      if (!message) message = `${label} must equal ${equal} characters`;
      return createError({ path, message });
    }
    return true;
  });
});

Yup.addMethod(
  Yup.mixed,
  "checkPhone",
  function (message = "Mobile Phone is invalid", isJoint, len = 8) {
    return this.test("phone", message, function (phone) {
      if (isJoint) {
        const { index } = this.options;
        if (![null, undefined].includes(index) && !isMyApplicant(index))
          return true;
      }
      if (phone) {
        const { path, createError } = this;
        if ((phone + "").length !== len) {
          return createError({ path, message });
        }
      }
      return true;
    });
  }
);

Yup.addMethod(Yup.mixed, "checkMaxDate", function (message, label = "Date") {
  return this.test("expire", message, function (value) {
    const { path, createError } = this;
    const date = moment(value);
    if (date.isValid()) {
      if (date.toDate() > +new Date()) {
        return createError({
          path,
          message: message || `${label} invalid`,
        });
      }
      return true;
    } else {
      return createError({ path, message: message || `${label} invalid` });
    }
  });
});

Yup.addMethod(Yup.mixed, "checkMinAge", function (min, message, isJoint) {
  return this.test("age", message, function (value) {
    if (isJoint) {
      const { index } = this.options;
      if (![null, undefined].includes(index) && !isMyApplicant(index))
        return true;
    }
    if (!min) return true;
    const { path, createError } = this;
    const isTypeDate = (value + "").includes("/");
    const sliceDate = isTypeDate ? value?.slice(0, 10) : value;
    const date = moment(sliceDate, isTypeDate ? "DD/MM/YYYY" : moment.ISO_8601);
    const formatDate = new Date(date);
    if (date.isValid() && formatDate.getFullYear() >= 1920) {
      const diff = moment().diff(date, "years");
      if (diff < min) {
        return createError({
          path,
          message: `You must be over ${min} years of age to create an account`,
        });
      }
      return true;
    } else {
      if (!message) message = "Date of Birth is invalid";
      return createError({ path, message });
    }
  });
});

Yup.addMethod(Yup.string, "checkExpireDate", function (message) {
  return this.test("expire", message, function (value) {
    const { path, createError } = this;
    const date = moment(value, moment.ISO_8601);
    if (date.isValid()) {
      if (date.toDate() < +new Date()) {
        return createError({
          path,
          message: "Your medicare card is expired",
        });
      }
      return true;
    } else {
      if (!message) message = "Expire Date is invalid";
      return createError({ path, message });
    }
  });
});

const MULTI_TFN = [1, 4, 3, 7, 5, 8, 6, 9, 10];
Yup.addMethod(Yup.string, "checkTfn", function (message) {
  return this.test("tfn", message, function (value) {
    const { path, createError } = this;
    if ([null, undefined, ""].includes(value)) return true;
    if ((value + "").length !== 9) {
      return createError({ path, message: "Tax File Number is invalid" });
    } else {
      const listDigit = Array.from(value + "", (v) => Number(v));
      const checkSum =
        listDigit.reduce((acc, cur, i) => {
          const sumDigit = cur * MULTI_TFN[i];
          return acc + sumDigit;
        }, 0) % 11;
      if (checkSum !== 0) {
        return createError({ path, message: "Tax File Number is invalid" });
      }
      return true;
    }
  });
});

Yup.addMethod(
  Yup.mixed,
  "checkNumber",
  function (message = "This field must be number") {
    return this.test("checkNumber", message, function (value) {
      if ([null, undefined].includes(value)) return true;
      const { path, createError } = this;
      const patt = /^[0-9]{1,}$/g;
      const result = patt.test(value);
      if (!result) return createError({ path, message });
      return true;
    });
  }
);

Yup.addMethod(
  Yup.string,
  "checkDriverLicense",
  function (
    message = "Driver License No. have to follow rule of Driver License"
  ) {
    return this.test("checkDriverLicense", message, function (value) {
      const { path, createError } = this;
      const patt = /^[A-Za-z0-9]{1,10}$/g;
      const result = patt.test(value);
      if (value == null)
        return createError({
          path,
          message: "Drivers License No. is required",
        });
      if (!result) return createError({ path, message });
      return true;
    });
  }
);

Yup.addMethod(
  Yup.string,
  "checkMedicareCard",
  function (
    message = "Medicare Card No. have to follow rule of Medicare Card"
  ) {
    return this.test("checkMedicareCard", message, function (value) {
      const { path, createError } = this;
      const patt = /^[2-6][0-9]{9}$/g;
      const result = patt.test(value);
      if (!result) {
        return createError({ path, message });
      }
      return true;
    });
  }
);

Yup.addMethod(Yup.mixed, "checkRole", function (message) {
  return this.test("checkRole", message, function (value) {
    const { path, createError } = this;
    if (!value) return createError({ path, message });
    if (!Object.values(value).filter((e) => e).length) {
      return createError({ path, message });
    }
    return true;
  });
});

Yup.addMethod(Yup.mixed, "checkRoleInSuper", function (message, type) {
  return this.test("checkRoleInSuper", message, function (value) {
    const { path, createError, from, parent } = this;
    const trusteeType = from?.[1]?.value?.trustee_type?.value;
    switch (type) {
      case ACCOUNT_TYPE.COMPANY:
        if (trusteeType === ACCOUNT_TYPE.INDIVIDUAL) return true;
        if (!value) return createError({ path, message });
        if (!Object.values(value).filter((e) => e).length) {
          return createError({ path, message });
        }
        return true;
      case ACCOUNT_TYPE.INDIVIDUAL:
        if (trusteeType === ACCOUNT_TYPE.COMPANY) return true;
        if (!value) return createError({ path, message });
        return true;
      case "OTHER":
        if (trusteeType === ACCOUNT_TYPE.COMPANY) return true;
        if (parent?.role_in_super_individual?.value === "OTHER" && !value) {
          return createError({ path, message });
        }
        return true;
      default:
        return true;
    }
  });
});

Yup.addMethod(
  Yup.string,
  "checkPassport",
  function (message = "Passport No. have to follow rule of Passport") {
    return this.test("checkPassport", message, function (value) {
      const { path, createError } = this;
      if (["", null, undefined].includes(value))
        return createError({ path, message: "Passport No. is required" });
      const patt = /^[A-Za-z]{1,2}[0-9]{7}$/g;
      const result = patt.test(value);
      if (!result) {
        return createError({ path, message });
      }
      return true;
    });
  }
);

function checkNull(value) {
  return [null, undefined].includes(value);
}

Yup.addMethod(Yup.mixed, "checkLanguageField", function (label, message = "") {
  return this.test("checkLanguageField", message, function (value) {
    if (checkNull(value)) return true;
    const { path, createError } = this;
    // eslint-disable-next-line prefer-regex-literals
    const getLength = new RegExp("^[A-z0-9-' ]{1,}$");
    const result = getLength.test(value);
    if (!result) return createError({ path, message: `${label} is invalid` });
    return true;
  });
});
Yup.addMethod(Yup.string, "checkLengthName", function (label, message) {
  return this.test("checkLengthName", message, function (value, testContext) {
    if (checkNull(value)) return true;
    const { path, createError } = this;
    const {
      government_id: governmentId,
      first_name: firstName,
      middle_name: middleName,
    } = testContext.parent;
    const type = governmentId?.type;
    if (type) {
      if (value !== value.trim())
        return createError({ path, message: `${label} is invalid` });
      const findType = CHECKLENGTH_ENUM[type.value];
      if (findType) {
        const checkMaxLength =
          path.indexOf("last_name") !== -1 &&
          type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
            ? 40
            : findType.maxLength;
        const getLength = new RegExp(
          path.indexOf("middle_name") !== -1
            ? `^.{1,${checkMaxLength}}$`
            : `^[A-z-\\' ]{1,${checkMaxLength}}$`
        );
        const result = getLength.test(value);
        if (!result)
          return createError({ path, message: `${label} is invalid` });
      }
      if (
        path.indexOf("middle_name") !== -1 ||
        path.indexOf("first_name") !== -1
      ) {
        const tranformMiddleName = middleName ? middleName.trim() : "";
        const tranformFirstName = firstName ? firstName.trim() : "";
        const result =
          tranformMiddleName.length + tranformFirstName.length <= 31;
        if (!result)
          return createError({
            path,
            message:
              "The maximum First Name and Middle Name length cannot exceed 31 characters in total",
          });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, "checkTax", function (message) {
  return this.test("checkTax", message, function (value) {
    if ([null, undefined].includes(value)) return true;
    const { path, createError } = this;
    const FormNumber = [1, 4, 3, 7, 5, 8, 6, 9, 10];
    if (value) {
      if (value.length === 9) {
        const tranFormString = value
          .split("")
          .reduce((total, current) => [...total, parseInt(current)], []);
        const result =
          FormNumber.map((currentN, index) => {
            return currentN * tranFormString[index];
          }).reduce((total, currentN) => total + currentN, 0) % 11;
        if (result !== 0)
          return createError({
            path,
            message: "Australian Tax Resident is invalid",
          });
        return true;
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.mixed, "checkTypeChannel", function () {
  return this.test("required", "", function (value, context) {
    const getChanel = [
      context.parent.mobile_push,
      context.parent.desktop_push,
      context.parent.email_push,
      context.parent.sms_push,
    ];
    const { path, createError } = this;
    if (!getChanel.includes(true)) {
      return createError({ path, message: `Channel is required.` });
    }
    return true;
  });
});

Yup.addMethod(Yup.mixed, "checkInvalid", function () {
  return this.test("required", "", function (value) {
    const { path, createError } = this;
    const date = moment(value, moment.ISO_8601);
    if (!date.isValid())
      return createError({ path, message: "Expire Date is invalid" });
    return true;
  });
});

Yup.addMethod(
  Yup.mixed,
  "checkAccountDesignation",
  function (message, isJoint) {
    return this.test("tfn", message, function (value) {
      if (isJoint) {
        const { index } = this.options;
        if (![null, undefined].includes(index) && !isMyApplicant(index))
          return true;
      }
      const { path, createError } = this;
      if ([null, undefined, ""].includes(value))
        return createError({
          path,
          message: "Account Designation is required",
        });
      if (value.length !== value.trim().length)
        return createError({
          path,
          message:
            "Account Designation can't contain white space at start and end",
        });
      if (value.length > 24)
        return createError({
          path,
          message: "Account Designation is maximum 24 characters",
        });
      if (
        [
          "account",
          "atf",
          "trust",
          "trustee",
          "trustees",
          "act",
          "a/c",
          "acfo",
          "testamentary",
          "test",
        ].includes(value.toLowerCase())
      ) {
        return createError({
          path,
          message: "Account Designation can't contain sensitive words",
        });
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.string,
  "checkABN",
  function (message = "ABN number invalid") {
    return this.test("checkABN", message, function (value) {
      const { path, createError } = this;
      if (value) {
        const number = (value + "").match(/\d/g)?.join("") || "";
        const patt = /^[\d\s-]*$/;
        if (!patt.test(value) || number.length !== 11) {
          return createError({ path, message });
        }
        return true;
      }
      return true;
    });
  }
);

Yup.addMethod(Yup.mixed, "checkACN", function (message = "ACN number invalid") {
  return this.test("checkACN", message, function (value) {
    try {
      const { path, createError } = this;
      if (value) {
        const number = (value || "")?.match(/\d/g)?.join("");
        const patt = /^[\d\s-]*$/;
        if (!patt.test(value) || number.length !== 9) {
          return createError({ path, message });
        }
        return true;
      }
      return true;
    } catch (err) {
      logger.error(err, "checkACN");
      return true;
    }
  });
});

Yup.addMethod(
  Yup.mixed,
  "atLeastOneRequired",
  function (listCheck = [], message) {
    return this.test("atLeastOneRequired", message, function (value) {
      try {
        const { path, createError, parent } = this;
        let check = false;
        if (value) return true;
        for (let index = 0; index < listCheck.length; index++) {
          const checkValue = parent?.[listCheck[index]];
          if (checkValue) {
            check = true;
            break;
          }
        }
        if (!check) {
          return createError({ path, message });
        }
        return true;
      } catch (err) {
        logger.error(err, "atLeastOneRequired");
        return true;
      }
    });
  }
);

export default Yup;
