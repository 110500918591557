import * as React from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { ChartBar as ChartBarIcon } from "../icons/chart-bar";
import { ShoppingBag as ShoppingBagIcon } from "../icons/shopping-bag";
import { User as UserIcon } from "../icons/user";
import { Users as UsersIcon } from "../icons/users";
import { Article as ArticleIcon } from "../icons/article";
import { Bell as BellIcon } from "../icons/bell";
import NavItem from "./NavItem";
import dataStorage from "src/dataStorage";

export default function SidebarMenu() {
  const [open, setOpen] = React.useState({
    onboadring: false,
    userandaccount: false,
  });

  const handleClick =
    (nameParent = "") =>
    () => {
      setOpen((e) => ({ ...e, [nameParent]: !e[nameParent] }));
    };

  const env = dataStorage.env;

  return (
    <List
      sx={{ width: "100%" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <NavItem
        key={"Dashboard"}
        icon={<ChartBarIcon fontSize="small" />}
        href={`/${env}`}
        title={"Dashboard"}
      />
      <NavItem
        key={"Notifications"}
        icon={<BellIcon fontSize="small" />}
        href={`/${env}/notifications`}
        title={"Notifications"}
      />
      <NavItem
        key={"Segments"}
        icon={<UsersIcon fontSize="small" />}
        href={`/${env}/segments`}
        title={"Segments"}
      />
      <NavItem
        key={"Subscribers"}
        icon={<UserIcon fontSize="small" />}
        href={`/${env}/subscribers`}
        title={"Subscribers"}
      />
      <NavItem
        isParent={true}
        open={open.userandaccount}
        key={"User and Account"}
        icon={<ArticleIcon fontSize="small" />}
        href={`/${env}/userandaccount`}
        title={"User and Account"}
        onClick={handleClick("userandaccount")}
      />
      <Collapse in={open.userandaccount} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavItem
            key={"New User"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/userandaccount/new_user`}
            title={"New User"}
          />
          <NavItem
            key={"User Management"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/userandaccount/user_management`}
            title={"User Management"}
          />
          {/* <NavItem
                        key={'Drafts'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/drafts`}
                        title={'Drafts'}
                    />
                    <NavItem
                        key={'Pending Applications'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/pending_application`}
                        title={'Pending Application'}
                    />
                    <NavItem
                        key={'Completed Applications'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/completed_application`}
                        title={'Completed Application'}
                    />
                    <NavItem
                        key={'Rejected Applications'}
                        icon={<ShoppingBagIcon fontSize="small" style={{ visibility: 'hidden' }} />}
                        href={`/${env}/onboarding/reject_application`}
                        title={'Reject Application'}
                    /> */}
        </List>
      </Collapse>
      <NavItem
        isParent={true}
        open={open.onboadring}
        key={"Onboarding"}
        icon={<ArticleIcon fontSize="small" />}
        href={`/${env}/onboarding`}
        title={"Onboarding"}
        onClick={handleClick("onboadring")}
      />
      <Collapse in={open.onboadring} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavItem
            key={"New Application"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/onboarding/new_application`}
            title={"New Application"}
          />
          <NavItem
            key={"Drafts"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/onboarding/drafts`}
            title={"Drafts"}
          />
          <NavItem
            key={"Pending Applications"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/onboarding/pending_application`}
            title={"Pending Application"}
          />
          <NavItem
            key={"Completed Applications"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/onboarding/completed_application`}
            title={"Completed Application"}
          />
          <NavItem
            key={"Rejected Applications"}
            icon={
              <ShoppingBagIcon
                fontSize="small"
                style={{ visibility: "hidden" }}
              />
            }
            href={`/${env}/onboarding/reject_application`}
            title={"Reject Application"}
          />
        </List>
      </Collapse>
    </List>
  );
}
