import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useFormikContext } from "formik";
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  PhoneNumberField,
  BooleanRadio,
} from "src/components/CustomFields";
import RadioField from "src/components/CustomFields/RadioField";
import {
  AUSTRALIAN_TAX_OPTIONS,
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  MEDICARE_CARD_COLOUR_OPTIONS,
  MEDICARE_CARD_COLOUR,
  MEDICARE_CARD_INDIDUAL_NUMBERS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS,
  SOURCE_OF_WEALTH,
  OWN_CAFE_RESTAURANT_OPTIONS,
} from "src/constants";
import BrokerData from "./BrokerData";
import {
  getEnv,
  checkKycVerify,
  isOtherApplicant,
} from "src/utils/functionUtils";
import COUNTRIES from "src/countries";
import dataStorage from "src/dataStorage";
import { makeStyles } from "@mui/styles";
import formModel from "../FormModels/formModels";

const { formField } = formModel;

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: "hidden",
    minHeight: "400px",
    display: "flex",
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper,
  },
  SelectionBox: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "transparent",
  },
  cardBox: {
    width: "33%",
    minHeight: "200px",
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  cardBox2: {
    width: "33%",
    minHeight: "200px",
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "cover",
  },
  cardBox3: {
    width: "33%",
    minHeight: "200px",
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "cover",
  },
  media: {
    marginTop: "15%",
    // height: '60%',
    marginLeft: "30%",
    width: "40% !important",
  },
  buttons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(0),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
  },
  deleteIcon: {
    display: "none",
  },
  listItem: {
    "&:hover $deleteIcon": {
      display: "block",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
  },
  leftButtons: {
    display: "flex",
    flexDirection: "row",
    "& $wrapper": {
      "& button": {
        marginRight: 16,
      },
      "&:first-child": {
        marginLeft: 0,
        "& button": {
          marginLeft: 0,
        },
      },
    },
  },
  rightButtons: {
    display: "flex",
    flexDirection: "row",
  },
}));

function ApplicationForm(props, ref) {
  const classes = useStyle();
  const { index: activeIndex } = props;
  const {
    values: formValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const isVerified = checkKycVerify(
    formValues.applicant_details?.[activeIndex]?.ekyc_overall_status
  );
  const getFormatExpireDate = () => {
    const cardColor =
      formValues.applicant_details[activeIndex]?.government_id
        ?.medicare_card_colour;
    if (!cardColor || cardColor.value === MEDICARE_CARD_COLOUR.GREEN) {
      return {
        format: "MM/yyyy",
        views: ["month", "year"],
      };
    }
    return {
      format: "dd/MM/yy",
      views: ["date", "month", "year"],
    };
  };

  const getMatchYear = (year) => {
    if (year == null) return Date.now();
    const ConfigDate = new Date(Date.now());
    ConfigDate.setFullYear(ConfigDate.getFullYear() - year);
    return ConfigDate;
  };

  const checkDateFeature = (day) => {
    if (day == null) return Date.now();
    const ConfigDate = new Date(Date.now());
    ConfigDate.setDate(ConfigDate.getDate() + day);
    return ConfigDate;
  };

  const renderVerification = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].title`}
            label={formField.applicant_details.title.label}
            data={TITLE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].first_name`}
            label={formField.applicant_details.first_name.label}
            ext={formField.applicant_details.first_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].middle_name`}
            label={formField.applicant_details.middle_name.label}
            ext={formField.applicant_details.middle_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].last_name`}
            label={formField.applicant_details.last_name.label}
            ext={formField.applicant_details.last_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 4 }}>
          <Typography variant="body2" className="textStatic">
            Full Name Example: John Peter Leslie Smith
          </Typography>
        </Grid>
        {!formValues.applicant_details[activeIndex]
          .residential_address_manual_address ? (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <AutocompleteFieldAsync
                required
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].residential_address_full_address`}
                label={
                  formField.applicant_details.residential_address_full_address
                    .label
                }
                fullWidth
              />
            </Grid>
          </React.Fragment>
        ) : null}
        {formValues.applicant_details[activeIndex]
          .residential_address_manual_address ? (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].residential_address_manual_address`}
                label="Cannot find your address? Manual Input"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].residential_address_unit_flat_number`}
                label={
                  formField.applicant_details
                    .residential_address_unit_flat_number.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_street_number`}
                label={
                  formField.applicant_details.residential_address_street_number
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_street_name`}
                label={
                  formField.applicant_details.residential_address_street_name
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_street_type`}
                label={
                  formField.applicant_details.residential_address_street_type
                    .label
                }
                data={STREET_TYPE}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_city_suburb`}
                label={
                  formField.applicant_details.residential_address_city_suburb
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <SelectField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_state`}
                label={
                  formField.applicant_details.residential_address_state.label
                }
                data={STATE_OF_ISSUE_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_postcode`}
                label={
                  formField.applicant_details.residential_address_postcode.label
                }
                fullWidth
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].residential_address_manual_address`}
                label="Cannot find your address? Manual Input"
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12} style={{ padding: 0 }}></Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].government_id.type`}
            label={formField.applicant_details.government_id.type.label}
            data={GOVERNMENT_ID_TYPE_OPTIONS}
            fullWidth
          />
        </Grid>
        {formValues.applicant_details?.[activeIndex]?.government_id?.type ? (
          <Grid item xs={12} sm={6}>
            <InputField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].government_id.number`}
              label={
                formField.applicant_details.government_id.number.label[
                  formValues.applicant_details?.[activeIndex]?.government_id
                    ?.type?.value
                ]
              }
              fullWidth
            />
          </Grid>
        ) : (
          <React.Fragment />
        )}
        {/* Using Driver License */}
        {formValues.applicant_details[activeIndex].government_id?.type
          ?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE ? (
          <Grid item xs={12} sm={6}>
            <SelectField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
              label={
                formField.applicant_details.government_id.state_of_issue.label
              }
              data={STATE_OF_ISSUE_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : null}

        {/* Using Medicare Card */}
        {formValues.applicant_details[activeIndex].government_id?.type
          ?.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD ? (
          <React.Fragment>
            <Grid item xs={12} sm={3}>
              <SelectField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].government_id.medicare_card_colour`}
                label={
                  formField.applicant_details.government_id.medicare_card_colour
                    .label
                }
                fullWidth
                data={MEDICARE_CARD_COLOUR_OPTIONS}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].government_id.medicare_individual_reference_number`}
                label={
                  formField.applicant_details.government_id
                    .medicare_individual_reference_number.label
                }
                data={MEDICARE_CARD_INDIDUAL_NUMBERS}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerField
                name={`applicant_details[${activeIndex}].government_id.medicare_card_expiry_date`}
                label={
                  formField.applicant_details.government_id
                    .medicare_card_expiry_date.label
                }
                required
                disabled={isVerified}
                format={getFormatExpireDate().format}
                setTouched={setFieldTouched}
                // views={getFormatExpireDate().views}
                minDate={checkDateFeature(1)}
                maxDate={new Date("2050/12/31")}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        ) : null}

        <Grid item xs={12} sm={6}>
          <AutocompleteField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].nationality`}
            label={formField.applicant_details.nationality.label}
            data={COUNTRIES}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            required
            disabled={
              isVerified || dataStorage.isOperatorSupport || isOtherApplicant()
            }
            inputProps={{ readOnly: isOtherApplicant() }}
            name={`applicant_details[${activeIndex}].applicant_email`}
            label={formField.applicant_details.applicant_email.label}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  };

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <RadioField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].gender`}
            label={formField.applicant_details.gender.label}
            data={GENDER_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            format="dd/MM/yyyy"
            inputFormat="dd/MM/yyyy"
            minDate={new Date("1920/1/1")}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneNumberField
            disabled={isVerified}
            required
            prefix="04"
            name={`applicant_details[${activeIndex}].applicant_mobile_phone`}
            label={formField.applicant_details.applicant_mobile_phone.label}
            fullWidth
          />
        </Grid>
        {renderManualAddress("residential_address")}
        {dataStorage.userType === 0 &&
        activeIndex === 0 &&
        !dataStorage.isSubApplicant ? (
          <React.Fragment />
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <InputField
                required
                disabled={
                  isVerified ||
                  dataStorage.isOperatorSupport ||
                  isOtherApplicant()
                }
                inputProps={{ readOnly: isOtherApplicant() }}
                name={`applicant_details[${activeIndex}].applicant_email`}
                label={formField.applicant_details.applicant_email.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
  const renderManualAddress = (nameField) => {
    if (!nameField) return <></>;
    return (
      <React.Fragment>
        <Grid item xs={12} className="addressContainer">
          {formValues.applicant_details[activeIndex][
            `${nameField}_manual_address`
          ] ? (
            <React.Fragment />
          ) : (
            <AutocompleteFieldAsync
              required
              name={`applicant_details[${activeIndex}][${nameField}_full_address]`}
              label={
                formField.applicant_details[`${nameField}_full_address`].label
              }
              fullWidth
            />
          )}
          <CheckboxField
            name={`applicant_details[${activeIndex}][${nameField}_manual_address]`}
            label="Cannot find your address? Manual Input"
          />
        </Grid>
        {formValues.applicant_details[activeIndex][
          `${nameField}_manual_address`
        ] ? (
          <>
            <Grid item xs={6} sm={6}>
              <InputField
                name={`applicant_details[${activeIndex}].${nameField}_unit_flat_number`}
                label={
                  formField.applicant_details[nameField + "_unit_flat_number"]
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_number`}
                label={
                  formField.applicant_details[nameField + "_street_number"]
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_name`}
                label={
                  formField.applicant_details[nameField + "_street_name"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_type`}
                label={
                  formField.applicant_details[nameField + "_street_type"].label
                }
                data={STREET_TYPE}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_city_suburb`}
                label={
                  formField.applicant_details[nameField + "_city_suburb"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].${nameField}_state`}
                label={formField.applicant_details[nameField + "_state"].label}
                data={STATE_OF_ISSUE_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_postcode`}
                label={
                  formField.applicant_details[nameField + "_postcode"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].source_of_wealth`}
            label={formField.applicant_details.source_of_wealth.label}
            data={SOURCE_OF_WEALTH_OPTIONS}
            fullWidth
          />
        </Grid>
        {formValues.applicant_details[activeIndex].source_of_wealth?.value ===
        SOURCE_OF_WEALTH.FOREIGN_INCOME ? (
          <Grid item xs={12} sm={12}>
            <BooleanRadio
              name={`applicant_details[${activeIndex}].subclass_visa`}
              label={formField.applicant_details.subclass_visa.label}
              data={AUSTRALIAN_TAX_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={6}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].occupation_type`}
            label={formField.applicant_details.occupation_type.label}
            data={OCCUPATION_TYPE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].occupation_category`}
            label={formField.applicant_details.occupation_category.label}
            data={
              OCCUPATION_CATEGORY_OPTIONS[
                formValues.applicant_details[activeIndex].occupation_type?.value
              ]
            }
            fullWidth
          />
        </Grid>
        {formValues.applicant_details[activeIndex].occupation_type?.value ===
        OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER ? (
          <React.Fragment>
            {["Hotel and Motel", "Accommodation and Food Services"].includes(
              formValues.applicant_details[activeIndex]?.occupation_category
                .value
            ) ? (
              <>
                <Grid item xs={12} sm={12}>
                  <BooleanRadio
                    disabled={isVerified}
                    required
                    name={`applicant_details[${activeIndex}].liquor_gambling_licence`}
                    label={
                      formField.applicant_details.liquor_gambling_licence.label
                    }
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}

            {formValues.applicant_details[activeIndex]?.occupation_category
              .value === "Charity Community or Social Services" ? (
              <>
                <Grid item xs={12} sm={12}>
                  <BooleanRadio
                    disabled={isVerified}
                    required
                    name={`applicant_details[${activeIndex}].if_business_is_regulated_charity`}
                    label={
                      formField.applicant_details
                        .if_business_is_regulated_charity.label
                    }
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}

            {formValues.applicant_details[activeIndex]?.occupation_category
              .value === "Cafe and Restaurant" ? (
              <>
                <Grid item xs={12}>
                  <SelectField
                    disabled={isVerified}
                    required
                    name={`applicant_details[${activeIndex}].own_cafe_restaurant`}
                    label={
                      formField.applicant_details.own_cafe_restaurant.label
                    }
                    data={OWN_CAFE_RESTAURANT_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={6}>
              <InputField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].business_owner_trading_name`}
                label={
                  formField.applicant_details.business_owner_trading_name.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                disabled={isVerified}
                type="number"
                name={`applicant_details[${activeIndex}].abn_acn_registration_number`}
                label={
                  formField.applicant_details.abn_acn_registration_number.label
                }
                fullWidth
              />
            </Grid>
            {renderManualAddress("business_owner_trading_address")}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <Grid item xs={12} sm={12} style={{ padding: 0 }}></Grid>
        <Grid item xs={12} sm={6}>
          <BooleanRadio
            required
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].australian_tax_resident`}
            label={formField.applicant_details.australian_tax_resident.label}
            data={AUSTRALIAN_TAX_OPTIONS}
            fullWidth
          />
        </Grid>

        {formValues.applicant_details[activeIndex].australian_tax_resident ? (
          <React.Fragment>
            <Grid item xs={12}>
              <BooleanRadio
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].tax_exemption`}
                label={formField.applicant_details.tax_exemption.label}
                data={AUSTRALIAN_TAX_OPTIONS}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        {formValues.applicant_details[activeIndex].tax_exemption &&
        formValues.applicant_details[activeIndex].australian_tax_resident ? (
          <Grid item xs={12}>
            <SelectField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].tax_exemption_details`}
              label={formField.applicant_details.tax_exemption_details.label}
              data={TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6}>
            <InputField
              name={`applicant_details[${activeIndex}].tfn`}
              disabled={isVerified}
              label={formField.applicant_details.tfn.label}
              helperText={
                "Note: Quotation of your Australian Tax File Number(s) (TFN) is optional"
              }
              fullWidth
            />
          </Grid>
        )}
      </React.Fragment>
    );
  };

  const renderApplicant = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            required
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].title`}
            label={formField.applicant_details.title.label}
            data={TITLE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].first_name`}
            label={formField.applicant_details.first_name.label}
            ext={formField.applicant_details.first_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].middle_name`}
            label={formField.applicant_details.middle_name.label}
            ext={formField.applicant_details.middle_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingTop: 4 }}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].last_name`}
            label={formField.applicant_details.last_name.label}
            ext={formField.applicant_details.last_name?.ext}
            helperText={"Full Name Example: John Peter Leslie Smith"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].applicant_email`}
            label={formField.applicant_details.applicant_email.label}
            helperText={"We will send a reference email to this email address"}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  };

  const renderNote = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginTop: 16 }}
          >
            APPLICANT DETAILS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Please make sure your details match what is shown on your ID. This
            information will be used to create your trading account, and for the
            purpose of conducting an Electronic Verification (EV) check which is
            required by the AML/CTF (Anti-Money Laundering and Counter-Terrorism
            Financing) Act.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">* = required field</Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const renderConfirm = () => {
    return (
      <Grid item xs={12}>
        <CheckboxField
          disabled={isVerified}
          name={`applicant_details[${activeIndex}].ekyc_aml_consent`}
          label={
            <div>
              <span>I agree with </span>
              <Link
                className={classes.link}
                target="_blank"
                href={`/ekyc-and-aml-consent.pdf?${+new Date()}`}
              >
                the eKYC & AML Consent
              </Link>
              <span>.</span>
            </div>
          }
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={3} key={`joint_applicant_${activeIndex}`}>
      {renderNote()}
      {!activeIndex ? (
        <BrokerData disabled={isVerified} setFieldValue={setFieldValue} />
      ) : null}
      {renderVerification()}
      {renderPersonalInfo()}
      {renderFinancialInfo()}
      {renderConfirm()}
    </Grid>
  );
}

export default ApplicationForm;
