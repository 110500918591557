import React from "react";
import Grid from "@mui/material/Grid";
import { InputWithAdorment } from "src/components/CustomFields";
import formModel from "../FormModels/formModels";
import HelpText from "src/components/HelpText";
import HeadText from "src/components/HeadText";
import InfoText from "src/components/InfoText";

const { formField } = formModel;

export default function AccountDesignation() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HeadText>Account Designation</HeadText>
      </Grid>
      <Grid item xs={12} sm={12}>
        <HelpText>
          This field will be used to identify the Trust / Superannuation Fund
          that is linked to your trading account. It should refer to the Trust
          or Superannuation Fund name.
        </HelpText>
        <HelpText>
          The field must include &quot;A/C&quot; at the end and cannot be more
          than 24 characters (including &quot;A/C&quot;).
        </HelpText>
        <HelpText>
          These details will form a part of the registered details so please
          ensure they are filled correctly.
        </HelpText>
      </Grid>
      <Grid item xs={12} sm={12}>
        <InfoText text="Account designation must contain only letters, numbers and spaces. Additionally the following words are not permitted: Account, ATF, Trust, Trustee, Trustees, ACT, A/C, ACFO, Testamentary, Test" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWithAdorment
          startAdornment="<"
          endAdornment="A/C>"
          required
          name={formField.account_designation.name}
          label={formField.account_designation.label}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
