/* eslint-disable no-debugger */
import React from "react";
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
import validationSchema from "./validationSchema";
import formModels from "./formModels";
import initialValues from "./initialValues";
import CreateNotificationForm from "./CreateNotificationForm";
import {
  getPushIcontUrl,
  getPushLargeImagetUrl,
  getSendSegmentUrl,
} from "src/api/api";
import { NOTIFICATION_TYPE, DELIVERY_TYPE, Type, ORDER } from "src/constants";
import dataStorage from "src/dataStorage";
import ConfirmModal from "../ConfirmModal";
import CloseIcon from "@mui/icons-material/Close";
import { postData, getPushDirectUrl } from "src/api/api";
import logger from "src/utils/logger";

const baseUrlEvn = {
  PROD: {
    equitystorytrader: "https://equitystorytrader.equix.app/",
    equix: "https://web.equix.app/",
    morrison: "https://morrison.equix.app/",
    ricard: "https://shares.exchange/",
    optixtrading: "https://optixtrading.equix.app/",
    tradeforgood: "https://tradeforgood.equix.app/",
    sharewise: "https://sharewise.equix.app/",
  },
  UAT: {
    equitystorytrader: "https://equity-story-uat.equix.app/",
    equix: "https://uat.equix.app/",
    morrison: "https://morrison-uat.equix.app/",
    ricard: "https://ricard-uat.equix.app/",
    optixtrading: "https://optixtrading-uat.equix.app/",
    tradeforgood: "https://tradeforgood-uat.equix.app/",
    sharewise: "https://sharewise-uat.equix.app/",
  },
  DEV: {
    dev1: "https://dev1.equix.app/",
  },
};

const { formId } = formModels;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: theme.spacing(3),
    borderRadius: 10,
    // minHeight: '400px',
    padding: theme.spacing(0, 2, 2, 2),
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: 800,
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(52 41 41 / 50%)",
      borderRadius: 10,
      // outline: '1px solid slategrey'
    },
    "@media screen and (max-width: 1700px)": {
      height: "92vh",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  buttons: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 8,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 56,
    alignItems: "center",
    position: "fixed",
    width: "calc(86vw - 56px)",
    background: theme.palette.background.paper,
    zIndex: 99,
    borderRadius: 10,
  },
  styleButtonClose: {
    background: "#B75D69",
    "&:hover": {
      background: "#9D4358",
    },
  },
}));
const NewNotification = (props) => {
  const classes = useStyles();
  const uploadIcon = React.useRef(null);
  const uploadImage = React.useRef(null);
  const confirmSendModalRef = React.useRef();
  const cleanModalRef = React.useRef();
  const submitClearNoti = (values, FormikHelpers) => {
    uploadIcon.current.value = "";
    uploadImage.current.value = "";
    FormikHelpers.resetForm({
      values: {
        ...initialValues,
        notification_type: values.notification_type,
        symbol: "",
        Segment: "",
      },
    });
    FormikHelpers.setErrors({});
  };
  const clearNoti = (values, FormikHelpers) => {
    cleanModalRef?.current?.showModal?.(
      () => submitClearNoti(values, FormikHelpers),
      {
        title: "Confirm clear",
        content: "Are you sure you want to clear data?",
      }
    );
  };

  const renderButtons = (isSubmitting, values, formik) => {
    return (
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.styleButtonClose}
          style={{ marginRight: 5 }}
          onClick={() => clearNoti(values, formik)}
        >
          Clear
        </Button>
        {values?.delivery_type?.value === DELIVERY_TYPE.SCHEDULE &&
        values?.notification_type?.value === NOTIFICATION_TYPE.DIRECT ? (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size="25px" /> : "Save"}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size="25px" /> : "Send"}
          </Button>
        )}
      </div>
    );
  };
  const getUrlField = async (nameField, field, URl) => {
    const createForm = new FormData();
    createForm.set(nameField, field);
    // createForm.append('file', field, nameField);
    if (!field) return;
    try {
      const getUrl = await postData(URl, createForm, {
        "Content-Type": `multipart/form-data`,
      });
      if (getUrl[0].success) {
        return getUrl[0].url;
      }
    } catch (err) {
      return "";
    }
  };
  // console.log(urlByEvn)
  const getURl = (obj) => {
    try {
      const pathUrl = obj.Type_Url?.value;
      const urlByEvn =
        baseUrlEvn?.[dataStorage.enviroment]?.[dataStorage.env] ||
        baseUrlEvn.DEV.dev1;
      const baseUrl = new URL(urlByEvn);
      const searchUrl = new URLSearchParams(baseUrl.search);
      searchUrl.set("type", pathUrl);
      switch (obj.Type_Url.value) {
        case Type.new_order: {
          if (obj.symbol.symbol && obj.order_type.value && obj.quantity) {
            if (obj.order_type.value === ORDER.limit) {
              searchUrl.set("price", obj.price);
            }
            searchUrl.set("symbol", obj.symbol.symbol);
            searchUrl.set("quantity", obj.quantity);
            searchUrl.set("order-type", obj.order_type.value);
            baseUrl.search = searchUrl;
            return baseUrl.href;
          }
          return false;
        }
        case Type.order_detail: {
          if (obj.orderId) {
            searchUrl.set("order-id", obj.orderId);
            baseUrl.search = searchUrl;
            return baseUrl.href;
          }
          return false;
        }
        case Type.security_detail: {
          if (obj.symbol.symbol) {
            searchUrl.set("symbol", obj.symbol.symbol);
            baseUrl.search = searchUrl;
            return baseUrl.href;
          }
          return false;
        }
        case Type.article: {
          if (obj.symbolMultiple.length && obj.titleArticle && obj.urlArticle) {
            const transform = obj.symbolMultiple
              .map((e) => e.symbol + "." + e.exchanges[0])
              .join(",");
            searchUrl.set("symbol", transform);
            searchUrl.set("title", obj.titleArticle);
            searchUrl.set("url", obj.urlArticle);
            baseUrl.search = searchUrl;
            return decodeURIComponent(baseUrl.href);
          }
          return false;
        }
        default: {
          if (obj.symbol.symbol && obj.order_type.value && obj.quantity) {
            if (obj.order_type.value === ORDER.limit) {
              searchUrl.set("price", obj.price);
            }
            searchUrl.set("symbol", obj.symbol.symbol);
            searchUrl.set("quantity", obj.quantity);
            searchUrl.set("order-type", obj.order_type.value);
            baseUrl.search = searchUrl;
            return baseUrl.href;
          }
          return false;
        }
      }
    } catch (error) {
      logger.error(error, "getURl error");
    }
  };
  const submitSegment = async (obj, url, value, helperAction) => {
    helperAction.setSubmitting(true);
    if (value.title && value.message && url) {
      obj.title = value?.title;
      obj.message = value?.message;
      // obj.message = value?.message + " " + (dataStorage?.ChipMessage?.status ? dataStorage?.ChipMessage?.message : '');
      obj.url = url;
      if (typeof value?.mobile_push == "boolean")
        obj.mobile_push = value?.mobile_push;
      if (typeof value?.desktop_push == "boolean")
        obj.desktop_push = value?.desktop_push;
      if (typeof value?.email_push == "boolean")
        obj.email_push = value?.email_push;
      if (typeof value?.sms_push == "boolean") obj.sms_push = value?.sms_push;
      if (value?.icon) {
        obj.icon = await getUrlField("icon", value?.icon, getPushIcontUrl());
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.symbol = value.symbol?.code;
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.exchange = value.symbol?.display_exchange;
      }
      const [res, sessionError] = await postData(
        getSendSegmentUrl(value?.Segment?.segment_id),
        obj
      );
      if (sessionError && !res?.success) {
        dataStorage.showAlert?.({ message: sessionError, type: "error" });
        helperAction.setSubmitting(false);
      } else {
        // dispatch(fetchNotifications({ pageId: paginate.current_page, pageSize: paginate.page_size, search: '' }))
        dataStorage.selfCreateNotification = res.segment_id;
        dataStorage.showAlert?.({
          message: "Send notification successfully",
          type: "success",
        });
        props.handleModalToggle && props.handleModalToggle();
      }
    }
    helperAction.setSubmitting(false);
  };
  const submitDirect = async (obj, url, value, helperAction) => {
    helperAction.setSubmitting(true);
    if (value.title && value.message && url) {
      obj.title = value?.title;
      obj.message = value?.message;
      // obj.message = value?.message + " " + (dataStorage?.ChipMessage?.status ? dataStorage?.ChipMessage?.message : '');
      obj.url = url;

      if (value?.action1_title && value?.action1_url) {
        obj.action1 = [
          {
            title: value?.action1_title,
            url: value?.action1_url,
          },
        ];
      }
      if (value?.action2_title && value?.action2_url) {
        obj.action2 = [
          {
            title: value?.action1_title,
            url: value?.action1_url,
          },
        ];
      }
      if (typeof value?.mobile_push == "boolean")
        obj.mobile_push = value?.mobile_push;
      if (typeof value?.desktop_push == "boolean")
        obj.desktop_push = value?.desktop_push;
      if (typeof value?.email_push == "boolean")
        obj.email_push = value?.email_push;
      if (typeof value?.sms_push == "boolean") obj.sms_push = value?.sms_push;
      if (value?.subsribers.length) {
        // console.log(value?.subsribers)
        //subscriber_id
        obj.subscribers = value?.subsribers.map(
          (current) => current?.subscriber_id
        );
      }
      if (+value?.expireNumber && value?.optionType?.value) {
        obj.expire_time = value?.expireNumber + value?.optionType?.value;
      }
      if (value?.icon) {
        obj.icon = await getUrlField("icon", value?.icon, getPushIcontUrl());
      }
      if (
        value?.large_image &&
        value?.notification_type?.value !== NOTIFICATION_TYPE.SEGMENT
      ) {
        obj.large_image = await getUrlField(
          "large_image",
          value?.large_image,
          getPushLargeImagetUrl()
        );
      }
      if (
        value?.delivery_type?.value === DELIVERY_TYPE.SCHEDULE &&
        value?.schedule_time
      ) {
        const getTime = new Date(value?.schedule_time);
        obj.schedule_time = getTime.getTime();
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.symbol = value.symbol?.code;
      }
      if (value.Type_Url.value != Type.order_detail) {
        obj.exchange = value.symbol?.display_exchange;
      }

      const [res, sessionError] = await postData(getPushDirectUrl(), obj);
      if (sessionError) {
        helperAction.setSubmitting(false);
        dataStorage.showAlert?.({ message: sessionError, type: "error" });
      } else {
        if (res?.success) {
          // dispatch(fetchNotifications({ pageId: paginate.current_page, pageSize: paginate.page_size, search: '' }))
          dataStorage.selfCreateNotification = res.notification_id;
          dataStorage.showAlert?.({
            message: "Send notification successfully",
            type: "success",
          });
          props.handleModalToggle && props.handleModalToggle();
        }
      }
    }
    helperAction.setSubmitting(false);
  };
  const submitForm = (value, helperAction) => {
    helperAction.setSubmitting(false);
    try {
      const obj = {};
      const url = getURl(value);
      if (value?.notification_type?.value === NOTIFICATION_TYPE.SEGMENT) {
        confirmSendModalRef?.current?.showModal?.(
          () => submitSegment(obj, url, value, helperAction),
          {
            title: "Confirm send",
            content: "Are you sure you want to send this notification?",
          }
        );
      } else {
        confirmSendModalRef?.current?.showModal?.(
          () => submitDirect(obj, url, value, helperAction),
          {
            title: "Confirm send",
            content: "Are you sure you want to send this notification?",
          }
        );
      }
    } catch (err) {
      dataStorage.showAlert?.({ message: "invalid argument", type: "error" });
      helperAction.setSubmitting(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className={classes.headerContainer}>
        <IconButton
          onClick={props.handleModalToggle}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="top" component="h1" variant="h6" align="center">
          NEW NOTIFICATION
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Paper className={classes.paper} elevation={15}>
        {renderHeader()}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={submitForm}
        >
          {({ isSubmitting, values, ...formik }) => (
            <Form
              id={formId}
              autoComplete="off"
              style={{ paddingTop: 56, width: "100%" }}
            >
              <CreateNotificationForm
                uploadIcon={uploadIcon}
                uploadImage={uploadImage}
              />
              {renderButtons(isSubmitting, values, formik)}
            </Form>
          )}
        </Formik>
      </Paper>
      <ConfirmModal
        title={" "}
        textButton={"Send"}
        textCancel={"Close"}
        content={"Are you sure you want to continue?"}
        ref={confirmSendModalRef}
      />
      <ConfirmModal
        title={" "}
        textButton={"Clear"}
        textCancel={"Close"}
        content={"Are you sure you want to continue?"}
        ref={cleanModalRef}
      />
    </>
  );
};

export default NewNotification;
