import React from "react";
import CreateUser from "./Forms/CreateUser";
import { Formik, Form } from "formik";
import { initialState } from "./FormModels/Forminitial";
import { Paper, Grid } from "@mui/material";
import { getUrlCreateUser, postData } from "src/api/api";
import { USER_STATUS, API_ACCESS_API } from "src/constants";
import useStyles from "../styles";
import dataStorage from "src/dataStorage";
import validationYup from "./FormModels/formValidation";
import FocusError from "src/components/FocusError";
const handlerData = (data = {}) => {
  if (!data) return {};
  const valueApiAcess = +(data.api_access?.value != null
    ? data.api_access?.value
    : data.api_access);
  const obj = {
    full_name: data.full_name || "",
    user_login_id: (data.user_login_id || "").toLowerCase(),
    email: data.user_login_id || "",
    phone: data.phone || "",
    user_group: +data.user_group,
    role_group: data.role_group || "",
    user_type: API_ACCESS_API[valueApiAcess],
    status: +data.status,
    email_template: data.email_template,
    access_method: +data.access_method,
    note: data.note || "",
  };
  if (data.phone === "") obj.phone = "";
  if (+data.status === USER_STATUS.ACTIVE) {
    obj.password = data.password;
    obj.change_password = 1;
    obj.send_password = data.send_password ? 1 : 0;
  }
  if (Array.isArray(data.list_mapping)) {
    if (data.list_mapping.length > 0) {
      let listMapping = "";
      for (let index = 0; index < data.list_mapping.length; index++) {
        const element = data.list_mapping[index];
        listMapping += element.account_id + ",";
      }
      listMapping = listMapping.replace(/.$/, "");
      obj.list_mapping = listMapping;
    }
  }
  if (Array.isArray(data.branch_management)) {
    if (data.branch_management.length > 0) {
      let branchCode = "";
      for (let index = 0; index < data.branch_management.length; index++) {
        const element = data.branch_management[index];
        branchCode += element.branch_code + ",";
      }
      branchCode = branchCode.replace(/.$/, "");
      obj.branch_code = branchCode;
    }
  }
  if (Array.isArray(data.organization_management)) {
    if (data.organization_management.length > 0) {
      let organisationCode = "";
      for (
        let index = 0;
        index < data.organization_management.length;
        index++
      ) {
        const element = data.organization_management[index];
        organisationCode += element.organisation_code + ",";
      }
      organisationCode = organisationCode.replace(/.$/, "");
      obj.organisation_code = organisationCode;
    }
  }
  if (Array.isArray(data.manage_advisor)) {
    if (data.manage_advisor.length > 0) {
      let advisorCode = "";
      for (let index = 0; index < data.manage_advisor.length; index++) {
        const element = data.manage_advisor[index];
        advisorCode += element.advisor_code + ",";
      }
      advisorCode = advisorCode.replace(/.$/, "");
      obj.advisor_code = advisorCode;
    }
  }
  return obj;
};
const index = () => {
  const classes = useStyles();
  const submitUser = (value, actions) => {
    actions.setSubmitting(true);
    postData(getUrlCreateUser(), { data: handlerData(value) })
      .then((res) => {
        if (res?.[1]) {
          dataStorage.showAlert?.({ message: res[1], type: "error" });
        } else {
          dataStorage.showAlert?.({
            message: `Create user successfully`,
            type: "success",
          });
          actions.resetForm({ ...initialState });
        }
        actions.setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        actions.setSubmitting(false);
      });
  };
  return (
    <React.Fragment>
      <Paper className={classes.paper} elevation={15}>
        <Formik
          initialValues={initialState}
          validationSchema={validationYup}
          onSubmit={submitUser}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {() => {
            return (
              <React.Fragment>
                <FocusError>
                  <Form autoComplete="off">
                    <Grid container spacing={3}>
                      <CreateUser />
                    </Grid>
                  </Form>
                </FocusError>
              </React.Fragment>
            );
          }}
        </Formik>
      </Paper>
    </React.Fragment>
  );
};

export default index;
