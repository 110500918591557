import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import NewNotification from "../NewNotification/NewNotification";

const useStyles = makeStyles(() => ({
  modalContainer: {
    width: "86vw",
    outline: "none",
  },
}));

const CreateNotificationModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useImperativeHandle(
    ref,
    () => ({
      showModal: showModal,
    }),
    []
  );

  const showModal = () => setOpen(true);

  const handleModalToggle = () => {
    setOpen((old) => !old);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={classes.modalContainer}>
        <NewNotification handleModalToggle={handleModalToggle} />
      </div>
    </Modal>
  );
});

CreateNotificationModal.displayName = "CreateNotificationModal";
export default CreateNotificationModal;
